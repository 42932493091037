import { Link } from 'react-router-dom';
import React from 'react'
import Slider from 'react-slick';
const settings = {
    slidesToShow: 2,
    dots: false,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 2000,
   };
export default function CommunityList() {
    const communityAreas = [
        { title: 'Come affrtonare il caldo', content: 'Bere, dormire, mangiare, correre giocare....', image: 'assets/images/aboutbg.jpg',},
        { title: 'I primi ricordi', content: 'Salva foto e video dei suoi primi passi, le prime parole e tutti i ricordi a te cari', image: 'assets/images/comm-01.png'},
        { title: 'Come affrtonare il caldo', content: 'Bere, dormire, mangiare, correre giocare....', image: 'assets/images/aboutbg.jpg',},
    ]
    const communityAreasList = communityAreas.map((communityArea,index) =>
        <div className='px-3 pt-4 pb-8' key={index}>
            <div className="rounded-xl shadow-lg h-full  bg-white overflow-hidden">
                <div className='sm:flex items-center'>
                    <div className="bg-gray-300 rounded-xl storiesbox sm:w-44 w-full min-w-44 h-full">
                    <Link to="#" className='block h-full '><img src={communityArea.image} className='sm:w-44 w-full h-44 mx-auto object-cover'/></Link>
                    </div>
                    <div className='md:p-4 p-3'>
                        <h4 className="card-title text-xl font-semibold mb-2"><Link to="#">{communityArea.title}</Link></h4>
                        <p className='text-gray-600 text-[15px]'>{communityArea.content}</p>
                        <div className='text-right'>
                            <Link to="#" className='text-sm mt-4 inline-block text-black underline'>Leggi di più </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  return (
    <>
    <div className='communityslide px-2'>
        <Slider {...settings}>
            {communityAreasList}
        </Slider>
        </div>

    </>
  )
}