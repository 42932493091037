import React from 'react'

export default function VideoCall() {
  return (
    <>
    <div className='w-full h-screen relative'>
        <img src='assets/images/videocall-screen.png' className='w-full h-screen object-cover'/>

        <span className='absolute top-6 left-6 z-1 text-xl font-semibold'>Giovanni Bianchi</span>
        <div className='absolute md:bottom-6 bottom-20 right-6 z-1'>
            <div className='relative'>
                <img src='assets/images/profile-dr1.jpg' className='lg:w-[220px] lg:h-[140px] w-[140px] h-95px object-cover rounded-lg'/>
                <p className='absolute bottom-3 px-3 text-sm text-white font-semibold'>Dott. Giuseppe Morero</p>
            </div>
        </div>
        <div className='absolute md:bottom-6 bottom-3 left-0 right-0 mx-auto md:w-[262px] w-[205px] md:p-3 p-2 bg-[#141419b5] rounded-lg flex gap-2 justify-center'>
            <div >
                <button className='lg:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-[#141419] text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-video"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-white text-black md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-volume-high"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] bg-[#141419] text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-microphone-slash"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] bg-red-500 text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-phone-hangup"></i></button>
            </div>
        </div>
    </div>
    </>
  )
}
