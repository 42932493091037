import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import { useDispatch } from 'react-redux';
import { resetSelectedStudios, resetSelectedDays } from '../../redux/studioSlice';
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints


export default function CalendarVisit() {

    const idPediatrician = localStorage.getItem('userId');
    // const pediatricianDetails = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [pgloading, setPgLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const [availabilityList, setAvailabilityList] = useState([]); // Dynamic availability list
    const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list
    const [serviceList, setServiceList] = useState([]);

    // let isAvailabilityAdded = false;

    // if (pediatricianDetails && pediatricianDetails.isAvailabilityAdded !== undefined) {
    //     isAvailabilityAdded = pediatricianDetails.isAvailabilityAdded;
    // }


    const dispatch = useDispatch();

    // Function to reset Redux states
    const handleReset = () => {
        console.log('Resetting studios and days'); // Check if this is printed
        dispatch(resetSelectedStudios()); // Reset studios
        dispatch(resetSelectedDays()); // Reset days (or any other Redux state you need to clear)
    };

    // Fetch availability data
    const fetchAvailability = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.allAvailability}/${idPediatrician}`);
            const { status, list } = response.data;
            if (status === 1) {
                setAvailabilityList(list); // Store the availability list
            } else {
                setError('Failed to fetch availability details.');
            }
        } catch (error) {
            setError('Error fetching availability details');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch services data
    const fetchServices = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.pediatricianServices}/${idPediatrician}`);
            const { status, list } = response.data;
            if (status === 1) {
                setServiceList(list); // Store the services list
            } else {
                setError('Failed to fetch services details.');
            }
        } catch (error) {
            setError('Error fetching services details');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleReset(); // Reset Redux data when component mounts
        fetchAvailability();
        fetchServices();

        const fetchPediatricianStatus = async () => {
            setPgLoading(true); // Optional: Set loading state while fetching
            fetchAvailability();

            try {
                const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);

                setPediatricianDetail(response.data.data); // Store the availability list

                // Access the correct path to `isAvailabilityAdded`
                if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
                    if (response.data.data.isAvailabilityAdded == true) {
                        // If `isAvailabilityAdded` is true, redirect to the `agenda-calander` page
                        navigate('/agenda-calander');
                    } else {
                        // If `isAvailabilityAdded` is false, stop loading and stay on the page
                        setPgLoading(false);
                    }
                } else {
                    setPgLoading(false);
                }
            } catch (error) {
                console.error("Error fetching pediatrician data:", error);
                setPgLoading(false);
            }
        };

        fetchPediatricianStatus();
    }, [idPediatrician, navigate]);

    console.log("availabilityList ", availabilityList);
    console.log("pediatricianDetail ", pediatricianDetail);
    console.log("serviceList ", serviceList);

    console.log("isSSNBookedAllowed ", pediatricianDetail.isSSNBookedAllowed);
    console.log("cancelBeforeHr ", pediatricianDetail.cancelBeforeHr);
    

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching the data
    }

    if (pgloading) {
        return <div>Loading...</div>; // Show a pgloading message while fetching the data
    }



    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/calendar-visit"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                            </div>
                            <Grid container spacing={3} className='justify-center'>
                                <Grid item lg={5} md={12} xs={12}>
                                    <h2 className='text-2xl text-center font-semibold mb-5'>Calendario viste</h2>
                                    <p className='mb-8 text-center'>Imposta il <span className='font-semibold'>Calendario Visite,</span> le Impostazioni visita e la <span className='font-semibold'>Lista prestazioni</span> per poter iniziare a ricevere prenotazioni</p>
                                    <Grid container spacing={3} className='justify-center'>
                                        <Grid item lg={9}>
                                            {/* Condition 1: Check if availabilityList is empty */}
                                            {availabilityList.length > 0 ? (
                                                <Link to="/choose-availability" className="mb-6 block">
                                                    <div className='flex justify-between items-center'>
                                                        <div className='font-semibold'><i className="fa-solid fa-circle-check mr-2 text-[#828DEE]"></i> Calendario Viste</div>
                                                        <div className='text-base'>modifica <i className="fa-regular fa-angle-right"></i></div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to="/choose-availability" className='block w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-5 relative'>
                                                    <span className="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                                    <div className='flex p-6 gap-5 items-center'>
                                                        <div className='text-4xl text-[#828DEE]'>
                                                            <i className="fa-light fa-calendar-days"></i>
                                                        </div>
                                                        <div>
                                                            <h2 className=' text-lg font-semibold mb-1'>Imposta Calendario Viste</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}

                                            {/* Condition 2: Check if isSSNBookedAllowed or cancelBeforeHr in pediatricianDetail is null */}
                                            {pediatricianDetail.isSSNBookedAllowed == null || pediatricianDetail.cancelBeforeHr == null ? (
                                                <Link to="/settings" className='block w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-5 relative'>
                                                    <span className="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                                    <div className='flex p-6 gap-5 items-center'>
                                                        <div className='text-4xl text-[#828DEE]'>
                                                            <i className="fa-sharp fa-regular fa-gear"></i>
                                                        </div>
                                                        <div>
                                                            <h2 className=' text-lg font-semibold mb-1'>Impostazioni vista</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to="/settings" className="mb-6 block">
                                                    <div className='flex justify-between items-center'>
                                                        <div className='font-semibold'><i className="fa-solid fa-circle-check mr-2 text-[#828DEE]"></i> Impostazioni vista</div>
                                                        <div className='text-base'>modifica <i className="fa-regular fa-angle-right"></i></div>
                                                    </div>
                                                </Link>
                                            )}
                                            {/* Condition 1: Check if serviceList is empty */}
                                            {serviceList.length === 0 ? ( // Check if serviceList is empty

                                                <Link to="/services-list" className='block w-full bg-white rounded-lg shadow-lg hover:shadow-xl transition-all items-center mb-5 relative'>
                                                    <span class="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                                    <div className='flex p-6 gap-5 items-center'>
                                                        <div className='text-4xl text-[#828DEE]'>
                                                            <i class="fa-solid fa-list-ul"></i>
                                                        </div>
                                                        <div>
                                                            <h2 className=' text-lg font-semibold mb-1'>Lista prestazioni</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to="/services-list" className="mb-6 block">
                                                    <div className='flex justify-between items-center'>
                                                        <div className='font-semibold'><i className="fa-solid fa-circle-check mr-2 text-[#828DEE]"></i> Lista prestazioni</div>
                                                        <div className='text-base'>modifica <i className="fa-regular fa-angle-right"></i></div>
                                                    </div>
                                                </Link>
                                            )}
                                        </Grid>
                                        {(availabilityList.length > 0 && (pediatricianDetail.isSSNBookedAllowed != null) && serviceList.length > 0) ? (
                                            <div>
                                                <div className='text-center mt-20'>
                                                    <p className='mb-5'>Prosegui per poter iniziare a ricevere visite.</p>
                                                    <p className='mb-5'>Potrai modificare gli orari, i giorni, le modalità e la lista prestazioni nelle impostazioni del calendario visita <i className="fa-regular fa-gear"></i></p>
                                                </div>
                                                <div className="mt-10 text-center">
                                                    <Link to="/view-calander" className="w-72 py-3 inline-block shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">
                                                        Continua
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : null}


                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
