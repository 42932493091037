import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, FormControl, InputLabel, Switch, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalanderDate from "react-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import Select from 'react-select';
import { Constants } from '../../endPoints'; // Constants for endpoints
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';
import './CalendarStyles.css';

const localizer = momentLocalizer(moment)

export default function ViewCalendar() {
  const idPediatrician = localStorage.getItem('userId');
  const pediatricianDetails = JSON.parse(localStorage.getItem('user'));
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Store selected event
  const [selectedSlot, setSelectedSlot] = useState(null); // Store selected time slot (start and end time)
  const [availabilityList, setAvailabilityList] = useState([]); // Dynamic availability list
  const [events, setEvents] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null); // State for selected patient
  const [isSSN, setIsSSN] = useState(true); // Default condition: SSN ON, Private OFF
  const [isPrivate, setIsPrivate] = useState(false);
  const [patientList, setPatientList] = useState([]); // State for patient list
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]); // Initialize selectedDate
  const [services, setServices] = useState([]); // State to hold services list
  const [selectedServices, setSelectedServices] = React.useState([]); // To track selected services
  const [selectedFilteredServices, setFilteredServices] = React.useState([]); // To track selected services

  const [discountType, setDiscountType] = useState('€'); // Default to fixed discount
  const [discountValue, setDiscountValue] = useState(0);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);


  const [loading, setLoading] = useState(false);
  const [pgloading, setPgLoading] = useState(false);
  const [error, setError] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false); // Loader state for the save button
  const [formError, setFormError] = useState(''); // State to store form validation errors
  const [calanderDate, setCalanderDate] = useState(new Date());
  const [isAddNewSlot, setIsAddNewSlot] = useState(false);  // New state to track if "Add New Slot" button is clicked

  const [keyword, setKeyword] = useState('');
  const [availabilities, setAvailabilities] = useState('');
  const [activeFilter, setActiveFilter] = useState('');  // Track active filter
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();

  let pediatricianName = null;

  if (pediatricianDetails && pediatricianDetails.firstName !== undefined) {
    pediatricianName = pediatricianDetails.firstName;
  }

  const handleDateChange = (date) => {
    setCalanderDate(date);
    console.log("original date:", date);
  };


  // Calculate the start and end of the week based on selectedDate
  const startOfWeek = moment(calanderDate).startOf('week').toDate();
  const endOfWeek = moment(calanderDate).endOf('week').toDate();

  const getPatients = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.patientList}/${idPediatrician}`);
      const { status, patient } = response.data;
      if (status === 1) {
        const patientOptions = patient?.map((p) => ({
          value: p?.id,
          label: `${p?.firstName} ${p?.lastName}`,
          type: p?.type // Adding type for patient
        }));
        setPatientList(patientOptions); // Set the formatted patient list for select
      } else {
        setError('Failed to fetch patient details.');
      }

      setLoading(false);
    } catch (error) {
      setError('Error fetching patient details');
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch studio addresses
  const getStudios = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Constants.pediatricianAddressList}${idPediatrician}`);
      if (response.ok) {
        const data = await response.json(); // Directly parse JSON
        if (data.status === 1) {
          setAddresses(Array.isArray(data.data) ? data.data : []); // Set fetched addresses
        } else {
          console.error('Failed to fetch addresses:', data.message);
        }
      } else {
        console.error('Failed to fetch addresses');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setLoading(false);
    }
  };

  // Fetch availability data
  const fetchAvailability = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.slotBooking}/${idPediatrician}?keyword=${keyword}&availabilities=${availabilities}`);
      const { status, data } = response.data; // Destructure the response correctly

      if (status === 1 && data.length > 0) {
        console.log("Booking Data:", data); // This should now log the data array
        setAvailabilityList(data); // Set the availability list
        setEvents(mapAvailabilityToEvents(data)); // Set events based on the availability data
      } else {
        // Clear the list and events if no data is fetched
        setAvailabilityList([]); // Reset availability list
        setEvents([]); // Clear events
        setError('No booking details available.');
      }

      setLoading(false);
    } catch (error) {
      setError('Error fetching booking details');
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch services data
  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.pediatricianServices}/${idPediatrician}`);
      const { status, list } = response.data;

      if (status === 1) {
        // Check if pediatricianServices list is empty
        setServices(list);
      } else {
        setError('Failed to fetch services details.');
      }

      setLoading(false);
    } catch (error) {
      setError('Error fetching services details');
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Mock API call for demonstration
  const fetchFilteredServices = async (serviceIds) => {
    // Assume this function calls the API and filters services based on `serviceIds`
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.pediatricianServices}/${idPediatrician}?ids=${serviceIds}`);
      const { status, list } = response.data;

      if (status === 1) {
        // Check if pediatricianServices list is empty
        setFilteredServices(list);
      } else {
        setError('Failed to fetch services details.');
      }

      setLoading(false);
    } catch (error) {
      setError('Error fetching services details');
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const mapAvailabilityToEvents = () => {
    const mappedEvents = [];

    availabilityList.forEach((slot) => {
      const bookingDate = new Date(slot.bookingDate); // Convert bookingDate to Date object

      // Create start and end dates using bookingDate and slot start/end times
      const startDate = new Date(bookingDate);
      startDate.setHours(slot.slotStart.split(':')[0], slot.slotStart.split(':')[1]);

      const endDate = new Date(bookingDate);
      endDate.setHours(slot.slotEnd.split(':')[0], slot.slotEnd.split(':')[1]);

      // Determine the color based on conditions
      let eventColor;
      if (slot.isSSN && slot.isPrivate && slot.ssnClinic && slot.ssnRemote && slot.privateClinic && slot.privateRemote) {
        eventColor = '#FFC885'; // Yellow
      } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && slot.isPrivate && slot.privateClinic && !slot.privateRemote) {
        eventColor = '#FFC885'; // Yellow
      } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote) {
        eventColor = '#FFC885'; // Yellow
      } else if (slot.isSSN && slot.ssnClinic && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnRemote) {
        eventColor = '#FFC885'; // Yellow for SSN in Clinic
      } else if (slot.isSSN && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnClinic) {
        eventColor = '#FFA232'; // Orange for SSN remotely
      } else if (slot.isPrivate && slot.privateClinic && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateRemote) {
        eventColor = '#828DEE'; // Violet for Private in Clinic
      } else if (slot.isPrivate && slot.privateClinic && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote) {
        eventColor = '#828DEE'; // Violet for Private in Clinic
      } else if (slot.isPrivate && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateClinic) {
        eventColor = '#5EAF88'; // Green for Private remotely
      } else {
        eventColor = '#FFC885'; // Default color if no other conditions are matched
      }

      let eventTitle = 'Visit '; // Start with "Visit" only once

      // Append SSN details if isSSN is true
      if (slot.isSSN) {
        eventTitle += 'SSN ';
        if (slot.ssnClinic && slot.ssnRemote) {
          eventTitle += 'in studio e da remoto ';
        } else if (slot.ssnClinic) {
          eventTitle += 'in studio ';
        } else if (slot.ssnRemote) {
          eventTitle += 'da remoto ';
        }
      }

      // Append Private details if isPrivate is true
      if (slot.isPrivate) {
        eventTitle += 'Privato ';
        if (slot.privateClinic && slot.privateRemote) {
          eventTitle += 'in studio e da remoto ';
        } else if (slot.privateClinic) {
          eventTitle += 'in studio ';
        } else if (slot.privateRemote) {
          eventTitle += 'da remoto ';
        }
      }

      eventTitle = eventTitle.trim(); // Remove any trailing spaces


      // Check if the booking date matches the current view (year, month, and date)
      const isSameYear = startDate.getFullYear() === bookingDate.getFullYear();
      const isSameMonth = startDate.getMonth() === bookingDate.getMonth();
      const isSameDate = startDate.getDate() === bookingDate.getDate();

      if (isSameYear && isSameMonth && isSameDate) {
        // Push the event only if it matches the year, month, and day
        mappedEvents.push({
          id: slot.id,
          idPediatrician: slot.idPediatrician,
          idAvailabilities: slot.idAvailabilities,
          idPatient: slot.idPatient,
          patientFirstName: slot.patientFirstName,
          patientLastName: slot.patientLastName,
          idStudio: slot.idStudio,
          studioName: slot.studioName,
          studioAddress: slot.studioAddress,
          slotStart: slot.slotStart,
          slotEnd: slot.slotEnd,
          languageId: slot.languageId,
          title: eventTitle,
          start: startDate,
          end: endDate,
          color: eventColor,
          isSSN: slot.isSSN,
          ssnClinic: slot.ssnClinic,
          ssnRemote: slot.ssnRemote,
          isPrivate: slot.isPrivate,
          privateClinic: slot.privateClinic,
          privateRemote: slot.privateRemote,
          selectedAddress: slot.idStudio,
          bookingDate: slot.bookingDate,
          amount: slot.amount,
          bookedByUserId: slot.bookedByUserId,
          bookedByUserFullName: slot.bookedByUserFullName,
          bookedByUserType: slot.bookedByUserType,
          status: slot.status,
          paymentStatus: slot.paymentStatus,
        });
      }
    });

    return mappedEvents;
  };

  useEffect(() => {
    const fetchData = async () => {
      setPgLoading(true);

      // Fetch availability, studios, and patients
      await fetchAvailability();
      await getStudios();
      await getPatients();
      await fetchServices();

      try {
        const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);

        // Check if `isAvailabilityAdded` is present in the response
        if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
          if (response.data.data.isAvailabilityAdded === false) {
            // If `isAvailabilityAdded` is true, redirect to the `calendar-visit` page
            navigate('/calendar-visit');
          } else {
            // If `isAvailabilityAdded` is false, stop loading and stay on the page
            setPgLoading(false);
          }
        } else {
          setPgLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pediatrician data:", error);
        setPgLoading(false);
      }
    };

    // Invoke the fetchData function to initiate API calls
    fetchData();

  }, [idPediatrician, navigate, calanderDate, keyword, availabilities]);

  useEffect(() => {
    // Ensure the calendar updates based on the availability list
    if (availabilityList.length > 0) {
      setEvents(mapAvailabilityToEvents(availabilityList)); // Map and set events when availabilityList changes
    } else {
      setEvents([]); // Clear events if no data is available
    }
  }, [availabilityList]); // Only depend on availabilityList here to trigger the event setting

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setKeyword(e.target.value);
  };

  // Handler for when a service is checked/unchecked
  const handleServiceSelect = (serviceId) => {
    setSelectedServices(prevSelected => {
      const updatedSelected = prevSelected.includes(serviceId)
        ? prevSelected.filter(id => id !== serviceId)
        : [...prevSelected, serviceId];

      if (updatedSelected.length > 0) {
        setError(null); // Clear error once a service is selected
      }
      return updatedSelected;
    });
  };


  // Update the availability when a filter button is clicked
  const handleFilterClick = (filterType) => {
    if (filterType !== availabilities) { // Only update if filterType is different
      setActiveFilter(filterType); // Set the clicked filter as active
      setAvailabilities(filterType); // Update availabilities for the API call
    }
  };

  // Conditional class application
  const getLinkClass = (filterType) => {
    return activeFilter === filterType
      ? 'bg-[#828DEE] text-white py-[5px] px-4 inline-block border rounded-full text-sm border-[#72777A]'
      : 'text-[#72777A] py-[5px] px-4 inline-block border rounded-full text-sm border-[#72777A] hover:bg-[#828DEE] hover:text-white';
  };

  // Define eventPropGetter to customize event background color
  const eventPropGetter = (event) => {
    const backgroundColor = event.color; // Set color based on event data
    return { style: { backgroundColor } }; // Return the style object with the color
  };

  const handleEventClick = (event) => {
    setIsAddNewSlot(false);  // Not adding a new slot, just editing an existing event

    const day = event.weekName || moment(event.start).format('dddd');
    const selectedDate = moment(event.start).format('YYYY-MM-DD');

    setSelectedEvent({
      id: event.id,
      weekName: day,
      selectedDate,
      idPediatrician: event.idPediatrician,
      idAvailabilities: event.idAvailabilities,
      idPatient: event.idPatient,
      patientFirstName: event.patientFirstName,
      patientLastName: event.patientLastName,
      idStudio: event.idStudio,
      studioName: event.studioName,
      studioAddress: event.studioAddress,
      slotStart: moment(event.start).format('HH:mm'),
      slotEnd: moment(event.end).format('HH:mm'),
      languageId: event.languageId,
      title: event.title,
      start: event.start,
      end: event.end,
      color: event.color,
      isSSN: event.isSSN,
      ssnClinic: event.ssnClinic,
      ssnRemote: event.ssnRemote,
      isPrivate: event.isPrivate,
      privateClinic: event.privateClinic,
      privateRemote: event.privateRemote,
      selectedAddress: event.idStudio,
      bookingDate: event.bookingDate,
      amount: event.amount,
      bookedByUserId: event.bookedByUserId,
      bookedByUserFullName: event.bookedByUserFullName,
      bookedByUserType: event.bookedByUserType,
      status: event.status,
      paymentStatus: event.paymentStatus,
    });

    setOpenModal(true);  // Open modal
  };

  const handleSlotSelect = (slotInfo) => {
    setIsAddNewSlot(false);  // Not adding a new slot, just editing an empty time slot

    setSelectedEvent(null);
    setOpenModal(false);  // Open the modal
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvent(null); // Clear the selected event
    setSelectedSlot(null); // Clear the selected slot
  };

  // Function to handle "Add New Slot" button click
  const handleAddNewSlotClick = () => {
    setIsAddNewSlot(true);  // Set flag to true for bypassing date validation

    setSelectedSlot({
      day: '',  // Force the user to select a day when the button is clicked
      start: '',  // Empty start time
      end: '',  // Empty end time
      isSSN: false,
      ssnClinic: false,
      ssnRemote: false,
      isPrivate: false,
      privateClinic: false,
      privateRemote: false,
      selectedAddress: '',  // No address selected initially
    });
    setSelectedEvent(null);  // No pre-selected event
    setcreateslotModal(true);  // Just open the modal, no validation yet
  };

  const handleCancelWithSwal = async () => {
    const eventData = selectedEvent || selectedSlot;

    // Show Swal alert for confirmation with custom buttons
    Swal.fire({
      title: 'Cancella visita',
      html: `
            Vuoi annullare la prenotazione <br>
            della visita?<br>
            Avviseremo il paziente che hai<br>
            cancellato la visita
            <div style="margin-top: 20px;">
              <button id="confirm-btn" style="
                  background-color: #f04438;
                  color: white;
                  border: none;
                  border-radius: 25px;
                  padding: 10px 20px;
                  cursor: pointer;
                  font-size: 16px;
                  margin-right: 10px;
              ">Cancella</button>

          </div>
          <div style="margin-top: 20px;">
              <a id="close-link" style="
                  color: #828dee;
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 16px;
                  font-weight: bold;
              ">Chiudi</a>
          </div>
        `,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      willOpen: () => {
        // Add event listeners after Swal is shown
        const confirmBtn = Swal.getHtmlContainer().querySelector('#confirm-btn');
        const closeLink = Swal.getHtmlContainer().querySelector('#close-link');

        // Confirm button click handler
        confirmBtn.addEventListener('click', async () => {
          try {
            await handleCancel(eventData); // Call handleDelete with eventData
            Swal.close(); // Close the Swal after deletion
          } catch (error) {
            console.error("Deletion failed:", error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Si è verificato un errore durante la cancellazione!',
            });
          }
        });

        // Close link click handler
        closeLink.addEventListener('click', () => {
          Swal.close(); // Close the Swal without doing anything
        });
      }
    });
  };

  const handleCancel = async () => {
    const eventData = selectedEvent || selectedSlot;
    setLoadingSave(true);  // Show loading indicator

    try {
      let apiUrl = `${Constants.cancelSlotBooking}/${selectedEvent?.id}`;  // Set the API endpoint


      // Make the API request
      const response = await axios.get(apiUrl);

      if (response.data.status === 1) {
        // Handle successful deletion
        fetchAvailability();  // Refresh the availability after deletion
        setOpenModal(false);  // Close modal
      } else {
        setFormError('Failed to delete availability. Please try again.');
      }
    } catch (error) {
      setFormError('Error deleting availability. Please try again.');
      console.error('Error deleting availability:', error);
    } finally {
      setLoadingSave(false);  // Stop the loader
    }
  };

  // Validation function to avoid duplication
  const validateForm = (eventData) => {
    const slotStart = eventData?.slotStart || eventData?.start || '';
    const slotEnd = eventData?.slotEnd || eventData?.end || '';
    const momentSelectedDate = moment(selectedDate); // Use the selected date from state

    // Perform validations
    if (!slotStart || !slotEnd) {
      return 'Please provide both start and end times.';
    }

    // Validate the selected date
    if (!selectedDate || !momentSelectedDate.isValid()) {
      console.log('Selected Date:', selectedDate);
      return 'Please select a valid booking date.';
    }

    // Check if the selected date is today or in the future
    const today = moment().startOf('day');
    if (momentSelectedDate.isBefore(today, 'day')) {
      return 'The booking date cannot be in the past.';
    }

    // Check if at least one of SSN or Privato is selected
    const isSSNChecked = selectedEvent?.isSSN || selectedSlot?.isSSN;
    const isPrivateChecked = selectedEvent?.isPrivate || selectedSlot?.isPrivate;
    if (!isSSNChecked && !isPrivateChecked) {
      return 'Please check at least one option: SSN or Privato.';
    }

    // Ensure one radio button is checked for SSN or Privato
    if (isSSNChecked && !(selectedEvent?.ssnClinic || selectedEvent?.ssnRemote || selectedSlot?.ssnClinic || selectedSlot?.ssnRemote)) {
      return 'Please select "In studio" or "Da remoto" for SSN.';
    }

    if (isPrivateChecked && !(selectedEvent?.privateClinic || selectedEvent?.privateRemote || selectedSlot?.privateClinic || selectedSlot?.privateRemote)) {
      return 'Please select "In studio" or "Da remoto" for Privato.';
    }

    // Additional checks if needed
    const { privateClinic = false, ssnClinic = false, selectedAddress = null } = eventData;

    if ((isSSNChecked && ssnClinic || isPrivateChecked && privateClinic)) {
      if (!selectedAddress) {
        return 'Please select a studio address if "In the Clinic" is selected for SSN or Private.';
      }
    }

    return ''; // No errors
  };

  const handleSave = async () => {
    const eventData = selectedEvent || selectedSlot; // Ensure eventData is defined
    const validationError = validateForm(eventData);

    if (validationError) {
      setFormError(validationError); // Set error message
      return;
    }

    setLoadingSave(true);
    setFormError('');

    try {
      const apiUrl = `${Constants.createSlotBooking}`;

      const isSSN = selectedEvent?.isSSN || selectedSlot?.isSSN || false;
      const isPrivate = selectedEvent?.isPrivate || selectedSlot?.isPrivate || false;

      const requestPayload = {
        idPediatrician,
        idPatient: selectedPatient?.value || null,
        languageId: 1,
        slotStart: selectedSlot?.start || selectedEvent?.slotStart || '',
        slotEnd: selectedSlot?.end || selectedEvent?.slotEnd || '',
        isSSN: isSSN,
        ssnClinic: isSSN ? (selectedEvent?.ssnClinic || selectedSlot?.ssnClinic || false) : false,
        ssnRemote: isSSN ? (selectedEvent?.ssnRemote || selectedSlot?.ssnRemote || false) : false,
        isPrivate: isPrivate,
        privateClinic: isPrivate ? (selectedEvent?.privateClinic || selectedSlot?.privateClinic || false) : false,
        privateRemote: isPrivate ? (selectedEvent?.privateRemote || selectedSlot?.privateRemote || false) : false,
        idStudio: selectedEvent?.selectedAddress || selectedSlot?.selectedAddress || null,
        bookingDate: selectedDate,
        bookedByUserId: idPediatrician,
        bookedByUserFullName: pediatricianName || null,
        bookedByUserType: 'Pediatrician',
      };

      const response = await axios.post(apiUrl, requestPayload);

      // Check response status
      if (response.data.status === 1) {
        // Successful booking
        Swal.fire({
          icon: 'success',
          title: 'Thanks!',
          text: 'Booking is confirmed.',
          confirmButtonText: 'OK'
        }).then(() => {
          fetchAvailability(); // Refresh availability
          setcreateslotModal(false); // Close the modal
        });
      } else {
        // Handle other statuses by displaying the message from API
        setFormError(response.data.message || 'Failed to save booking. Please try again.');
      }
    } catch (error) {
      // Display error from API or fallback to a general error message
      if (error.response && error.response.data) {
        setFormError(error.response.data.message || 'Error saving booking. Please try again.');
      } else {
        setFormError('Error saving booking. Please try again.');
      }
      console.error('Error saving booking:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const CustomToolbar = (props) => {
    const goToWeekView = () => {
      props.onView('week');
    };

    const goToDayView = () => {
      props.onView('day');
    };

    const goToAgendaView = () => {
      props.onView('agenda');
    };

    return (
      <div className="rbc-toolbar custom-toolbar"> {/* Added custom-toolbar class */}
        <span className="rbc-btn-group toolbar-icons">
          {/* Day View */}
          <button
            type="button"
            onClick={goToDayView}
            className={props.view === 'day' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          >
            <img src='assets/images/view_stream.png' />

          </button>
          {/* Agenda View */}
          <button
            type="button"
            onClick={goToAgendaView}
            className={props.view === 'agenda' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          >
            <img src='assets/images/calendar_view_day.png' />

          </button>

          {/* Week View */}
          <button
            type="button"
            onClick={goToWeekView}
            className={props.view === 'week' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          > <img src='assets/images/calendar_view_week.png' />
          </button>

        </span>
      </div>
    );
  };

  const [createslotmodal, setcreateslotModal] = React.useState(false);
  const createslotOpen = () => setcreateslotModal(true);
  // const createslotClose = () => setcreateslotModal(false);

  const createslotClose = () => {
    setcreateslotModal(false); // Close the modal
    setServiceEventDetails({}); // Optionally, reset the event details when closing
    setSelectedServices([]); // Reset to empty array
    setFilteredServices([]); // Reset to empty array
  };

  const [serviceModal, setServiceModal] = useState(false);
  const [serviceEventDetails, setServiceEventDetails] = useState({}); // Initialize as an empty object

  // const serviceOpen = () => setserviceModal(true);
  // const serviceClose = () => setserviceModal(false);

  const serviceOpen = (eventDetails) => {
    console.log("Event details received:", eventDetails);
    if (eventDetails) {
      setServiceEventDetails(eventDetails);
      setServiceModal(true);
      console.log("Modal state after open:", serviceModal); // Check if state is true
    } else {
      console.error("Event details are not valid:", eventDetails);
    }
  };

  const serviceClose = () => {
    setServiceModal(false); // Close the modal
    setServiceEventDetails({}); // Optionally, reset the event details when closing
    setSelectedServices([]);
    setFilteredServices([]);
  };

  console.log("Service selected Event:", serviceEventDetails);



  const [servicetotalmodal, setservicetotalModal] = React.useState(false);
  // const serviceCartOpen = () => setservicetotalModal(true);
  // Modified serviceCartOpen with validation
  const serviceCartOpen = () => {
    if (selectedServices.length === 0) {
      setError("Please select at least one service to continue.");
    } else {
      setError(null); // Clear any previous errors
      setservicetotalModal(true);

      // Call API with selectedServices for filtering
      fetchFilteredServices(selectedServices);
    }
  };
  // const servicetotalClose = () => setservicetotalModal(false);
  const servicetotalClose = () => {
    setservicetotalModal(false); // Close the modal
    // setSelectedServices([]); // Reset to empty array
    setFilteredServices([]); // Reset to empty array
  };

  // Function to generate the payload and call the API
  const handleConfirmPayment = async () => {
    // Calculate total services cost
    const totalServices = selectedFilteredServices.reduce((total, service) => total + (Number(service.cost) || 0), 0);

    // Calculate discount amount based on type (fixed or percentage)
    const discountAmount = discountType === '€'
      ? Number(discountValue || 0)
      : totalServices * (Number(discountValue || 0) / 100);

    // Validation: Ensure discount is not greater than or equal to totalServices
    if (discountAmount >= totalServices) {
      setErrorModalMessage("Il valore dello sconto non può essere maggiore o uguale al totale dei servizi.");
      setErrorModalOpen(true);
      return; // Exit the function early to prevent the API call
    }

    // Amount after discount
    const discountedAmount = Math.max(totalServices - discountAmount, 0);

    // Apply stamp duty if discounted amount exceeds €77.47
    const stamp = discountedAmount > 77.47 ? 2.00 : 0.00;

    // Subtotal after applying stamp duty
    const subtotalAfterStampDuty = discountedAmount + stamp;

    // Calculate platform margin (6.1% of subtotal after stamp duty, includes VAT)
    const platformMarginOnParent = subtotalAfterStampDuty * 0.061;

    // Final total amount
    const amount = subtotalAfterStampDuty + platformMarginOnParent;

    // Map selected services to services array for payload
    const servicesArray = selectedFilteredServices.map((service) => ({
      idService: service.id,
      idServiceFrom: 'PediatricianServices',
      serviceAmount: parseFloat(service.cost),
    }));

    // Prepare payload with calculated values as numbers
    const payload = {
      bookingId: serviceEventDetails.id,
      servicesArray,
      discountType: discountType === '€' ? 'Value' : 'Percentage',
      discountTypeValue: Math.round(discountAmount * 100) / 100,
      discountAmount: Math.round(discountAmount * 100) / 100,
      totalServices: Math.round(totalServices * 100) / 100,
      stamp: Math.round(stamp * 100) / 100,
      platformMarginOnParent: Math.round(platformMarginOnParent * 100) / 100,
      amount: Math.round(amount * 100) / 100,
    };

    try {
      const response = await axios.post(`${Constants.bookingServiceSloat}`, payload);
      console.log('API Response:', response.data);

      if (response.data.status === 1) {
        confirmOpen();
      } else {
        // Display the exact API message in the error modal
        setErrorModalMessage(response.data.message || "An unexpected error occurred.");
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error booking service slot:', error);
      // Display the error message if available; otherwise, use a generic message
      const errorMessage = error.response?.data?.message || "Booking failed. Please try again.";
      setErrorModalMessage(errorMessage);
      setErrorModalOpen(true);
    }
  };


  const [confirmmodal, setconfirmModal] = React.useState(false);
  const confirmOpen = () => setconfirmModal(true);
  // const confirmClose = () => setconfirmModal(false);
  const confirmClose = () => {
    setOpenModal(false);
    setconfirmModal(false);
    setServiceModal(false); // Close the modal
    setcreateslotModal(false); // Close the modal
    setservicetotalModal(false); // Close the modal
    setDiscountValue(0); // Reset discount value
    setSelectedServices([]); // Reset to empty array
    setFilteredServices([]); // Reset to empty array
    setServiceEventDetails({}); // Optionally, reset the event details when closing
    fetchAvailability();  // Refresh the availability after deletion
  };

  // Format addresses into options
  const addressOptions = addresses.map((studio) => ({
    value: studio.id,
    label: `${studio.studio} - ${studio.street}, ${studio.province}, ${studio.zip}`
  }));

  // Helper function to calculate total services cost, ensuring a numeric result
  const totalServices = selectedFilteredServices.reduce((total, service) => total + (Number(service.cost) || 0), 0);

  // Calculate discount amount based on type (fixed or percentage)
  const discountAmount = discountType === '€'
    ? Number(discountValue || 0)
    : totalServices * (Number(discountValue || 0) / 100);

  // Amount after discount
  const discountedAmount = Math.max(totalServices - discountAmount, 0);

  // Apply stamp duty if discounted amount exceeds €77.47
  const stampDuty = discountedAmount > 77.47 ? 2.00 : 0.00;

  // Subtotal after applying stamp duty
  const subtotalAfterStampDuty = discountedAmount + stampDuty;

  // Calculate platform margin (6.1% of subtotal after stamp duty, includes VAT)
  const platformMarginOnParent = subtotalAfterStampDuty * 0.061;

  // Final total amount
  const total = subtotalAfterStampDuty + platformMarginOnParent;

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (pgloading) {
  //   return <div>Loading...</div>;
  // }



  return (
    <>
      <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
          <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div>
              <Grid container spacing={3} className='items-center'>
                <Grid item lg={8} md={12} xs={12}>
                  <div className='flex gap-3 mb-6 font-semibold'>
                    <Link to="/agenda-calander"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                  </div>
                </Grid>
                <Grid item lg={4} md={12} xs={12}>
                  <form>
                    <div className='relative'>
                      <div>
                        <input
                          type="text"
                          className='pl-10 w-full py-2 border border-gray-300 rounded-lg'
                          placeholder='Cerca'
                          value={keyword}
                          onChange={handleSearchChange} // Handle search input
                        />
                        <button type="button" className='absolute top-2 left-3 text-gray-400'>
                          <i className="fa-regular fa-magnifying-glass"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </Grid>
              </Grid>
              <div className='pt-6 mb-6'>
                <div className='flex gap-4 justify-end'>
                  <Link
                    to="javascript:void(0);"
                    className={getLinkClass('privateRemote')} // Conditionally apply classes
                    onClick={() => handleFilterClick('privateRemote')} // Handle button click for filter
                  >
                    Privato da remoto
                  </Link>

                  <Link
                    to="javascript:void(0);"
                    className={getLinkClass('privateClinic')} // Conditionally apply classes
                    onClick={() => handleFilterClick('privateClinic')} // Handle button click for filter
                  >
                    Privato in studio
                  </Link>

                  <Link
                    to="javascript:void(0);"
                    className={getLinkClass('ssnClinic')} // Conditionally apply classes
                    onClick={() => handleFilterClick('ssnClinic')} // Handle button click for filter
                  >
                    SNN in studio
                  </Link>

                  <Link
                    to="javascript:void(0);"
                    className={getLinkClass('ssnRemote')} // Conditionally apply classes
                    onClick={() => handleFilterClick('ssnRemote')} // Handle button click for filter
                  >
                    SNN da remoto
                  </Link>
                </div>
              </div>
              <Grid container spacing={3}>
                <Grid item lg={4} md={12} xs={12}>
                  <div className='calendarBox pb-7 border-b border-gray-300'>
                    <CalanderDate
                      onChange={handleDateChange}
                      value={calanderDate}
                      showNeighboringMonth={false}
                      tileClassName={({ date, view }) => {

                        if (date.getDate() === 5 || date.getDate() === 12) {
                          return 'orange';
                        }
                        if (date.getDate() === 23) {
                          return 'green';
                        }
                        if (date.getDate() === 15) {
                          return 'purple';
                        }
                        return '';
                      }}
                    />
                  </div>
                  <div className='pt-7'>
                    <h3 className='text-lg font-semibold mb-5'><i class="fa-regular fa-gear mr-2"></i> Impostazioni</h3>
                    {/* <Link to="/availability-calander" className='block w-full items-center mb-2 relative py-2 px-4 bg-[#828DEE]'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-white'>
                          <i class="fa-light fa-calendar-days"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-white'>Gestisci calendario orari</h2>
                        </div>
                      </div>
                    </Link> */}
                    <Link to="/availability-calander" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-light fa-calendar-days"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Gestisci calendario orari</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/setting-visit" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-sharp fa-regular fa-gear"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Impostazioni visita</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/services-list-modify" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-solid fa-list-ul"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Lista prestazioni</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={8} md={12} xs={12} className='lg:text-right'>
                  <div className='mt-10'>
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div className='cal calendarVisit'>
                        <Calendar
                          localizer={localizer}
                          events={events} // Use dynamic events from API
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: 600 }}
                          defaultView="week" // Default view is week
                          views={['week', 'day', 'agenda']} // Include multiple views
                          selectable // Enable slot selection
                          eventPropGetter={eventPropGetter} // Custom event color logic
                          onSelectEvent={handleEventClick} // Event click handler
                          onSelectSlot={handleSlotSelect} // Slot select handler for empty time slots
                          formats={{
                            timeGutterFormat: 'HH:mm', // Show times in 24-hour format
                            dayFormat: (date, culture, localizer) =>
                              `${localizer.format(date, 'ddd', culture)} ${localizer.format(date, 'D', culture)}`, // Format for day names
                            eventTimeRangeFormat: ({ start, end }, culture, localizer) => // Show event time range in 24-hour format
                              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                          }}
                          components={{
                            toolbar: CustomToolbar,  // Use custom toolbar here
                          }}
                          length={7} // Show 7 days in the agenda view
                          agendaLength={7} // Limit agenda view to show 7 days
                          //defaultDate={startOfWeek} // Show the selected week
                          defaultDate={calanderDate}
                          step={30} // Time slots of 30 minutes
                          timeslots={2} // 2 timeslots per hour (e.g., 30 minutes each)
                          firstDay={0} // Start week from Monday (0 = Sunday, 1 = Monday)
                          getNow={() => moment().toDate()} // Make sure the current date is used as the starting point
                        />
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <button onClick={handleAddNewSlotClick} className='fixed right-3 bottom-3 w-12 h-12 bg-[#828DEE] rounded-full text-3xl text-white shadow-xl'><i class="fa-solid fa-plus"></i></button>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-[420px] w-[90%] bg-[#F8FFFC] py-10 px-6 rounded-2xl">
            <div className='text-center'>
              {selectedEvent && (
                <>
                  <div className='flex gap-2 justify-between mb-6'>
                    <div>
                      <button type='button' onClick={handleCloseModal} className='underline text-[#828DEE]'>Annulla</button>
                    </div>
                    <Link target="_blank" rel="noopener noreferrer" to={`/meet/${btoa(selectedEvent.bookingDate + '-' +selectedEvent.id +'-'+ selectedEvent.patientFirstName +' '+ selectedEvent.patientLastName)}`} className='underline text-[#828DEE]'>Videochiamata</Link>

                  </div>
                  <h2 className='text-lg font-semibold mb-2'>
                    {moment(selectedEvent.bookingDate).format('Do MMM, YYYY')}
                  </h2>
                  <p className='mb-3 text-base'>
                    orario {selectedEvent.slotStart} - {selectedEvent.slotEnd}
                  </p>
                  <h3
                    className='font-semibold mb-2'
                    style={{
                      color: selectedEvent.ssnClinic ? '#FFC885' :
                        selectedEvent.ssnRemote ? '#FFA232' :
                          selectedEvent.privateClinic ? '#828DEE' :
                            selectedEvent.privateRemote ? '#5EAF88' :
                              '#FFC885' // Fallback color if none of the conditions match
                    }}
                  >
                    {selectedEvent.ssnClinic ? 'SSN IN STUDIO' :
                      selectedEvent.ssnRemote ? 'SSN DA REMOTO' :
                        selectedEvent.privateClinic ? 'Privato IN STUDIO' :
                          selectedEvent.privateRemote ? 'Privato DA REMOTO' :
                            'Unknown Service'} {/* Fallback title if none match */}
                  </h3>
                  <h3 className='font-semibold mb-2' style={{
                    color: selectedEvent.ssnClinic ? '#FFC885' :
                      selectedEvent.ssnRemote ? '#FFA232' :
                        selectedEvent.privateClinic ? '#828DEE' :
                          selectedEvent.privateRemote ? '#5EAF88' :
                            '#FFC885' // Fallback color if none of the conditions match
                  }}>
                    {selectedEvent.studioAddress}
                  </h3>
                  <div className='mt-6 text-base'>
                    <p>Prenotata da <span className='font-semibold'>{selectedEvent.bookedByUserFullName}</span></p>
                    <p>Paziente <span className='font-semibold'>{selectedEvent.patientFirstName} {selectedEvent.patientLastName}</span></p>
                    <p className='mt-5'>Note del tutore</p>
                    <p>{selectedEvent.parentNotes || 'Nessuna nota'}</p>
                  </div>
                  <div className='mt-8'>
                    {selectedEvent.status === "Confirmed" ? (
                      // Do not show any buttons
                      <button type="button" onClick={handleCancelWithSwal} className='text-red-500 underline font-semibold'>Cancella visita</button>
                    ) : selectedEvent.status === "Booked" ? (
                      <>
                        <button type="button" onClick={handleCancelWithSwal} className='text-red-500 underline font-semibold'>Cancella visita</button>
                        {/* Render the payment button only if selectedFilteredServices has data */}
                        {services.length > 0 && (
                          <button type="button" onClick={() => serviceOpen(selectedEvent)} className="mt-5 min-w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Ottieni pagamento</button>
                        )}
                      </>
                    ) : selectedEvent.status === "Cancelled" ? (
                      <>
                        <button type="button" onClick={handleCancelWithSwal} className='text-red-500 underline font-semibold'>Cancella visita</button>
                        {/* Do not render the payment button */}
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </Box>
        </Modal>

        <Modal
          open={createslotmodal}
          onClose={createslotClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-[420px] w-[90%] bg-[#F8FFFC] p-6 rounded-2xl">
            {formError && (
              <div className="mb-4 text-red-500 text-center">
                {formError}
              </div>
            )}

            {(selectedSlot || selectedEvent) && (
              <>
                <div className='flex gap-2 justify-between mb-6'>
                  <div>
                    <button type='button' onClick={createslotClose} className='underline text-[#828DEE]'>Annulla</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      onClick={handleSave}
                      className='underline text-[#828DEE]'
                      disabled={loadingSave}
                    >
                      {loadingSave ? 'Saving...' : 'Salva'}
                    </button>

                  </div>
                </div>

                <h2 className='text-center font-semibold text-2xl mb-4'>
                  {selectedEvent
                    ? `${selectedEvent.weekName} ${moment(selectedEvent.selectedDate).format('Do MMM YYYY')}`
                    : selectedSlot
                      ? `${selectedSlot.day} ${moment(selectedSlot.selectedDate).format('Do MMM YYYY')}`
                      : 'Create Booking'}
                </h2>

                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>Data di prenotazione</div>
                  <div className='w-100 mb-3'>
                    <TextField
                      type='date'
                      variant="standard"
                      className='w-full ps-20'
                      value={selectedDate || ''}
                      onChange={(e) => {
                        const newDate = e.target.value;
                        setSelectedDate(newDate);
                      }}
                      InputProps={{
                        inputProps: {
                          min: new Date().toISOString().split("T")[0],
                        },
                      }}
                    />

                  </div>
                </div>


                <div className='mb-3'>
                  {/* <FormControl fullWidth variant="standard">
                    <InputLabel id="selectPatient-label">Scegli un paziente</InputLabel>
                    <Select
                      name="iPatient"
                      value={patientList.find(patient => patient.value === selectedPatient) || null}
                      onChange={(selectedOption) => {
                        setSelectedPatient(selectedOption ? selectedOption.value : null);
                      }}
                      options={patientList}
                      placeholder="Scegli un paziente"
                      isClearable
                    />
                  </FormControl> */}
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="selectPatient-label">Scegli un paziente</InputLabel>
                    <Select
                      value={selectedPatient}
                      onChange={(patient) => {
                        setSelectedPatient(patient);

                        // Set isSSN and isPrivate based on patient type
                        const isSSNType = patient.type === 'SSN';
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isSSN: isSSNType, isPrivate: !isSSNType });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isSSN: isSSNType, isPrivate: !isSSNType });
                        }
                      }}
                      options={patientList} // assuming this is the list of patients
                      placeholder="Select Patient"
                    />
                  </FormControl>
                </div>

                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>dalle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.start) || (selectedEvent?.slotStart) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, start: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotStart: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>alle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.end) || (selectedEvent?.slotEnd) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, end: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotEnd: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                {/* SSN Switch */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    SSN
                    <Switch
                      checked={selectedEvent?.isSSN || selectedSlot?.isSSN || false}
                      onChange={() => {
                        const isCurrentlySSN = selectedEvent?.isSSN || selectedSlot?.isSSN || false;
                        const newValue = !isCurrentlySSN;
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isSSN: newValue, isPrivate: false });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isSSN: newValue, isPrivate: false });
                        }
                      }}
                    />
                  </label>
                  {selectedEvent?.isSSN || selectedSlot?.isSSN ? (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Radio
                          checked={selectedEvent?.ssnClinic || selectedSlot?.ssnClinic}
                          onChange={() => {
                            if (selectedSlot) {
                              setSelectedSlot({ ...selectedSlot, ssnClinic: true, ssnRemote: false });
                            } else {
                              setSelectedEvent({ ...selectedEvent, ssnClinic: true, ssnRemote: false });
                            }
                          }}
                        />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Radio
                          checked={selectedEvent?.ssnRemote || selectedSlot?.ssnRemote}
                          onChange={() => {
                            if (selectedSlot) {
                              setSelectedSlot({ ...selectedSlot, ssnRemote: true, ssnClinic: false });
                            } else {
                              setSelectedEvent({ ...selectedEvent, ssnRemote: true, ssnClinic: false });
                            }
                          }}
                        />}
                        label="Da remoto"
                      />
                    </div>
                  ) : null}
                </div>

                {/* Private Switch */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    Privato
                    <Switch
                      checked={selectedEvent?.isPrivate || selectedSlot?.isPrivate || false}
                      onChange={() => {
                        const isCurrentlyPrivate = selectedEvent?.isPrivate || selectedSlot?.isPrivate || false;
                        const newValue = !isCurrentlyPrivate;
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isPrivate: newValue, isSSN: false });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isPrivate: newValue, isSSN: false });
                        }
                      }}
                    />
                  </label>
                  {selectedEvent?.isPrivate || selectedSlot?.isPrivate ? (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Radio
                          checked={selectedEvent?.privateClinic || selectedSlot?.privateClinic}
                          onChange={() => {
                            if (selectedSlot) {
                              setSelectedSlot({ ...selectedSlot, privateClinic: true, privateRemote: false });
                            } else {
                              setSelectedEvent({ ...selectedEvent, privateClinic: true, privateRemote: false });
                            }
                          }}
                        />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Radio
                          checked={selectedEvent?.privateRemote || selectedSlot?.privateRemote}
                          onChange={() => {
                            if (selectedSlot) {
                              setSelectedSlot({ ...selectedSlot, privateRemote: true, privateClinic: false });
                            } else {
                              setSelectedEvent({ ...selectedEvent, privateRemote: true, privateClinic: false });
                            }
                          }}
                        />}
                        label="Da remoto"
                      />
                    </div>
                  ) : null}
                </div>

                {(selectedEvent?.ssnClinic || selectedEvent?.privateClinic || selectedSlot?.ssnClinic || selectedSlot?.privateClinic) && (
                  <FormControl fullWidth>
                    <Select
                      id="selectAddress"
                      value={addressOptions.find(option => option.value === (selectedEvent?.selectedAddress || selectedSlot?.selectedAddress)) || null} // Find the selected address option
                      onChange={(selectedOption) => {
                        const value = selectedOption ? selectedOption.value : null;
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, selectedAddress: value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, selectedAddress: value });
                        }
                      }}
                      options={addressOptions.length > 0 ? addressOptions : [{ value: '', label: 'No Studio', isDisabled: true }]} // Use addressOptions; provide a fallback option if empty
                      placeholder="Studio di riferimento"
                      isClearable
                    />
                  </FormControl>
                )}
              </>
            )}

          </Box>
        </Modal>

        {/* Service added via booking wise */}
        <Modal
          open={serviceModal}
          onClose={serviceClose}
          eventDetails={serviceEventDetails} // Pass details to the modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-3/4 w-[90%] bg-[#F8FFFC]  p-6 rounded-2xl">
            <Grid container spacing={3}>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <div className='md:border-r border-black h-full'>
                  {serviceEventDetails.bookingDate && ( // Check if event details are available
                    <>
                      <p className='mb-3 font-semibold text-[#828DEE]'>Riepilogo visita</p>
                      <p className='font-semibold'>{moment(serviceEventDetails.bookingDate).format('Do MMM, YYYY')}</p>
                      <p>orario {serviceEventDetails.slotStart} - {serviceEventDetails.slotEnd}</p>
                      <p
                        className='font-semibold mb-2'
                        style={{
                          color: serviceEventDetails.ssnClinic ? '#FFC885' :
                            serviceEventDetails.ssnRemote ? '#FFA232' :
                              serviceEventDetails.privateClinic ? '#828DEE' :
                                serviceEventDetails.privateRemote ? '#5EAF88' :
                                  '#FFC885' // Fallback color if none of the conditions match
                        }}
                      >
                        {serviceEventDetails.ssnClinic ? 'SSN IN STUDIO' :
                          serviceEventDetails.ssnRemote ? 'SSN DA REMOTO' :
                            serviceEventDetails.privateClinic ? 'Privato IN STUDIO' :
                              serviceEventDetails.privateRemote ? 'Privato DA REMOTO' :
                                'Unknown Service'} {/* Fallback title if none match */}
                      </p>
                      <p className='font-semibold mb-2' style={{
                        color: serviceEventDetails.ssnClinic ? '#FFC885' :
                          serviceEventDetails.ssnRemote ? '#FFA232' :
                            serviceEventDetails.privateClinic ? '#828DEE' :
                              serviceEventDetails.privateRemote ? '#5EAF88' :
                                '#FFC885' // Fallback color if none of the conditions match
                      }}>
                        {serviceEventDetails.studioAddress}
                      </p>
                      <p>Prenotata da <span className='font-semibold'>{serviceEventDetails.bookedByUserFullName}</span></p>
                      <p>Paziente <span className='font-semibold'>{serviceEventDetails.patientFirstName} {serviceEventDetails.patientLastName}</span></p>
                      <p className='mt-3'>Note del tutore</p>
                      <p>{serviceEventDetails.parentNotes || 'Nessuna nota'}</p>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <Grid container spacing={3} className='justify-center'>
                  <Grid item lg={6} md={12} sm={12} xs={12} className='text-center'>
                    <h2 className='text-2xl font-semibold'>Prestazioni</h2>
                    <p className='font-semibold text-[#828DEE] mb-5'>Importo pre-autorizzato al paziente € 20,00</p>

                    {loading && <p>Loading services...</p>}
                    {error && <p className="text-red-500">{error}</p>}

                    {services.map((service) => (
                      <div key={service.id} className='flex border-b py-2 justify-between items-center'>
                        <div>
                          <label><Checkbox
                            checked={selectedServices.includes(service.id)}
                            onChange={() => handleServiceSelect(service.id)}
                          />  {service.serviceName}</label>
                        </div>
                        <div className='text-sm'>€ {service.cost}</div>
                      </div>
                    ))}

                    <div className='mt-5'>
                      <button className='underline text-[#828DEE]'>Aggiungi prestazione</button>
                    </div>
                    <div className="mt-10">
                      <button onClick={serviceCartOpen} className="min-w-80 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Continua</button>
                    </div>
                    <div className='mt-5'>
                      <button className='underline text-[#828DEE]'>Effettua la visita gratuitamente</button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={servicetotalmodal}
          onClose={servicetotalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-3/4 w-[90%] bg-[#F8FFFC]  p-6 rounded-2xl">
            <Grid container spacing={3}>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <div className='md:border-r border-black h-full'>
                  {serviceEventDetails.bookingDate && ( // Check if event details are available
                    <>
                      <p className='mb-3 font-semibold text-[#828DEE]'>Riepilogo visita</p>
                      <p className='font-semibold'>{moment(serviceEventDetails.bookingDate).format('Do MMM, YYYY')}</p>
                      <p>orario {serviceEventDetails.slotStart} - {serviceEventDetails.slotEnd}</p>
                      <p
                        className='font-semibold mb-2'
                        style={{
                          color: serviceEventDetails.ssnClinic ? '#FFC885' :
                            serviceEventDetails.ssnRemote ? '#FFA232' :
                              serviceEventDetails.privateClinic ? '#828DEE' :
                                serviceEventDetails.privateRemote ? '#5EAF88' :
                                  '#FFC885' // Fallback color if none of the conditions match
                        }}
                      >
                        {serviceEventDetails.ssnClinic ? 'SSN IN STUDIO' :
                          serviceEventDetails.ssnRemote ? 'SSN DA REMOTO' :
                            serviceEventDetails.privateClinic ? 'Privato IN STUDIO' :
                              serviceEventDetails.privateRemote ? 'Privato DA REMOTO' :
                                'Unknown Service'} {/* Fallback title if none match */}
                      </p>
                      <p className='font-semibold mb-2' style={{
                        color: serviceEventDetails.ssnClinic ? '#FFC885' :
                          serviceEventDetails.ssnRemote ? '#FFA232' :
                            serviceEventDetails.privateClinic ? '#828DEE' :
                              serviceEventDetails.privateRemote ? '#5EAF88' :
                                '#FFC885' // Fallback color if none of the conditions match
                      }}>
                        {serviceEventDetails.studioAddress}
                      </p>
                      <p>Prenotata da <span className='font-semibold'>{serviceEventDetails.bookedByUserFullName}</span></p>
                      <p>Paziente <span className='font-semibold'>{serviceEventDetails.patientFirstName} {serviceEventDetails.patientLastName}</span></p>
                      <p className='mt-3'>Note del tutore</p>
                      <p>{serviceEventDetails.parentNotes || 'Nessuna nota'}</p>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <Grid container spacing={3} className='justify-center'>

                  <Grid item lg={6} md={12} sm={12} xs={12} className='text-center'>
                    <h2 className='text-2xl font-semibold'>Prestazioni</h2>
                    <p className='font-semibold text-[#828DEE] mb-5'>Importo pre-autorizzato al paziente € 20,00</p>
                    <p className="text-center text-red-500" id="error-modal-description">{errorModalMessage}</p>
                    <div className='flex gap-3 mt-8 mb-8 justify-center items-center'>
                      <p>Vuoi effettuare uno sconto?</p>
                      <div>
                        <select
                          className='h-[40px] border border-[#bfc4c2] rounded px-2'
                          value={discountType}
                          onChange={(e) => setDiscountType(e.target.value)}
                        >
                          <option value="€">€</option>
                          <option value="%">%</option>
                        </select>
                      </div>
                      <div>
                        <TextField
                          size="small"
                          className="w-20"
                          placeholder="00.00"
                          value={discountValue || ''}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (/^\d*\.?\d*$/.test(inputValue)) {  // Allow only numbers and a single dot
                              setDiscountValue(inputValue);
                            }
                          }}
                          onBlur={() => setDiscountValue(parseFloat(discountValue) || 0)} // Convert to number on blur
                        />
                      </div>
                    </div>

                    {/* Services Display */}
                    {selectedFilteredServices.map((service) => (
                      <div key={service.id} className='flex border-b py-2 justify-between items-center'>
                        <div>{service.serviceName}</div>
                        <div className='text-sm'>€ {(Number(service.cost) || 0).toFixed(2)}</div>
                      </div>
                    ))}

                    {/* Display calculations */}
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div>Servizi totali</div>
                      <div className='text-sm'>€ {totalServices.toFixed(2)}</div>
                    </div>
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div>Sconto</div>
                      <div className='text-sm'>-€ {discountAmount.toFixed(2)}</div>
                    </div>
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div></div>
                      <div className='text-sm'>€ {discountedAmount.toFixed(2)}</div>
                    </div>
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div>Timbro</div>
                      <div className='text-sm'>€ {stampDuty.toFixed(2)}</div>
                    </div>
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div></div>
                      <div className='text-sm'>€ {subtotalAfterStampDuty.toFixed(2)}</div>
                    </div>
                    <div className='flex pt-2 justify-end gap-10 items-center font-semibold'>
                      <div>Margine della piattaforma sul genitore</div>
                      <div className='text-sm'>€ {platformMarginOnParent.toFixed(2)}</div>
                    </div>
                    <div className='flex mt-3 py-3 justify-end gap-10 items-center font-semibold border-t'>
                      <div>Quantità</div>
                      <div className='text-sm'>€ {total.toFixed(2)}</div>
                    </div>

                    {/* Confirm Payment Button */}
                    <div className="mt-10">
                      <button onClick={handleConfirmPayment} className="min-w-80 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Conferma pagamento</button>
                    </div>
                    <div className='mt-5'>
                      <button className='underline text-[#828DEE]'>Effettua la visita gratuitamente</button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={confirmmodal}
          onClose={confirmClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal w-[300px] bg-[#828DEE] py-10 px-6 rounded-2xl text-center">
            <div className='mb-5 text-center'><img className='w-[130px] m-auto' src='assets/images/stars.png' /></div>
            <h2 className='text-white font-semibold text-2xl mb-5'>Richiesta pagamento in corso</h2>
            <p className='text-white mb-10'>Stiamo effettuando la richiesta di pagamento, potrai vedere la ricevuta nella sezione profilo ricevute</p>
            <button onClick={confirmClose} className='h-[54px] w-full bg-white text-[#828DEE] font-semibold rounded-full'>Chiudi</button>
          </Box>
        </Modal>

      </div>
    </>
  );
}