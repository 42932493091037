import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Constants } from '../../../endPoints';
import PersonalDetails from "../../Profile/PersonalDetails";
import ProfessionalDataForm from "../../Profile/ProfesstionalData";
import Swal from 'sweetalert2';
import { useUser } from '../../../hooks/useUserContext'; // Import your custom hook to access UserContext

// import IdentificationDocument from "../Profile/IdentificationDocument";

export default function EditPersonalDetails() {
    const navigate = useNavigate();
    const { user, updateUser, forceUserDataUpdate } = useUser(); // Access the user info from the context
    const LOCAL_STORAGE_KEY = `userDetailsForm`;
    const [apiError, setApiError] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        taxCode: '',
        placeOfBirth: '',
        dateOfBirth: '',
        // regionalCode: '',
        // aslAgreementCode: '',
        // vatNumber: '',
        // fiscalAddress: '',
        // iban: '',
        // identityCardBack: '',
        // identityCardFront: '',

    });
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0]; // Get the selected file
        if (file) {
            formik.setFieldValue(fieldName, file); // Update formik's value with the file object
        }
    };


    useEffect(() => {
        // Fetch pediatrician details
        const fetchPediatricianDetails = async () => {
            const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
            if (userId) {
                try {
                    const response = await axios.get(`${Constants.viewPediatricianProfile}/${userId}`);
                    if (response.data.status === 1) {
                        const { data } = response.data;
                        setFormData({
                            firstName: data.firstName,
                            lastName: data.lastName,
                            dateOfBirth: data.dateOfBirth,
                            taxCode: data.taxCode,

                            placeOfBirth: data.placeOfBirth,
                            // regionalCode: data.regionalCode,
                            // placeOfBirth: data.placeOfBirth,
                            // aslAgreementCode: data.aslAgreementCode,
                            // vatNumber: data.vatNumber,
                            // fiscalAddress: data.fiscalAddress,
                            // iban: data.iban,
                            // identityCardBack: data.identityCardBack,
                            // identityCardFront: data.identityCardFront,
                        });
                    }
                } catch (error) {
                    console.error('Error fetching pediatrician details:', error);
                    setApiError('Failed to retrieve pediatrician details.');
                }
            }
        };
        fetchPediatricianDetails();
    }, []);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {

            const response = await axios.post(Constants.profilePediatrician, values, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201 || response.status === 200) {

                updateUser({
                    ...user,
                    firstName: values.get('firstName'),
                    lastName: values.get('lastName'),
                    dateOfBirth: values.get('dateOfBirth'),
                    taxCode: values.get('taxCode'),
                    placeOfBirth: values.get('placeOfBirth'),
                    // regionalCode: values.get('regionalCode'),
                    // provinceCode: values.get('provinceCode'),
                    // placeOfBirth: values.get('placeOfBirth'),
                    // aslAgreementCode: values.get('aslAgreementCode'),
                    // vatNumber: values.get('vatNumber'),
                    // fiscalAddress: values.get('fiscalAddress'),
                    // iban: values.get('iban'),
                    // identityCardBack: values.get('identityCardBack'),
                    // identityCardFront: values.get('identityCardFront'),

                });
                // // reset the step progress on completion
                // resetStep()
                // // User data updated successfully
                forceUserDataUpdate()
                Swal.fire({
                    title: 'Success',
                    text: 'Pediatrician data updated successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                navigate('/profile');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setApiError(error.response.data.message);
            } else {
                setApiError('An error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const taxCodeRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    const validationSchema = Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        dateOfBirth: Yup.date().required('Date of Birth is required'),
        taxCode: Yup.string().required('Tax Code is required'),
        placeOfBirth: Yup.string().required('placeOfBirth  is required'),
    });

    const formik = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error("userId not found in localStorage");
                return;
            }
            console.log("values incoming ", values)
            setFormData((prevData) => ({
                ...prevData,
                ...values,
            }));

            const formDataToSend = new FormData();

            Object.keys(values).forEach((key) => {
                formDataToSend.append(key, values[key]);
            });

            formDataToSend.append('idPediatrician', userId);

            await handleSubmit(formDataToSend);
        },
    });

    return (
        <>
            <div className='flex gap-3 mb-12 font-semibold'>
                <Link to="/profile"><i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link>
                <span>/</span>
                <span>Anagrafica</span>
            </div>
            <Grid container spacing={3} className='justify-center'>
                <Grid item xl={6} lg={7} md={8} xs={12}>

                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <PersonalDetails formik={formik} />
                        </>


                        <div className="mb-6 mt-10">
                            <button
                                className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                type="submit"
                            >
                                 {loading ? <CircularProgress /> : 'Salva'}
                            </button>
                        </div>


                    </form>
                </Grid>
            </Grid>
        </>
    );
}
