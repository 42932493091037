import React, { useState } from "react";
import VideoCallComponent from "../VideoCall/VideoCallComponent";
import { useParams } from 'react-router-dom';


export const VideoCallPopup = () => {

  const { channelId } = useParams();
  const decodeString = atob(channelId)
  const splitString = decodeString.split('-');
  let getUserName = splitString[splitString.length - 1];
  getUserName = getUserName === "null null" ? "Default" : getUserName; // Handles null string
  const appId = "165faa0223714e92adf43ea8fb127e22";   //App id from Agora  dev env(Testing)//165faa0223714e92adf43ea8fb127e22
  const token = "006165faa0223714e92adf43ea8fb127e22IACycGi0/dsYQFSXV5ezRtDIzKpyW/W4AOkqDYfDTF99PSfIg8kAAAAAIgB4rb4bud02ZwQAAQC53TZnAgC53TZnAwC53TZnBAC53TZn"
  return (
    <VideoCallComponent appId={appId} channel={channelId} token={token} userName={getUserName} />
  );
};

export default VideoCallPopup;
