import { Grid } from '@mui/material'
import React from 'react'

export default function AboutApp() {
  return (
    <>
    <section className='md:py-28 py-16'>
        <div className='container mx-auto px-4 '>
            <Grid container columns={12} spacing={4} className='items-center justify-center'>
                <Grid item lg={4}>
                    <img src='assets/images/aboutbg.jpg' className='rounded-xl shadow-xl'/>
                </Grid>
                <Grid item lg={6}>
                    <h2 className='md:text-5xl sm:text-4xl text-3xl mb-5 font-semibold'>Expert Pediatric care at your Fingertips —</h2>
                    <h3 className='text-2xl mb-7 text-gray-500'>Ensuring your child's health and happiness, anytime, anywhere.</h3>
                    <p className='mb-7'>Welcome to Kidocare, your trusted companion in your child's health and well-being. Our app is dedicated to providing parents with reliable, accessible, and expert pediatric care right at their fingertips.</p>
                    <a href='#' className='font-bold bg-amber-400 p-4 inline-block px-10 rounded-full text-white hover:bg-amber-500 transition '>View Details</a>
                </Grid>
            </Grid>
        </div>
    </section>
    </>
  )
}
