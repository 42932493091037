import React from 'react'
import Homebanner from './Homebanner'
import AboutApp from './AboutApp'
import Pediatrician from './Pediatrician'
import Storieslist from '../Stories/Storieslist'
import CommunityList from '../Community/CommunityList'
import Footer from '../../Layouts/Footer'
import Header from '../../Layouts/Header'


export default function () {
  return (
    <>
    <Header/>
        <Homebanner/>
        <AboutApp/>
        <Pediatrician/>
        <section className='md:py-28 py-16'>
          <div className='container mx-auto px-4 relative'>
            <h2 className="md:text-5xl sm:text-4xl text-3xl mb-6 font-semibold text-center">Latest Stories</h2>
            <div className='w-14 h-1 bg-theme-primary mx-auto mb-16'></div>
            <Storieslist/>
          </div>
        </section>
        <section className='md:py-28 py-16 bg-green-50'>
          <div className='container mx-auto px-4 relative'>
            <img src='assets/images/knowledge-animal.png' className='absolute left-16 top-[-35px] animationkite h-28 md:block hidden' alt=''/>
            <h2 className="md:text-5xl sm:text-4xl text-3xl mb-6 font-semibold text-center">Our Community</h2>
            <div className='w-14 h-1 bg-theme-primary mx-auto mb-16'></div>
              <CommunityList/>
              <div className="text-center mt-16"><a href="#" className="font-bold bg-amber-400 p-4 inline-block px-10 rounded-full text-white hover:bg-amber-500 transition " >View All Community</a></div>
          </div>
        </section>
        <Footer/>
    </>
  )
}
