
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom';
import CommunityList from './CommunityList'
import PatientCall from '../Call/PatientCall'

export default function Dashboard() {
    useEffect(() => {
        sessionStorage.removeItem('meta');
    }, [])

    return (
        <>
            <section>
                <div className='2xl:container mx-auto'>
                    <Grid container columns={12} spacing={2}>
                        <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                            <div>
                                <div className='mb-10'>
                                    <div className='flex justify-between items-center mb-10'>
                                        <div><img src='assets/images/logo-colored.png' className='w-44' /></div>
                                        <div>
                                            <Link to="#" className='relative text-xl inline-block border py-1 px-4 rounded-lg border border-[#C5C8C9]'>
                                                <i class="fa-regular fa-bell mr-2 text-green-500"></i> <span className='font-bold'>0</span>
                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                            </Link>
                                        </div>
                                    </div>
                                    <Grid container columns={12} spacing={4}>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white realtive'>
                                                <div className='bg-[#FFC885] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-01.png' className='mx-auto h-16' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg font-bold'>Calendario <br /> visite</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                                <div className='bg-[#FFC885] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-02.png' className='mx-auto h-16' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>3 <br /> Non evase</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <span className='w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]'></span>
                                                <div className='bg-[#AAB5FF] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-03.png' className='mx-auto h-16' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>0 <br /> Casi aperti</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={6}>
                                            <div className='shadow-xl rounded-lg bg-white relative'>
                                                <div className='bg-[#86D8B0] w-full rounded-lg leading-32 py-8'>
                                                    <img src='assets/images/dash-icon-04.png' className='mx-auto h-16' />
                                                </div>
                                                <div className='text-center px-5 md:py-10 py-5'>
                                                    <h2 className='md:text-2xl text-lg  font-bold'>Ricerca <br />  pazienti</h2>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <h3 className='md:text-3xl text-2xl mb-2 font-bold'>Advice from the community</h3>
                                    <CommunityList />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <PatientCall />
        </>
    )
}
