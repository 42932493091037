import React, { useEffect, useState } from "react";
import {
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import VideoDisplay from "./VideoDisplay";
import VideoControlButtons from "./VideoControlButtons";

export const VideoCallComponent = ({ appId, channel, token, userName }) => {
  const [calling, setCalling] = useState(false);
  const [isLocalFullscreen, setIsLocalFullscreen] = useState(false);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);

  const { localMicrophoneTrack, error: micError } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack, error: cameraError } = useLocalCameraTrack(cameraOn);
  const remoteUsers = useRemoteUsers();
  const joinInfo = useJoin(
    { appid: appId, channel: channel, token: token || null },
    calling,
  );

  usePublish(localMicrophoneTrack && localCameraTrack ? [localMicrophoneTrack, localCameraTrack] : []);

  const toggleFullscreen = (isLocal) => {
    if (isLocal) {
      setIsLocalFullscreen(!isLocalFullscreen);
    }
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(() => {
        console.log("Camera and microphone access granted.");
      })
      .catch(error => {
        console.error("Error getting media: ", error);
      });
  }, []);

  useEffect(() => {
    setCalling(true);
  }, []);

  useEffect(() => {
    if (cameraError) console.error("Camera track error:", cameraError);
    if (micError) console.error("Microphone track error:", micError);
  }, [cameraError, micError]);

  useEffect(() => {
    if (localCameraTrack) {
      localCameraTrack.play("local-video-container");
    } else {
      console.warn("localCameraTrack is unavailable or play function is missing.");
    }
  }, [localCameraTrack]);

  const handleCameraToggle = () => {
    setCamera(prev => !prev);
  };

  const handleMicToggle = () => {
    setMic(prev => !prev);
  };
  // Auto-disconnect after 30 minutes
  let time = 30;
  useEffect(() => {
    let disconnectTimeout;
    if (calling) {
      disconnectTimeout = setTimeout(() => {
        setCalling(false);
        console.log("Call ended after 30 minutes");
      }, time * 60000); //1800 * 1000 - 1800 seconds = 30 minutes
    }

    return () => clearTimeout(disconnectTimeout); // Clear timeout on cleanup
  }, [calling]);

  return (
    <div className="w-full h-full bg-white">
      <div className="absolute w-full h-full">
        {remoteUsers.length > 0 ? (
          remoteUsers.map((user) => (
            <VideoDisplay
              key={user.uid}
              user={user}
              isLocal={false}
              isFullscreen={!isLocalFullscreen}
              toggleFullscreen={() => toggleFullscreen(false)}
              type="remote"
              userName={userName}
              time={time}
            />
          ))
        ) : (
          <div className="flex items-center justify-center h-full text-black">
            Waiting for patient to join...
          </div>
        )}

        <div id="local-video-container" className="absolute bottom-20 right-4 w-36 h-24">
          <VideoDisplay
            user={{ uid: "local" }}
            isLocal={true}
            isFullscreen={isLocalFullscreen}
            toggleFullscreen={() => toggleFullscreen(true)}
            videoTrack={localCameraTrack}
            micTrack={localMicrophoneTrack}
            cameraOn={cameraOn}
            micOn={micOn}
            userName={userName}
            time={time}
          />
        </div>

        <VideoControlButtons
          micOn={micOn}
          cameraOn={cameraOn}
          setMic={handleMicToggle}
          setCamera={handleCameraToggle}
          setCalling={setCalling}
        />
      </div>
    </div>
  );
};

export default VideoCallComponent;