import React from 'react'
import { Link } from 'react-router-dom';

export default function Breadcumb(props) {
  return (
    <>
      <div className='flex gap-3 mb-12 font-semibold'>
        <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link>
      <span>/</span>
      <span>Documento di riconoscimento</span>
      </div>
    </>
  )
}
