
import React, { useState, useEffect, useContext } from 'react'
import { Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom';
import { Constants } from '../../endPoints';
import { useNavigate, useLocation } from 'react-router-dom' // Import useNavigate from react-router-dom
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, ErrorMessage } from 'formik';
import { LanguageContext } from '../../LanguageContext'; // Adjust the path if necessary
import IdentificationDocument from "../Profile/IdentificationDocument";
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce' // **[Change 1]**

const UserDetailsInfo = ({ handleNext, meta, }) => {
  const { user, updateUser, forceUserDataUpdate } = useUser(); // Access the user info from the context
  const [apiError, setApiError] = useState(''); // State to hold API error
  const navigate = useNavigate();
  const LOCAL_STORAGE_KEY = `${meta.id}_userDetailsForm`; // Key for storing form data in localStorage
  const [page, setPage] = useState(1); // Controls page navigation
  // For Region dropdown
  const [regionalCodes, setRegionalCodes] = useState([])
  const [regionCurrentPage, setRegionCurrentPage] = useState(1);
  const [regionTotalPages, setRegionTotalPages] = useState(1);
  const [regionLoading, setRegionLoading] = useState(false);
  const [regionHasMore, setRegionHasMore] = useState(true);

  // For City dropdown
  // const [cityOptions, setCityOptions] = useState([]);
  // const [cityCurrentPage, setCityCurrentPage] = useState(1);
  // const [cityTotalPages, setCityTotalPages] = useState(1);
  // const [cityLoading, setCityLoading] = useState(false);
  // const [cityHasMore, setCityHasMore] = useState(true);

   // **[Change 2]**
  // For City autocomplete

  const [cityOptions, setCityOptions] = useState([]);
  const [keyword, setKeyword] = useState(''); // New state for user's input
  const [cityLoading, setCityLoading] = useState(false);

  // For ASL dropdown
  const [aslOptions, setAslOptions] = useState([]);
  const [aslCurrentPage, setAslCurrentPage] = useState(1);
  const [aslTotalPages, setAslTotalPages] = useState(1);
  const [aslLoading, setAslLoading] = useState(false);
  const [aslHasMore, setAslHasMore] = useState(true);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const { t } = useTranslation()
  // Form data state
  const [formData, setFormData] = useState({
    idPediatrician: null,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    placeOfBirth: '',
    taxCode: '',
    registrationNumber: '',
    regionalCode: '',
    aslAgreementCode: '',
    vatNumber: '',
    taxDomicile: '',
    iban: '',
    identityCardBack: '',
    identityCardFront: '',
    sdi:'',
    pec:'',


  });


  // Load form data from localStorage on component mount
  useEffect(() => {

    console.log("META", meta)
    const savedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (savedFormData) {
      setFormData(JSON.parse(savedFormData)); // Restore form data from localStorage
    }
  }, []);

  // Save form data to localStorage on each form update
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData));
  }, [formData]); // Trigger every time formData changes

  // fetch all remote fields for apis
  useEffect(() => {

    fetchRegionalCodes()
  }, [formData]); // Trigger every time formData changes


  const handleFileChange = (event, fieldName) => {
    const file = event.currentTarget.files[0]; // Get the selected file
    if (file) {
      formik.setFieldValue(fieldName, file); // Update formik's value with the file object
    }
  };

  // const taxCodeValidateWordLength = (word) => {
  //   const minLength = /^.{16,}$/;
  //   const taxCodeValidationRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;

  //   // Check if the word meets the minimum length requirement (16 characters)
  //   if (!minLength.test(word)) {
  //     return "Tax code must be at least 16 characters long"; // Return error message for length
  //   }

  //   // Check if the word matches the tax code format
  //   if (!taxCodeValidationRegex.test(word)) {
  //     return "Tax code format is invalid"; // Return error message for invalid format
  //   }

  //   // If all conditions pass, return true (indicating valid tax code)
  //   return true;
  // };

  const regNumberValidateWordLength = (word) => {
    const minLength = /^.{2,}$/; // Minimum 2 characters

    // Check if the word meets the minimum length requirement
    if (!minLength.test(word)) {
      return "Registration number must be at least 2 characters long"; // Return error message for length
    }

    // If all conditions pass, return true (indicating valid registration number)
    return true;
  };

  // Formik validation schema for current page
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    dateOfBirth: Yup.date().required('Date of Birth is required').test('is-18', 'You must be at least 18 years old', function(value) {
      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();
      return age > 18 || (age === 18 && (monthDifference > 0 || (monthDifference === 0 && dayDifference >= 0)));
    }),
    placeOfBirth: Yup.string().required('Place of Birth is required'),
    taxCode: Yup.string()
    .matches(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/, t('invalid_tax_code_format'))
    .required(t('tax_code_required')),
    registrationNumber: Yup.string().required('N° iscrizione all\'albo is required').test(
      'is-valid-registration-number',
      'Registration number is not valid', // Default error message
      (value) => {
        if (value) {
          const validationResult = regNumberValidateWordLength(value);
          return validationResult === true ? true : validationResult;
        }
        return false;
      }
    ),
    regionalCode: Yup.string().required('Codice Regione di Convenzione is required'),
    aslAgreementCode: Yup.string().required('Codice ASL di convenzione is required'),
    vatNumber: Yup.string()
    .matches(/^\d{11}$/, t('vat_number_11_digits'))
    .required(t('vat_number_required')),
    taxDomicile: Yup.string().required('Domicilio Fiscale is required'),
    iban: Yup.string().required('IBAN is required'),
    identityCardBack: Yup.mixed().required('Identity card back is required'),
    identityCardFront: Yup.mixed().required('Identity card front is required'),
    sdi: Yup.string().notRequired(), // SDI is optional
    pec: Yup.string().notRequired(), // PEC is optional
  });

  // Formik initialization
  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true, // Allows formik to reinitialize with updated formData
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setFormData((prevData) => ({
        ...prevData,
        ...values, // Save current form values to formData
      }));

      // Submit form data to API
      values = { ...values, idPediatrician: meta.id }
      await handleSubmit(values);
    },
  });

  const handleNextPage = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...formik.values, // Save current form values to formData
    }));
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...formik.values,
    }));
    setPage((prevPage) => prevPage - 1);
  };

  // const handleSubmit = async (values) => {
  
  //   setRegistrationLoading(true);
  //   try {
  //     const response = await axios.post(Constants.profilePediatricianAppV2, values, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     if (response.status === 201 || response.status === 200) {
  //       if (meta.update) {
  //         navigate('/dashboard');
  //       } else {
  //         navigate('/login');
  //       }
  //       forceUserDataUpdate();
  //       localStorage.removeItem('meta');
  //     }
  //   } catch (error) {

  //     if (error.response && error.response.data) {
  //       console.log(error.response.data.message)
  //       setApiError(error.response.data.message)
  //     } else {
  //       setApiError('An error occurred. Please try again later.')
  //     }
  //   } finally {
  //     setRegistrationLoading(false);
  //   }

  // };
  const handleSubmit = async (originalValues) => {
    // Assuming originalValues is an object, convert it to FormData
    const formData = new FormData();
    Object.entries(originalValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the static field
    formData.append('isProfileCompleted', 'true');
  
    // Submit final form data to API
    setRegistrationLoading(true);
    try {
      const response = await axios.post(Constants.profilePediatricianAppV2, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201 || response.status === 200) {
        if (meta.update) {
          navigate('/dashboard');
        } else {
          navigate('/login');
        }
        forceUserDataUpdate();
        localStorage.removeItem('meta');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        setApiError(error.response.data.message);
      } else {
        setApiError('An error occurred. Please try again later.');
      }
    } finally {
      setRegistrationLoading(false);
    }
  };
  
  const fetchRegionalCodes = async (page) => {
    if (regionLoading || page > regionTotalPages) return;
    setRegionLoading(true);
    try {
      const response = await fetch(`${Constants.regionList}?&page=1&limit=500`)
      if (response.ok) {
        const data = await response.json()
        setRegionalCodes((prevCodes) => [...prevCodes, ...data.data]);
        setRegionTotalPages(data.pagination.totalPages);
        setRegionCurrentPage(data.pagination.currentPage);
        setRegionHasMore(data.pagination.currentPage < data.pagination.totalPages);
      } else {
        console.error('Failed to Region ')
      }
    } catch (error) {
      console.error('Error in fetching Region:', error)
    }
    finally {
      setRegionLoading(false);
    }
  }

  // const fetchCityOptions = async (regionalCode, page) => {
  //   if (cityLoading || page > cityTotalPages) return;
  //   setCityLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${Constants.regionWiseCity}&page=1&limit=50`,
  //     )
  //     if (response.ok) {
  //       const result = await response.json()
  //       setCityOptions((prevCities) => [...prevCities, ...result.data]);
  //       // setCityTotalPages(result.pagination.totalPages);
  //       // setCityCurrentPage(result.pagination.currentPage);
  //       // setCityHasMore(result.pagination.currentPage < result.pagination.totalPages);
  //     } else {
  //       console.error('Failed to fetch City options')
  //       setCityOptions([])
  //     }
  //   } catch (error) {
  //     console.error('Error fetching City options:', error)
  //     setCityOptions([])
  //   }
  //   finally {
  //     setCityLoading(false);
  //   }
  // }
    // **[Change 3]**
  // Debounced function to fetch cities based on keyword
  const fetchCitiesAutocomplete = debounce(async (input) => {
    if (input.length >= 1) {
      setCityLoading(true);
      try {
        const response = await axios.get(`${Constants.cityList}`, {
          params: {
            page: 1,
            limit: 5,
            keyword: input,
          },
        });
        if (response.data.status === 1 && response.data.data.length > 0) {
          setCityOptions(response.data.data);
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCityOptions([]);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCityOptions([]);
    }
  }, 300);

  // **[Change 4]**
  // Handler for placeOfBirth input change
  const handlePlaceOfBirthChange = (e) => {
    const inputValue = e.target.value;
    setKeyword(inputValue);
    formik.setFieldValue('placeOfBirth', inputValue);
    fetchCitiesAutocomplete(inputValue);
  };


  const fetchAslOptions = async (regionalCode, page) => {
    if (aslLoading || page > aslTotalPages) return;
    setAslLoading(true);
    if (regionalCode) {
      try {
        const response = await fetch(
          `${Constants.regionWiseASL}?idRegion=${regionalCode}&page=1&limit=50`,
        )
        if (response.ok) {
          const result = await response.json()
          setAslOptions((prevAsl) => [...prevAsl, ...result.aslList]);
          setAslTotalPages(result.totalPages);
          setAslCurrentPage(result.currentPage);
          setAslHasMore(result.currentPage < result.totalPages);
        } else {
          console.error('Failed to fetch ASL options')
          setAslOptions([])
        }
      } catch (error) {
        console.error('Error fetching ASL options:', error)
        setAslOptions([])
      } finally {
        setAslLoading(false);
      }
    } else {
      setAslOptions([])
    }
  }

  // Handle region change
  const handleRegionChange = async (event, formik) => {
    const regionalCode = event.target.value;
    formik.setFieldValue('regionalCode', regionalCode);
    setAslOptions([]);  // Reset ASL options on region change
    setAslCurrentPage(1);  // Reset ASL pagination
    await fetchAslOptions(regionalCode, 1);  // Fetch ASL based on new region
  };
  // **[Change 6]**
  // Removed 'fetchCityOptions' function and related state variables

  const handleScroll = (event, type) => {
    const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
    if (bottomReached) {
      if (type === 'region' && regionHasMore) {
        fetchRegionalCodes(regionCurrentPage + 1);
      } else if (type === 'asl' && aslHasMore) {
        fetchAslOptions(formik.values.regionalCode, aslCurrentPage + 1);
      }
      // Removed 'city' type handling
    }
  };


  // const handleScroll = (event, type) => {
  //   const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
  //   if (bottomReached) {
  //     if (type === 'region' && regionHasMore) {
  //       fetchRegionalCodes(regionCurrentPage + 1);
  //     } else if (type === 'city' && cityHasMore) {
  //       fetchCityOptions(formik.values.regionCode, cityCurrentPage + 1);
  //     } else if (type === 'asl' && aslHasMore) {
  //       fetchAslOptions(formik.values.regionCode, aslCurrentPage + 1);
  //     }
  //   }
  // };
  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      <Grid item xl={6} lg={7} md={8} xs={12}>
        <h2 className='mb-8 text-3xl font-semibold text-center'>
          {page === 1 ? 'Personal Information' : 'Other Information'}
        </h2>

        {/* Form rendering based on page */}
        <form onSubmit={formik.handleSubmit}>
          {page === 1 && (
            <>
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='firstName'
                  name='firstName'
                  label='First Name'
                  variant='outlined'
                  placeholder='Enter First Name'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='lastName'
                  name='lastName'
                  label='Last Name'
                  variant='outlined'
                  placeholder='Enter Last Name'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='dateOfBirth'
                  name='dateOfBirth'
                  label='Date of Birth'
                  variant='outlined'
                  type='date'
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                  helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                  InputProps={{ style: { height: '54px' } }}
                  InputLabelProps={{ shrink: true }}  // Ensure label does not overlap
                  inputProps={{  // Note the lowercase i in inputProps for native attributes
                    max: today  // Set the max attribute to today's date
                  }}

                />
              </div>
              {/* place of birth moved here */}
              <div className='mb-5' style={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  id='placeOfBirth'
                  name='placeOfBirth'
                  label='Place of Birth (City)'
                  variant='outlined'
                  placeholder='Enter Place of Birth'
                  value={formik.values.placeOfBirth}
                  onChange={handlePlaceOfBirthChange} // Changed handler
                  onBlur={formik.handleBlur}
                  error={formik.touched.placeOfBirth && Boolean(formik.errors.placeOfBirth)}
                  helperText={formik.touched.placeOfBirth && formik.errors.placeOfBirth}
                  InputProps={{ style: { height: '54px' } }}
                />
                {cityOptions.length > 0 && (
                  <ul
                    className="autocomplete-suggestions"
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      right: 0,
                      zIndex: 1000,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}
                  >
                    {cityOptions.map((city) => (
                      <li
                        key={city.id}
                        onClick={() => {
                          formik.setFieldValue('placeOfBirth', city.denominations);
                          setKeyword(city.denominations);
                          setCityOptions([]);
                        }}
                        style={{ padding: '8px', cursor: 'pointer' }}
                      >
                        {city.denominations}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='taxCode'
                  name='taxCode'
                  label='Tax Code'
                  variant='outlined'
                  placeholder='Enter Tax Code'
                  value={formik.values.taxCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.taxCode && Boolean(formik.errors.taxCode)}
                  helperText={formik.touched.taxCode && formik.errors.taxCode}
                  InputProps={{ style: { height: '54px' } }}
                />

              </div>


              <div className='mb-5'>
                <button
                  type='button'
                  className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                  onClick={async () => {
                    // Mark both fields as touched for validation feedback
                    formik.setTouched({
                      taxCode: true,
                      // registrationNumber: true,
                      placeOfBirth: true,
                    });

                    // Validate both fields
                    await formik.validateField('taxCode');
                    // await formik.validateField('registrationNumber');
                    await formik.validateField('placeOfBirth');

                    // // Validate the entire form and check for errors
                    // const errors = await formik.validateForm();

                    // If both fields are valid (no errors), proceed to the next page
                    if (!formik.errors.placeOfBirth && !formik.errors.taxCode  ) {
                      handleNextPage(); // Proceed to the next step
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {page === 2 && (
            <>
                          <div className='mb-5'>
                <TextField
                  fullWidth
                  id='registrationNumber'
                  name='registrationNumber'
                  label='N° iscrizione all\ (registrationNumber)'
                  variant='outlined'
                  value={formik.values.registrationNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                  helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
                  InputProps={{ style: { height: '54px' } }}
                />

              </div>


              <div className="mb-5">
                <TextField
                  select
                  fullWidth
                  id="regionalCode"
                  name="regionalCode"
                  label="Codice Regione di Convenzione (regionalCode)"
                  variant="outlined"
                  value={formik.values.regionalCode}
                  onChange={(event) => handleRegionChange(event, formik)} // Fetch city and ASL on region change
                  onBlur={formik.handleBlur}
                  error={formik.touched.regionalCode && Boolean(formik.errors.regionalCode)}
                  helperText={formik.touched.regionalCode && formik.errors.regionalCode}
                  InputProps={{ style: { height: '54px' } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                        onScroll: (event) => handleScroll(event, 'region'),
                      },
                    },
                  }}
                >
                  {regionalCodes.map((region, index) => (
                    <MenuItem key={index} value={region.code}>
                      {region.denomination}
                    </MenuItem>
                  ))}
                  {regionLoading && (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  )}
                </TextField>
              </div>
              {/* <div className='mb-5'>
                <TextField
                  //  select
                  fullWidth
                  id='placeOfBirth'
                  name='placeOfBirth'
                  label='Place of Birth/ (City)'
                  variant='outlined'
                  placeholder='Enter Place of Birth'
                  value={formik.values.placeOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.placeOfBirth && Boolean(formik.errors.placeOfBirth)}
                  helperText={formik.touched.placeOfBirth && formik.errors.placeOfBirth}
                  InputProps={{ style: { height: '54px' } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                        onScroll: (event) => handleScroll(event, 'city'),
                      },
                    },
                  }}
                />

              </div> */}
            

              <div className='mb-5'>
                <TextField
                  select
                  fullWidth
                  id='aslAgreementCode'
                  name='aslAgreementCode'
                  label='Codice ASL di convenzione(aslAgreementCode)'
                  variant='outlined'
                  value={formik.values.aslAgreementCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.aslAgreementCode && Boolean(formik.errors.aslAgreementCode)}
                  helperText={formik.touched.aslAgreementCode && formik.errors.aslAgreementCode}
                  InputProps={{ style: { height: '54px' } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                        onScroll: (event) => handleScroll(event, 'asl'),
                      },
                    },
                  }}
                >
                  {aslOptions.map((asl, index) => (
                    <MenuItem key={index} value={asl.code}>
                      {asl.denomination + '(' + asl.code + ')'}
                    </MenuItem>
                  ))}
                  {aslLoading && (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  )}
                </TextField>
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='vatNumber'
                  name='vatNumber'
                  label='P. IVA(vatNumber)'
                  variant='outlined'
                  value={formik.values.vatNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.vatNumber && Boolean(formik.errors.vatNumber)}
                  helperText={formik.touched.vatNumber && formik.errors.vatNumber}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='taxDomicile'
                  name='taxDomicile'
                  label='Domicilio Fiscale'
                  variant='outlined'
                  value={formik.values.taxDomicile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.taxDomicile && Boolean(formik.errors.taxDomicile)}
                  helperText={formik.touched.taxDomicile && formik.errors.taxDomicile}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>
              {/* SDI  */}
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='sdi'
                  name='sdi'
                  label='SDI'
                  variant='outlined'
                  value={formik.values.sdi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sdi && Boolean(formik.errors.sdi)}
                  helperText={formik.touched.sdi && formik.errors.sdi }
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>
              {/* PEC  */}
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='pec'
                  name='pec'
                  label='PEC'
                  variant='outlined'
                  value={formik.values.pec}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pec && Boolean(formik.errors.pec)}
                  helperText={formik.touched.pec && formik.errors.pec }
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='iban'
                  name='iban'
                  label='IBAN'
                  variant='outlined'
                  value={formik.values.iban}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.iban && Boolean(formik.errors.iban)}
                  helperText={formik.touched.iban && formik.errors.iban}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>


              <div className='mb-5 flex justify-between'>

                <button
                  type='button'
                  className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'
                  onClick={async () => {
                    // Mark both fields as touched for validation feedback

                    formik.setTouched({
                      // placeOfBirth: true,
                      registrationNumber: true,
                      regionalCode: true,
                      aslAgreementCode: true,
                      vatNumber: true,
                      taxDomicile: true,
                      iban: true,
                      sdi:true,
                      pec:true,
                    });

                    // Validate both fields
                    // await formik.validateField('placeOfBirth');
                    await formik.validateField('registrationNumber');
                    await formik.validateField('regionalCode');
                    await formik.validateField('aslAgreementCode');
                    await formik.validateField('vatNumber');
                    await formik.validateField('taxDomicile');
                    await formik.validateField('sdi');
                    await formik.validateField('pec');
                    await formik.validateField('iban');

                    if (
                      // !formik.errors.placeOfBirth &&
                      !formik.errors.regionalCode &&
                      !formik.errors.aslAgreementCode &&
                      !formik.errors.vatNumber &&
                      !formik.errors.taxDomicile &&
                      !formik.errors.sdi &&
                      !formik.errors.pec &&
                      !formik.errors.iban  &&
                      !formik.errors.registrationNumber 
                    )
                    {
                      handleNextPage(); // Proceed to the next step
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {page === 3 && (

            <>
              <IdentificationDocument navigation="registration" handleFileChange={handleFileChange} formik={formik} submitEnable={false} />
              <div className="mb-6 mt-10">
                <button
                    className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                    type="submit"
                >
                  {
                    registrationLoading ? <CircularProgress size={24} /> : 'Submit'
                  } 
                </button>
              </div>
            </>

          )}


          <div className="flex justify-center items-center h-full">
            {
              !(page === 3) && <button
                type="submit"
                className="w-[90px] h-[35px] bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                onClick={(e) => { navigate('/dashboard'); }}
              >
                Skip
              </button>
            }

          </div>

          {
            apiError && <div className="text-red-500 text-sm m-3">{apiError}</div>
          }


        </form>
      </Grid>
    </>
  );
};


export default UserDetailsInfo;