import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useUser } from '../hooks/useUserContext'; // Import your UserProvider
const Header = () => {

    const {user} = useUser();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className='fixed w-full z-50 bg-header py-2'>
            <nav>
                <div className="container mx-auto px-4">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                            <button
                onClick={toggleMenu}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                )}
                </button>
            </div>
            <div className="flex-1 flex items-center justify-between sm:items-stretch">
                <div className="flex-shrink-0">
                    <a href='/'><img className="h-10 w-auto" src="assets/images/logo.png" alt="Workflow" /></a>
                </div>
                <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-3">
                    <Link to="/" className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-full text-base font-medium transition-all">Home</Link>
                    <Link to="#" className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-full text-base font-medium transition-all">About</Link>
                    <Link  to="#" className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-full text-base font-medium transition-all">Services</Link >
                    {
                        user && <Link to="/dashboard" className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-full text-base font-medium transition-all">Dashboard</Link >
                    }
                    {
                        !user && <Link to="/register" className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-full text-base font-medium transition-all">Register as a Pediatrician</Link >
                    }
                    {
                        !user && <Link to="/login" className="bg-theme-primary text-white transition px-5 rounded-full hover:hover:bg-green-600 hover:text-white px-3 py-2 text-base font-medium">Login</Link >
                    }
                       
                    {/* <Link  to="/register" className="bg-amber-400 text-white transition px-5 rounded-full hover:hover:bg-amber-500 hover:text-white px-3 py-2 text-base font-medium">Register</Link > */}
                </div>
                </div>
            </div>
            </div>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="#" className="text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</Link>
            <Link to="#" className="text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">About</Link>
            <Link to="#" className="text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Services</Link>
            <Link to="/register" className="text-gray-700 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Register as a Pediatrician</Link>
            <Link to="/login" className="bg-theme-primary text-white transition px-5 rounded-full hover:hover:bg-green-600 hover:text-white px-3 py-2 text-base font-medium block">Login</Link>
            {/* <Link to="/register" className="bg-amber-400 text-white transition px-5 rounded-full hover:hover:bg-amber-500 hover:text-white px-3 py-2 text-base font-medium block">Register</Link> */}
            </div>
        </div>
        </nav>
    </header>
  );
};

export default Header;
