import { Grid, } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { resetSelectedStudios, setSelectedDays } from '../../redux/studioSlice';
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import Swal from 'sweetalert2'; // SweetAlert for alerts


export default function ChooseAvilability() {

    const dispatch = useDispatch();
    const idPediatrician = localStorage.getItem('userId');
    const [selectedDaysState, setSelectedDaysState] = useState([]); // Local state for selected days
    const [error, setError] = useState('');
    const [availabilityList, setAvailabilityList] = useState([]); // State to hold services list
    const [loading, setLoading] = useState(false);
    const [pgloading, setPgLoading] = useState(false);
    const navigate = useNavigate();

    const selectedStudios = useSelector((state) => state.studio.selectedStudios); // Selected studios from Redux
    const selectedDays = useSelector((state) => state.studio.selectedDays); // Selected days from Redux

    // Call API to get all visit details without pagination
    const fetchAvailability = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.availability}/${idPediatrician}`);
            const { status, list } = response.data;
    
            if (status === 1) {
                setAvailabilityList(list); // Set the availability list
            } else {
                setError(response.data.message || 'Failed to fetch availability details.');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                // Use the message from the API response
                setError(error.response.data.message || 'Error fetching availability details');
            } else if (error.request) {
                // The request was made but no response was received
                setError('No response received from server');
            } else {
                // Something happened in setting up the request that triggered an Error
                setError('Error in setting up the request');
            }
            console.error("Error fetching availability details:", error);
        } finally {
            setLoading(false);
        }
    };
    



    // Initialize local state from Redux on component mount
    useEffect(() => {
        fetchAvailability();

        if (selectedDays.length > 0) {
            setSelectedDaysState(selectedDays); // Pre-populate with selected days from Redux
        }

        const fetchPediatricianStatus = async () => {
            setPgLoading(true);
            try {
              const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
        
              // Access the correct path to `isAvailabilityAdded`
              if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
                if (response.data.data.isAvailabilityAdded==true) {
                  // If `isAvailabilityAdded` is true, redirect to the `agenda-calander` page
                  navigate('/agenda-calander');
                } else {
                  // If `isAvailabilityAdded` is false, stop loading and stay on the page
                  setPgLoading(false);
                }
              } else {
                setPgLoading(false);
              }
            } catch (error) {
              console.error("Error fetching pediatrician data:", error);
              setPgLoading(false);
            }
          };
        
          fetchPediatricianStatus();

        console.log('Selected Studios from Redux:', selectedStudios); // Log updated Redux state
        console.log('Selected Days from Redux:', selectedDays); // Log updated Redux state
    }, [selectedDays, selectedStudios, idPediatrician, navigate]);


    // Handle day selection
    const handleDayChange = (day) => {
        const updatedDays = selectedDaysState.includes(day)
            ? selectedDaysState.filter((d) => d !== day) // Unselect if already selected
            : [...selectedDaysState, day]; // Add new selection

        setSelectedDaysState(updatedDays); // Update local state
        dispatch(setSelectedDays(updatedDays)); // Update Redux state with the updated days
    };

    const handleReset = () => {
        console.log('Resetting studios'); // Check if this is printed
        dispatch(resetSelectedStudios()); // Dispatch action to reset the studio state
    };

    // Handle form submission
    const handleAddAvailabilityClick = (event) => {
        event.preventDefault();

        if (selectedDays.length === 0) {
            setError('Please select at least one day.');
        } else {
            dispatch(setSelectedDays(selectedDays)); // Save selected days in Redux
            setError(''); // Clear any existing errors
            navigate('/add-availability'); // Navigate to the next screen
        }
    };

    if (pgloading) {
        // return <div>Loading...</div>; // Show a pgloading message while fetching the data
    }


    return (
        <>

            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/calendar-visit"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                                <span>/</span>
                                <span>Disponibilità settimanale</span>
                            </div>
                            <div className='mt-10'>
                                <Grid container spacing={3} className='justify-center'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Grid container spacing={4} className="justify-center">
                                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                                <h2 className="text-center mb-8 text-xl font-semibold">Disponibilità settimanale</h2>
                                                <div className="p-3 rounded-lg border border-gray-300 mb-5">
                                                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                                                        <label
                                                            key={index}
                                                            className="flex py-3 px-3 bg-white rounded-lg justify-between gap-3 items-center mb-2 border"
                                                        >
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedDaysState.includes(day)} // Checked if day is in local state
                                                                    onChange={() => handleDayChange(day)} // Toggle day selection
                                                                />{' '}
                                                                {day}
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className={`inline-block text-xs py-1 px-2 rounded ${selectedDaysState.includes(day)
                                                                        ? 'bg-green-200 text-green-600'
                                                                        : 'bg-red-200 text-red-600'
                                                                        }`}
                                                                >
                                                                    {selectedDaysState.includes(day) ? 'Available' : 'Unavailable'}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>

                                                <div className="mb-5">
                                                    {/* <Link to="/add-date-specific-hours" className="w-full block py-3 px-5 text-center shadow-0 bg-white rounded-lg border border-[#828DEE] text-[#828DEE] font-semibold hover:bg-[#828DEE] transition-all hover:text-white">
                                                        Add Date Specific Hours
                                                    </Link> */}
                                                </div>
                                            </Grid>
                                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                                <h2 className="text-center mb-8 text-xl font-semibold">Slot</h2>
                                                {error && <p style={{ color: '#e91010', marginBottom: '10px', fontSize: '14px' }}>{error}</p>} {/* Display error message if no day is selected */}
                                                <div className='mt-5 mb-3'>
                                                    <button
                                                        onClick={handleAddAvailabilityClick} // Validate and navigate on click
                                                        className="w-full block py-3 px-5 text-center shadow-0 bg-white rounded-lg border border-[#828DEE] text-[#828DEE] font-semibold hover:bg-[#828DEE] transition-all hover:text-white"
                                                    >
                                                        + Add Availability
                                                    </button>
                                                </div>

                                                {/* <div className='mt-5 mb-3'>
                                                    <Link to="/add-date-specific-hours" className="w-full block py-3 px-5 text-center shadow-0 bg-white rounded-lg border border-[#828DEE] text-[#828DEE] font-semibold hover:bg-[#828DEE] transition-all hover:text-white">
                                                        Add Date Specific Hours
                                                    </Link>
                                                </div> */}

                                                <div className='mt-5 mb-3'>
                                                    <Link to="/view-calander" className="w-full block py-3 px-5 text-center shadow-0 bg-white rounded-lg border border-[#828DEE] text-[#828DEE] font-semibold hover:bg-[#828DEE] transition-all hover:text-white">
                                                        Calendario Viste
                                                    </Link>
                                                </div>

                                                {/* <div className='mt-5'>
                                                    {availabilityList.map((availability, index) => (
                                                        <div key={index} className='mb-2 text-sm bg-white p-3 rounded-lg border border-gray-300'>
                                                            <p className='mb-1'>
                                                                <span className='font-semibold min-w-24 inline-block'>Days:</span>
                                                                {availability.day} 
                                                            </p>

                                                            {availability.availabilitySets.map((set, setIndex) => (
                                                                <div key={setIndex} className='mb-3'>
                                                                    <p className='mb-1'>
                                                                        <span className='font-semibold min-w-24 inline-block'>Time Slot:</span> {set.timeSlot}
                                                                    </p>

                                                                    <p className='mb-1'>
                                                                        <span className='font-semibold min-w-24 inline-block'>Time:</span> {set.times}
                                                                    </p>

                                                                    <p className='mb-1'>
                                                                        <span className='font-semibold min-w-24 inline-block'>Mode:</span>
                                                                        {set.ssn.checked && set.ssn.type ? `SSN - ${set.ssn.type}` : ''}
                                                                        {set.private.checked ? `, Private` : ''}
                                                                    </p>

                                                                    <p className='mb-1'>
                                                                        <span className='font-semibold min-w-24 inline-block'>Address:</span> {set.address || 'No Address'}
                                                                    </p>
                                                                </div>
                                                            ))}

                                                            <div className='flex gap-2 mt-2'>
                                                                <button className='px-2 py-1 min-w-16 border border-[#828DEE] text-[#828DEE] rounded text-xs'>
                                                                    <i className="fa-regular fa-pen-to-square"></i> Edit
                                                                </button>
                                                                <button className='px-2 py-1 min-w-16 bg-red-200 rounded text-red-600 text-xs'>
                                                                    <i className="fa-regular fa-trash-can"></i> Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div> */}



                                                {/* <div className='mt-5'>
                                                    <div className='mb-2 text-sm bg-white p-3 rounded-lg border border-gray-300'>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Time Slot:</span> 30 min</p>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Time:</span> 10:00 AM - 11:00 AM</p>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Mode:</span> SSN - in the Clinic</p>
                                                        <p><span className='font-semibold min-w-24 inline-block'>Address:</span> Health First Diagonostics</p>
                                                        <p className='mb-2'><span className='font-semibold min-w-24 inline-block'>Days:</span></p>
                                                        <span className='inline-block px-2 py-1 font-semibold rounded text-[#828DEE] shadow relative mb-2 mr-2 border'>Monday
                                                            <button className='text-red-500 absolute right-[-5px] top-[-5px]'><i class="fa-solid fa-circle-xmark"></i></button>
                                                        </span>
                                                        <span className='inline-block px-2 py-1 font-semibold rounded text-[#828DEE] shadow relative mb-2 mr-2 border'>Tuesday
                                                            <button className='text-red-500 absolute right-[-5px] top-[-5px]'><i class="fa-solid fa-circle-xmark"></i></button>
                                                        </span>
                                                        <span className='inline-block px-2 py-1 font-semibold rounded text-[#828DEE] shadow relative mb-2 mr-2 border'>Wednessday
                                                            <button className='text-red-500 absolute right-[-5px] top-[-5px]'><i class="fa-solid fa-circle-xmark"></i></button>
                                                        </span>
                                                        <div className='flex gap-2 mt-2'>
                                                            <button className='px-2 py-1 min-w-16 border border-[#828DEE] text-[#828DEE] rounded text-xs'><i class="fa-regular fa-pen-to-square"></i> Edit</button>
                                                            <button className='px-2 py-1 min-w-16 bg-red-200 rounded text-red-600 text-xs'><i class="fa-regular fa-trash-can"></i> Delete</button>
                                                        </div>
                                                    </div>
                                                    <div className='mb-2 text-sm bg-white p-3 rounded-lg border border-gray-300'>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Time Slot:</span> 30 min</p>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Date:</span> 01/10/2024</p>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Time:</span> 10:00 AM - 11:00 AM</p>
                                                        <p className='mb-1'><span className='font-semibold min-w-24 inline-block'>Mode:</span> SSN - in the Clinic</p>
                                                        <p><span className='font-semibold min-w-24 inline-block'>Address:</span> Health First Diagonostics</p>
                                                        <div className='flex gap-2 mt-2'>
                                                            <button className='px-2 py-1 min-w-16 border border-[#828DEE] text-[#828DEE] rounded text-xs'><i class="fa-regular fa-pen-to-square"></i> Edit</button>
                                                            <button className='px-2 py-1 min-w-16 bg-red-200 rounded text-red-600 text-xs'><i class="fa-regular fa-trash-can"></i> Delete</button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
