import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import Calander2 from "react-calendar";
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; // Constants for endpoints



export default function SettingVisit() {
  const idPediatrician = localStorage.getItem('userId');
  const [loading, setLoading] = useState(false);
  const [pgloading, setPgLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list
  const [isSSNBookedAllowed, setIsSSNBookedAllowed] = useState(null);
  const [cancelBeforeHr, setCancelBeforeHr] = useState(null);

  const [value, setValue] = useState(new Date());
  const handleDateChange = (date) => {
    setValue(date);
    console.log("Selected date:", date);
  };

  useEffect(() => {
    const fetchPediatricianStatus = async () => {
      setPgLoading(true); 

      try {
        const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
        const data = response.data.data;

        setPediatricianDetail(data);

        // Pre-select options based on the fetched data
        if (data.isSSNBookedAllowed) {
          // Set isSSNBookedAllowed to "Yes" or "No" based on the value received
          setIsSSNBookedAllowed(data.isSSNBookedAllowed === "Yes" ? "Yes" : "No");
        }
        if (data.cancelBeforeHr) {
          setCancelBeforeHr(data.cancelBeforeHr);
        }

        // Redirect or set loading based on availability status
        if (data.isAvailabilityAdded !== undefined) {
          data.isAvailabilityAdded === false ? navigate('/calendar-visit') : setPgLoading(false);
        } else {
          setPgLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pediatrician data:", error);
        setPgLoading(false);
      }
    };

    fetchPediatricianStatus();
  }, [idPediatrician, navigate]);

  console.log("pediatricianDetail ", pediatricianDetail);

  // Validation and Submission function
  const handleSubmit = async () => {
    if (isSSNBookedAllowed === null || cancelBeforeHr === null) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Per favore seleziona tutte le opzioni richieste.',
      });

      return;
    }

    const payload = {
      idPediatrician,
      isSSNBookedAllowed,
      cancelBeforeHr,
    };

    try {
      const response = await axios.put(
        `${Constants.pediatricianSetting}`,
        payload
      );
      Swal.fire({
        icon: 'success',
        title: 'Salvato!',
        text: 'Le impostazioni sono state aggiornate con successo.',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Si è verificato un errore durante il salvataggio. Per favore riprova.',
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while fetching the data
  }

  if (pgloading) {
    return <div>Loading...</div>; // Show a pgloading message while fetching the data
  }


  return (
    <>
      <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
          <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div>
              <div className='flex gap-3 mb-14 font-semibold'>
                <Link to="/calendar-visit"><i className="fa-solid fa-angle-left mr-2"></i> Calendario viste  </Link>
                <span>/</span>
                <span>Imposta Calendario Viste</span>
              </div>

              <Grid container spacing={3}>
                <Grid item lg={4} md={12} xs={12}>
                  <div className='calendarBox pb-7 border-b border-gray-300'>
                    <Calander2
                      onChange={handleDateChange}
                      value={value}
                      showNeighboringMonth={false}
                      tileClassName={({ date, view }) => {

                        if (date.getDate() === 5 || date.getDate() === 12) {
                          return 'orange';
                        }
                        if (date.getDate() === 23) {
                          return 'green';
                        }
                        if (date.getDate() === 15) {
                          return 'purple';
                        }
                        return '';
                      }}
                    />
                  </div>
                  <div className='pt-7'>
                    <h3 className='text-lg font-semibold mb-5'><i class="fa-regular fa-gear mr-2"></i> Impostazioni</h3>
                    <Link to="/agenda-calander" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-light fa-calendar-days"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Gestisci calendario orari</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/setting-visit" className='block w-full items-center mb-2 relative py-2 px-4 bg-[#828DEE]'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-white'>
                          <i class="fa-sharp fa-regular fa-gear"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-white'>Impostazioni visita</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/services-list-modify" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-solid fa-list-ul"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Lista prestazioni</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={8} md={12} xs={12} className='lg:text-right'>
                  <Grid container spacing={3} className='justify-center'>
                    <Grid item lg={5} md={12} xs={12}>
                      <h2 className='text-center mb-8 text-2xl font-semibold'>Impostazioni vista</h2>
                      <h3 className='text-center text-xl font-semibold mb-2'>Vuoi far prenotare ai tuoi pazienti anche le visite con il SSN?</h3>
                      <p className='text-center text-sm mb-5'>Potrai modificare questa scelta anche successivamente</p>
                      <div className='mb-14 flex gap-5 justify-center'>
                        <div className='blockRadio'>
                          <input
                            type='radio'
                            name="isSSNBookedAllowed"
                            id='allowyes'
                            checked={isSSNBookedAllowed === "Yes"}
                            onChange={() => setIsSSNBookedAllowed("Yes")}
                          />
                          <label htmlFor='allowyes' className='border rounded-lg min-w-24 inline-block text-center py-3 px-4 border-gray-300 font-semibold'>SI</label>
                        </div>
                        <div className='blockRadio'>
                          <input
                            type='radio'
                            name="isSSNBookedAllowed"
                            id='allowno'
                            checked={isSSNBookedAllowed === "No"}
                            onChange={() => setIsSSNBookedAllowed("No")}
                          />
                          <label htmlFor='allowno' className='border rounded-lg min-w-24 inline-block text-center py-3 px-4 border-gray-300 font-semibold'>NO</label>
                        </div>
                      </div>

                      <h3 className='text-center text-xl font-semibold mb-2'>Entro quanto i pazienti possono disdire la visita?</h3>
                      <p className='text-center text-sm mb-5'>Potrai modificare questa scelta anche successivamente</p>
                      <div className='mb-14 flex gap-2 justify-center'>
                        {['0h', '12h', '24h', '48h'].map((hr) => (
                          <div className='blockRadio' key={hr}>
                            <input
                              type='radio'
                              name="cancelBeforeHr"
                              id={`hr${hr}`}
                              checked={cancelBeforeHr === hr}
                              onChange={() => setCancelBeforeHr(hr)}
                            />
                            <label htmlFor={`hr${hr}`} className='border rounded-lg inline-block text-center py-3 px-6 border-gray-300 font-semibold'>{hr}</label>
                          </div>
                        ))}
                      </div>

                      <div className='text-center'>
                        <button
                          onClick={handleSubmit}
                          className="min-w-52 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                        >
                          Salva
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

      </div>
    </>
  )
}
