import React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';


export default function PracticeManagement() {

  return (
    <>
    <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
            <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <div>
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                        <span>/</span>
                        <span>Gestione studio medico</span>
                    </div>
                    <Grid container spacing={3} className='justify-center'>
                        <Grid item lg={9} md={12} xs={12}>

                            <div>
                                <h2 className='text-5xl font-bold mt-8 mb-10 text-center'><i class="fa-regular fa-briefcase-blank"></i></h2>
                                <Link to="/studio" class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                                    <div className='flex gap-5 items-center'>
                                        <div className='min-w-5 text-center'>
                                            <i class="fa-regular fa-home"></i>
                                        </div>
                                        Gestione indirizzi studio
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </Link>
                                <Link to="/stamp-and-signature" class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                                    <div className='flex gap-5 items-center'>
                                        <div className='min-w-5 text-center'>
                                            <img src='assets/images/signature.png'  className='w-5'/>
                                        </div>
                                        Timbro e Firma
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </Link>
                                <Link to="/sts" class="flex gap-3 items-center justify-between bg-white mb-[2px] py-4 px-6 text-lg hover:bg-[#aab5ff] transition-all">
                                    <div className='flex gap-5 items-center'>
                                        <div className='min-w-5 text-center'>
                                          <img src='assets/images/sts.png'  className='w-5'/>
                                        </div>
                                        Sistema Tessera Sanitaria
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </div>
    </>
  )
}