import React, { useState, useEffect } from 'react';
import { FormControl, Grid, InputAdornment, OutlinedInput, InputLabel, TextField } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; // Constants for endpoints
import Calander2 from "react-calendar";


export default function ServicesListmodify() {
    const idPediatrician = localStorage.getItem('userId');
    const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list
    const [isVisible, setIsVisible] = useState(false);
    const [serviceName, setServiceName] = useState(''); // State for new service name
    const [serviceCost, setServiceCost] = useState(''); // State for new service cost
    const [services, setServices] = useState([]); // State to hold services list
    const [loading, setLoading] = useState(false);
    const [pgloading, setPgLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [value, setValue] = useState(new Date());
    const handleDateChange = (date) => {
        setValue(date);
        console.log("Selected date:", date);
    };

    // Call API to get all visit details without pagination
    const fetchVisitDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.servicesList}?page=1&limit=100`);
            const { status, list } = response.data;

            if (status === 1) {
                // const formattedList = list.map((service) => ({
                //     ...service,
                //     cost: formatCost(service.cost)
                // }));
                setServices(list); // Set the formatted services list
            } else {
                setError('Failed to fetch visit details.');
            }
        } catch (error) {
            setError('Error fetching visit details');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch services data
    const fetchServices = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Constants.pediatricianServices}/${idPediatrician}`);
            const { status, list } = response.data;

            if (status === 1) {
                // Check if pediatricianServices list is empty
                if (list.length > 0) {
                    setServices(list); // If there are pediatrician services, set them
                } else {
                    await fetchVisitDetails(); // Call admin servicesList API if no pediatrician services
                }
            } else {
                setError('Failed to fetch services details.');
            }
        } catch (error) {
            setError('Error fetching services details');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchPediatricianStatus = async () => {
            setPgLoading(true); // Optional: Set loading state while fetching
            fetchServices();

            try {
                const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);

                setPediatricianDetail(response.data.data); // Store the availability list

                // Access the correct path to `isAvailabilityAdded`
                if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
                    if (response.data.data.isAvailabilityAdded == false) {
                        // If `isAvailabilityAdded` is true, redirect to the `agenda-calander` page
                        navigate('/calendar-visit');
                    } else {
                        // If `isAvailabilityAdded` is false, stop loading and stay on the page
                        setPgLoading(false);
                    }
                } else {
                    setPgLoading(false);
                }
            } catch (error) {
                console.error("Error fetching pediatrician data:", error);
                setPgLoading(false);
            }
        };

        fetchPediatricianStatus();
    }, []);

    console.log("services ", services);

    const handleCostChange = (id, value) => {
        setServices((prevServices) =>
            prevServices.map((service) =>
                service.id === id ? { ...service, cost: value } : service
            )
        );
    };

    const formatCost = (cost) => {
        const [euros, cents] = cost.split('.');
        const formattedEuros = euros.padStart(2, '0');
        const formattedCents = (cents || '00').padEnd(2, '0');
        return `${formattedEuros}:${formattedCents}`;
    };

    const handleAddServiceToggle = () => {
        setIsVisible(!isVisible);
        setServiceName(''); // Clear the input on toggle
        setServiceCost('');
    };

    const handleSaveService = async () => {
        if (!serviceName || !serviceCost) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Please fill in both fields.',
            });
            return;
        }

        // Check if the service cost is 0 or 0.00
        // if (parseFloat(serviceCost) <= 0) {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Validation Error',
        //         text: 'Service cost must be greater than 0.',
        //     });
        //     return;
        // }

        try {
            const response = await axios.post(`${Constants.servicesAdd}`, {
                idPediatrician,
                languageId: 1,
                serviceName: serviceName,
                cost: serviceCost,
            });
            if (response.data.status === 1) {
                Swal.fire({
                    icon: 'success',
                    title: 'Service Added',
                    text: 'Your service has been added successfully!',
                });
                // Fetch updated services list after adding new service
                fetchServices();
                handleAddServiceToggle(); // Hide form after saving
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while saving the service.',
            });
        }
    };

    // Function to handle save button click
    const handleSave = async () => {
        // Check if all service costs are valid
        const allCostsValid = services.every(service => service.cost > 0);

        // if (!allCostsValid) {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Validation Error',
        //         text: 'All service costs must be greater than zero.',
        //     });
        //     return;
        // }


        // Prepare the payload
        const payload = {
            idPediatrician,
            languageId: 1,
            services: services.map(service => ({
                idService: service.id,
                serviceName: service.serviceName,
                cost: service.cost,
            })),
        };

        try {
            const response = await axios.post(`${Constants.pediatricianBulkServices}`, payload);
            if (response.data.status === 1) {
                Swal.fire({
                    icon: 'success',
                    title: 'Services Updated',
                    text: 'The service costs have been updated successfully!',
                });
                fetchServices(); // Fetch updated services after saving
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while saving the services.',
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (pgloading) {
        return <div>Loading...</div>; // Show a pgloading message while fetching the data
    }


    return (
        <>
            <div className='2xl:container mx-auto'>
                <Grid container columns={12} spacing={2}>
                    <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                        <div>
                            <div className='flex gap-3 mb-14 font-semibold'>
                                <Link to="/calendar-visit"><i className="fa-solid fa-angle-left mr-2"></i> Calendario viste  </Link>
                                <span>/</span>
                                <span>Imposta Calendario Viste</span>
                            </div>

                            <Grid container spacing={3}>
                                <Grid item lg={4} md={12} xs={12}>
                                    <div className='calendarBox pb-7 border-b border-gray-300'>
                                        <Calander2
                                            onChange={handleDateChange}
                                            value={value}
                                            showNeighboringMonth={false}
                                            tileClassName={({ date, view }) => {

                                                if (date.getDate() === 5 || date.getDate() === 12) {
                                                    return 'orange';
                                                }
                                                if (date.getDate() === 23) {
                                                    return 'green';
                                                }
                                                if (date.getDate() === 15) {
                                                    return 'purple';
                                                }
                                                return '';
                                            }}
                                        />
                                    </div>
                                    <div className='pt-7'>
                                        <h3 className='text-lg font-semibold mb-5'><i class="fa-regular fa-gear mr-2"></i> Impostazioni</h3>
                                        <Link to="/agenda-calander" className='block w-full items-center mb-2 relative py-2 px-4'>
                                            <div className='flex gap-5 items-center'>
                                                <div className='text-4xl text-[#828DEE]'>
                                                    <i class="fa-light fa-calendar-days"></i>
                                                </div>
                                                <div>
                                                    <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Gestisci calendario orari</h2>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/setting-visit" className='block w-full items-center mb-2 relative py-2 px-4'>
                                            <div className='flex gap-5 items-center'>
                                                <div className='text-4xl text-[#828DEE]'>
                                                    <i class="fa-sharp fa-regular fa-gear"></i>
                                                </div>
                                                <div>
                                                    <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Impostazioni visita</h2>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/services-list-modify" className='block w-full items-center mb-2 relative py-2 px-4 bg-[#828DEE]'>
                                            <div className='flex gap-5 items-center'>
                                                <div className='text-4xl text-white'>
                                                    <i class="fa-solid fa-list-ul"></i>
                                                </div>
                                                <div>
                                                    <h2 className=' text-lg font-semibold mb-1 text-white'>Lista prestazioni</h2>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Grid>
                                <Grid item lg={8} md={12} xs={12} className='lg:text-right'>
                                    <Grid container spacing={3} className='justify-center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <h2 class="mb-8 text-2xl font-semibold text-center">Lista prestazioni </h2>

                                            <div>
                                                {services.map((service) => (
                                                    <div className='flex justify-between gap-3 items-center py-2 mb-2' key={service.id}>
                                                        <div>
                                                            <h3 className='font-semibold'>{service.serviceName}</h3>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            <i className='fa-regular fa-euro-sign'></i>
                                                            <div>
                                                                <input
                                                                    type='text'
                                                                    placeholder='00.00'
                                                                    style={{
                                                                        width: '5rem',
                                                                        color: service.cost === "0.00" ? 'red' : 'inherit',  // Conditional color change
                                                                    }}
                                                                    className='bg-transparent px-2 border-b w-16 outline-none border-gray-400'
                                                                    value={service.cost}
                                                                    onChange={(e) => handleCostChange(service.id, e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>


                                            {isVisible && (
                                                <div className='p-3 shadow-lg rounded-xl'>
                                                    <div className='flex gap-3 mb-6'>
                                                        <div className='grow'>
                                                            <TextField
                                                                id="servicename"
                                                                label="Nome prestazione"
                                                                className='w-full'
                                                                value={serviceName}
                                                                onChange={(e) => setServiceName(e.target.value)} // Set service name
                                                            />
                                                        </div>
                                                        <div className='w-28 max-w-28'>
                                                            <FormControl>
                                                                <OutlinedInput
                                                                    id="price"
                                                                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                                    placeholder='00.00'
                                                                    value={serviceCost}
                                                                    onChange={(e) => setServiceCost(e.target.value)} // Set service cost
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-12 justify-center mb-2'>
                                                        <button
                                                            className='text-gray-500 underline'
                                                            onClick={handleAddServiceToggle} // Hide the form
                                                        >
                                                            Annulla
                                                        </button>
                                                        <button
                                                            className='text-[#828DEE] underline'
                                                            onClick={handleSaveService} // Save the service
                                                        >
                                                            Salva
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='text-center mt-6'>
                                                <button
                                                    onClick={handleAddServiceToggle}
                                                    className='font-semibold text-gray-500 rounded-lg border border-gray-400 border-dashed w-full py-3'
                                                >
                                                    + Aggiungi prestazione
                                                </button>
                                            </div>
                                            {/* <div className='mt-14 text-center'>
                                                <button onClick={handleSave} className='w-10/12 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Salva</button>
                                            </div> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
