// studioSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedStudios: [], // Initial state
    selectedDays: [],    // Initial state for days (Mon, Tue, Sun, etc.)
};

const studioSlice = createSlice({
    name: 'studio',
    initialState,
    reducers: {
        setSelectedStudios: (state, action) => {
            state.selectedStudios = action.payload;
        },
        resetSelectedStudios: (state) => {
            state.selectedStudios = []; // Reset state to an empty array
        },
        setSelectedDays: (state, action) => {
            state.selectedDays = action.payload; // Set selected days
        },
        resetSelectedDays: (state) => {
            state.selectedDays = []; // Reset days
        },
        removeDay: (state, action) => {
            state.selectedDays = state.selectedDays.filter(day => day !== action.payload); // Remove day
        },
    },
});

// Export the actions
export const { setSelectedStudios, resetSelectedStudios, setSelectedDays, resetSelectedDays, removeDay } = studioSlice.actions;

// Export the reducer
export default studioSlice.reducer;

