// MinimalLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function MinimalLayout() {
  return (
    <div className="minimal-layout">
      {/* Only render the content without header or footer */}
      <Outlet />
    </div>
  );
}
