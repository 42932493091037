import React, {useState} from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, Switch , Radio, RadioGroup, TextField } from '@mui/material'
import {Link} from 'react-router-dom'
import DatePicker from 'react-multi-date-picker';
import DashboardNav from '../Dashboard/DashboardNav';

export default function DateSpecificHours() {
    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };
    const [isChecked, setIsChecked] = useState(false); 
    const [specificDates, setSpecificDates] = useState([]);

  return (
    <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
            <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <div className='md:p-8 p-5 h-full'>
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/calendar-visit"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link> 
                        <span>/</span>
                        <Link to="/studio">Imposta Calendario Viste</Link>
                        <span>/</span>
                        <Link to="/choose-availability">Set Weekly Availability</Link>
                    </div>
                    <div className='mt-10'>
                        <Grid container spacing={3} className='justify-center'>
                            <Grid item lg={12} md={12} xs={12}>
                                <Grid container spacing={4} className='justify-center'>
                                    <Grid item lg={5} md={6} sm={12} xs={12}> 
                                    
                                        <h2 className='text-center mb-8 text-xl font-semibold mb-8'>Add Date Specific Hours</h2>
                                        <div className='mb-5 multidatepicker'>
                                            <label>Select Date</label>
                                            <DatePicker
                                                multiple
                                                value={specificDates}
                                                onChange={setSpecificDates}
                                                format="DD/MM/YYYY"
                                                placeholder='Select Date'
                                            />
                                        </div>
                                        <div className='mb-3'>
                                            <FormControl fullWidth>
                                                <InputLabel id="selectVisit-label">Select Visit Time Slot</InputLabel>
                                                <Select
                                                label="Select Visit Time Slot"
                                                labelId="selectVisit-label"
                                                id="selectVisit"
                                                >
                                                    <MenuItem value="min10">10 Min</MenuItem>
                                                    <MenuItem value="min15">15 Min</MenuItem>
                                                    <MenuItem value="min30">30 Min</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='mb-3 shadow-lg p-3 rounded'>
                                            <label className='flex justify-between font-semibold items-center'>SSN
                                                <Switch checked={isChecked} onChange={handleSwitchChange}/>
                                            </label>
                                            
                                            {isChecked && (
                                            <div>
                                                <RadioGroup className='gap-5' row name="ssn">
                                                    <FormControlLabel value="clinic" control={<Radio/>} label="In the Clinic" />
                                                    <FormControlLabel value="remote" control={<Radio/>} label="Remotely" />
                                                </RadioGroup>
                                            </div>
                                            )}
                                            
                                        </div>
                                        <div className='mb-3 shadow-lg p-3 rounded'>
                                            <label className='flex justify-between font-semibold items-center'>Private
                                                <Switch />
                                            </label>
                                        </div>
                                        <div className='mb-3 shadow-lg p-3 rounded'>
                                            <div className='flex gap-3 justify-center items-center mb-5'>
                                                <div className='w-full'>
                                                    <TextField label="From" type='time' variant="outlined"  className='w-full ps-20'/>
                                                </div>
                                                <div className='w-full'>
                                                    <TextField label="To" type='time' variant="outlined"  className='w-full ps-20'/>
                                                </div>
                                                <div>
                                                    <button><i class="fa-solid fa-circle-xmark text-red-500"></i></button>
                                                </div>
                                            </div>
                                            <div>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Studio Address</InputLabel>
                                                    <Select
                                                    label="Select Address"
                                                    labelId="selectAddress-label"
                                                    id="selectAddress"
                                                    >
                                                        <MenuItem value="studio1">Health First Diagonastic</MenuItem>
                                                        <MenuItem value="studio2">Precision Medical Imaging</MenuItem>
                                                        <MenuItem value="studio3">Vitality Wellness Center</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='mb-3 shadow-lg p-3 rounded'>
                                            <div className='flex gap-3 justify-center items-center mb-5'>
                                                <div className='w-full'>
                                                    <TextField label="From" type='time' variant="outlined"  className='w-full ps-20'/>
                                                </div>
                                                <div className='w-full'>
                                                    <TextField label="To" type='time' variant="outlined"  className='w-full ps-20'/>
                                                </div>
                                                <div>
                                                    <button><i class="fa-sharp fa-solid fa-circle-plus text-green-500"></i></button>
                                                    <button><i class="fa-solid fa-circle-xmark text-red-500"></i></button>
                                                </div>
                                            </div>
                                            <div>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Studio Address</InputLabel>
                                                    <Select
                                                    label="Select Address"
                                                    labelId="selectAddress-label"
                                                    id="selectAddress"
                                                    >
                                                        <MenuItem value="studio1">Health First Diagonastic</MenuItem>
                                                        <MenuItem value="studio2">Precision Medical Imaging</MenuItem>
                                                        <MenuItem value="studio3">Vitality Wellness Center</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div>
                                            <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Add</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
  )
}
