import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, FormControl, InputLabel, Select, Checkbox, MenuItem, Switch, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; // Constants for endpoints
import './CalendarStyles.css';


const localizer = momentLocalizer(moment);

// Helper function to map a day name to the corresponding date in the current week
const getDateFromDayName = (dayName) => {
  const daysOfWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };

  const today = moment(); // Current date
  const dayIndex = daysOfWeek[dayName]; // Get index of the day from the name
  return today.startOf('week').add(dayIndex, 'days').toDate(); // Return the date corresponding to the day in the current week
};

export default function ViewCalendar() {
  const idPediatrician = localStorage.getItem('userId');
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Store selected event
  const [selectedSlot, setSelectedSlot] = useState(null); // Store selected time slot (start and end time)
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [availabilityList, setAvailabilityList] = useState([]); // Dynamic availability list
  const [loading, setLoading] = useState(false);
  const [pgloading, setPgLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false); // Loader state for the save button
  const [formError, setFormError] = useState(''); // State to store form validation errors

  // Fetch availability data
  const fetchAvailability = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.allAvailability}/${idPediatrician}`);
      const { status, list } = response.data;
      if (status === 1) {
        setAvailabilityList(list); // Store the availability list
      } else {
        setError('Failed to fetch availability details.');
      }
    } catch (error) {
      setError('Error fetching availability details');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch studio addresses
  const getStudios = async () => {
    try {
      const response = await fetch(`${Constants.pediatricianAddressList}${idPediatrician}`);
      if (response.ok) {
        const data = await response.json(); // Directly parse JSON
        if (data.status === 1) {
          setAddresses(Array.isArray(data.data) ? data.data : []); // Set fetched addresses
        } else {
          console.error('Failed to fetch addresses:', data.message);
        }
      } else {
        console.error('Failed to fetch addresses');
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  // Initialize local state from API on component mount
  useEffect(() => {
    fetchAvailability();
    getStudios();

    const fetchPediatricianStatus = async () => {
      setPgLoading(true);
      try {
        const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
  
        // Access the correct path to `isAvailabilityAdded`
        if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
          if (response.data.data.isAvailabilityAdded==true) {
            // If `isAvailabilityAdded` is true, redirect to the `agenda-calander` page
            navigate('/agenda-calander');
          } else {
            // If `isAvailabilityAdded` is false, stop loading and stay on the page
            setPgLoading(false);
          }
        } else {
          setPgLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pediatrician data:", error);
        setPgLoading(false);
      }
    };
  
    fetchPediatricianStatus();

  }, [idPediatrician, navigate]);

  // Convert API data to calendar events
  const mapAvailabilityToEvents = () => {
    return availabilityList.map((slot) => {
      // Get the correct date for the day (e.g., "Monday")
      const dayDate = getDateFromDayName(slot.weekName);

      // Create start and end Date objects by combining the date and the provided time slot
      const startDate = new Date(dayDate);
      startDate.setHours(slot.slotStart.split(':')[0], slot.slotStart.split(':')[1]);

      const endDate = new Date(dayDate);
      endDate.setHours(slot.slotEnd.split(':')[0], slot.slotEnd.split(':')[1]);

      // Check for the presence of `studioName`, fallback to empty string if not present
      const studioName = slot.studioName ? `${slot.studioName}` : '';

       // New condition 1: isSSN true, isPrivate true, rest of all true
       let eventColor;
       if (slot.isSSN && slot.isPrivate && slot.ssnClinic && slot.ssnRemote && slot.privateClinic && slot.privateRemote) {
         eventColor = '#FFC885'; // Yellow
       } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && slot.isPrivate && slot.privateClinic && !slot.privateRemote) {
         eventColor = '#FFC885'; // Yellow
       } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote) {
         eventColor = '#FFC885'; // Yellow
       } else if (slot.isSSN && slot.ssnClinic && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnRemote) {
         eventColor = '#FFC885'; // Yellow for SSN in Clinic
       } else if (slot.isSSN && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnClinic) {
         eventColor = '#FFA232'; // Orange for SSN remotely
       } else if (slot.isPrivate && slot.privateClinic && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateRemote) {
         eventColor = '#828DEE'; // Violet for Private in Clinic
       } else if (slot.isPrivate && slot.privateClinic && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote) {
         eventColor = '#828DEE'; // Violet for Private in Clinic
       } else if (slot.isPrivate && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateClinic) {
         eventColor = '#5EAF88'; // Green for Private remotely
       } else {
         eventColor = '#FFC885'; // Default color if no other conditions are matched
       }

       let eventTitle = 'Visit '; // Start with "Visit" only once

        // Append SSN details if isSSN is true
        if (slot.isSSN) {
          eventTitle += 'SSN ';
          if (slot.ssnClinic && slot.ssnRemote) {
            eventTitle += 'in studio e da remoto ';
          } else if (slot.ssnClinic) {
            eventTitle += 'in studio ';
          } else if (slot.ssnRemote) {
            eventTitle += 'da remoto ';
          }
        }

        // Append Private details if isPrivate is true
        if (slot.isPrivate) {
          eventTitle += 'Privato ';
          if (slot.privateClinic && slot.privateRemote) {
            eventTitle += 'in studio e da remoto ';
          } else if (slot.privateClinic) {
            eventTitle += 'in studio ';
          } else if (slot.privateRemote) {
            eventTitle += 'da remoto ';
          }
        }

        eventTitle = eventTitle.trim(); // Remove any trailing spaces


      // Return the event object with all necessary fields
      return {
        id: slot.id,
        idPediatrician: slot.idPediatrician,
        idStudio: slot.idStudio,
        availabilityType: slot.availabilityType,
        weekName: slot.weekName,
        slotInterval: slot.slotInterval,
        slotStart: slot.slotStart,
        slotEnd: slot.slotEnd,
        languageId: slot.languageId,
        studioName: slot.studioName,
        title: eventTitle,
        start: startDate,
        end: endDate,
        color: eventColor, // Set the color based on the priority
        isSSN: slot.isSSN,  // Include SSN field
        ssnClinic: slot.ssnClinic,  // Include SSN Clinic field
        ssnRemote: slot.ssnRemote,  // Include SSN Remote field
        isPrivate: slot.isPrivate,  // Include Private field
        privateClinic: slot.privateClinic,  // Include Private Clinic field
        privateRemote: slot.privateRemote,  // Include Private Remote field
        selectedAddress: slot.idStudio,  // Include Studio Address field
        timeSlot: slot.slotInterval,  // Include time slot information
      };
    });
  };

  const events = mapAvailabilityToEvents(); // Dynamic events from API

  // Define eventPropGetter to customize event background color
  const eventPropGetter = (event) => {
    const backgroundColor = event.color; // Set color based on event data
    return { style: { backgroundColor } }; // Return the style object with the color
  };

  const handleSwitchChange = () => setIsChecked(!isChecked);
  const handleSwitchChange2 = () => setIsChecked2(!isChecked2);

  const handleCheckboxChange = (field) => {
    if (selectedEvent) {
      setSelectedEvent((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
      }));
    } else if (selectedSlot) {
      setSelectedSlot((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
      }));
    }
  };


  const handleEventClick = (event) => {
    const day = event.weekName || moment(event.start).format('dddd');  // Fallback to formatting start date if weekName is missing

    setSelectedEvent({
      id: event.id,
      idPediatrician: event.idPediatrician,
      idStudio: event.idStudio,
      availabilityType: event.availabilityType,
      weekName: day,  // Set the day here
      slotInterval: event.slotInterval,
      slotStart: moment(event.start).format('HH:mm'), // Format the start time
      slotEnd: moment(event.end).format('HH:mm'), // Format the end time
      languageId: event.languageId,
      studioName: event.studioName,
      timeSlot: event.timeSlot || 'min10', // Default time slot if not provided
      isSSN: event.isSSN || false,
      ssnClinic: event.ssnClinic || false,
      ssnRemote: event.ssnRemote || false,
      isPrivate: event.isPrivate || false,
      privateClinic: event.privateClinic || false,
      privateRemote: event.privateRemote || false,
      selectedAddress: event.selectedAddress || '', // Handle Studio address
    });

    setOpenModal(true); // Open modal
  };


  // Function to handle slot click (for empty slots)
  const handleSlotSelect = (slotInfo) => {
    const startMoment = moment(slotInfo.start);  // Get the moment object for the start time
    const endMoment = moment(slotInfo.end);  // Get the moment object for the end time
    const day = startMoment.format('dddd');  // Extract the day of the week (e.g., "Monday")

    // Ensure end time is at least 1 hour after the start time
    const minimumEndTime = startMoment.clone().add(1, 'hours');

    setSelectedSlot({
      day,  // Set the day extracted from the start time
      start: startMoment.format('HH:mm'),
      end: endMoment.isAfter(minimumEndTime) ? endMoment.format('HH:mm') : minimumEndTime.format('HH:mm'),
      isSSN: false,  // Initialize default values
      ssnClinic: false,
      ssnRemote: false,
      isPrivate: false,  // Initialize default values
      privateClinic: false,
      privateRemote: false,
      selectedAddress: '', // Initialize address
    });

    setOpenModal(true); // Open modal
  };


  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvent(null); // Clear the selected event
    setSelectedSlot(null); // Clear the selected slot
  };

  // Custom day header component to hide the date
  const DayHeader = ({ label }) => <span>{label.split(' ')[0]}</span>; // Only show the day name

  // Custom Toolbar to remove navigation buttons (Today, Back, Next)
  const CustomToolbar = () => <div />; // Empty toolbar to remove navigation


  const handleSave = async () => {
    // Use selectedEvent or fallback to selectedSlot, ensure it's not null
    const eventData = selectedEvent || selectedSlot;

    if (!eventData) {
      setFormError('No event or time slot selected.');
      return;
    }

    // Extract start, end times, timeSlot, and day from eventData
    const slotStart = eventData?.slotStart || eventData?.start || '';
    const slotEnd = eventData?.slotEnd || eventData?.end || '';
    const timeSlot = eventData?.timeSlot || '';
    const day = eventData?.day || eventData?.weekName || '';  // Get the day from either slot or event 
    const availabilityType = eventData?.availabilityType || 'Weekly';

    // Validate start and end times
    if (!slotStart || !slotEnd) {
      setFormError('Please provide both start and end times.');
      return;
    }

    // Validate timeSlot
    if (!timeSlot) {
      setFormError('Please select a valid visit time slot.');
      return;
    }

    // Validate day
    if (!day) {
      setFormError('Please select a valid day.');
      return;
    }

    const {
      privateClinic = false,
      ssnClinic = false,
      privateRemote = false,
      ssnRemote = false,
      selectedAddress = null
    } = eventData;

    // Validate Studio Address based on conditions:
    if ((privateClinic || ssnClinic) && !selectedAddress) {
      setFormError('Please select a studio address if "In the Clinic" is selected for SSN or Private.');
      return;
    }

    setLoadingSave(true); // Start loader
    setFormError(''); // Clear previous errors

    try {
      let apiUrl = '';
      let requestPayload = {
        idPediatrician,
        day,  // Add the day to the request payload
        languageId: 1,
        availabilityType,
        slotStart,
        slotEnd,
        timeSlot, // Time slot included in the payload
        isSSN: eventData.isSSN || false,
        ssnClinic: eventData.ssnClinic || false,
        ssnRemote: eventData.ssnRemote || false,
        isPrivate: eventData.isPrivate || false,
        privateClinic: eventData.privateClinic || false,
        privateRemote: eventData.privateRemote || false,
        selectedAddress: eventData.selectedAddress || null,
      };

      // If we are editing (there is an ID), call the update endpoint
      if (selectedEvent?.id) {
        apiUrl = `${Constants.updateAvailability}`;
        requestPayload.id = selectedEvent.id; // Add ID for the update
      } else {
        // If we are creating a new slot, use the create API
        apiUrl = `${Constants.updateAvailability}`; // Assuming you have an endpoint for creating new availability
      }

      const response = await axios.post(apiUrl, requestPayload);

      if (response.data.status === 1) {
        // Handle success
        fetchAvailability(); // Refresh the availability list after update
        setOpenModal(false); // Close the modal
      } else {
        setFormError('Failed to save availability. Please try again.');
      }
    } catch (error) {
      setFormError('Error saving availability. Please try again.');
      console.error('Error saving availability:', error);
    } finally {
      setLoadingSave(false); // Stop loader
    }
  };

  // Function to handle "Conferma" button click with SweetAlert
  const handleConfirmClick = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to confirm the availability?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(`${Constants.confirmAvailability}/${idPediatrician}`);

          if (response.data.status === 1) {
            Swal.fire('Confirmed!', 'Availability has been confirmed.', 'success');
            fetchAvailability(); // Reload availability data after confirmation

            // After updating the state, navigate to agenda-calander
            navigate('/agenda-calander');
          } else {
            Swal.fire('Error!', 'Failed to confirm availability.', 'error');
          }
        } catch (error) {
          console.error('Error confirming availability:', error);
          Swal.fire('Error!', 'An error occurred while confirming availability.', 'error');
        }
      }
    });
  };

  // Custom day component to hide time slots
// Custom Event component to render events
const CustomEvent = ({ event }) => {
  return (
      <div style={{ backgroundColor: event.style.backgroundColor, padding: '5px', color: 'white' }}>
          <strong>{event.title}</strong>
          {/* You can add more details here if needed */}
      </div>
  );
};

if (pgloading) {
  return <div>Loading...</div>; // Show a pgloading message while fetching the data
}


  return (
    <>
      <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
          <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div>
              <div className='flex gap-3 mb-6 font-semibold'>
                <Link to="/calendar-visit"><i className="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                <span>/</span>
                <Link to="/choose-availability">Set Weekly Availability</Link>
                <span>/</span>
                <span>Availability Calander</span>
              </div>
              <Grid container spacing={3} className='items-center'>
                <Grid item lg={6} md={12} xs={12}>
                  <p>Imposta il calendario scegliendo i giorni e gli orari dedicati alle visite del Servizio Sanitario Nazionale e quelle private (sia in studio che da remoto).
                    Potrai modificare le singole date o periodi nelle impostazioni del Calendario viste </p>
                </Grid>
                <Grid item lg={6} md={12} xs={12} className='lg:text-right'>
                  <button
                    onClick={handleConfirmClick} // Call the handler when clicked
                    className="h-[54px] px-14 shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                  >
                    Conferma
                  </button>
                </Grid>
              </Grid>
              <div className='mt-10'>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className='cal'>
                    <Calendar
                      localizer={localizer}
                      events={events} // Use dynamic events from API
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 600 }}
                      defaultView="week" // Default view is week
                      views={['week']} // Only show the week view
                      selectable // Enable slot selection
                      eventPropGetter={eventPropGetter} // Custom event color logic
                      onSelectEvent={handleEventClick} // Event click handler
                      onSelectSlot={handleSlotSelect} // Slot select handler for empty time slots
                      formats={{
                        timeGutterFormat: 'HH:mm', // Show times in 24-hour format
                        dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture), // Only show day names 4d means full day name and 3d means Mon, Sun etc
                        eventTimeRangeFormat: ({ start, end }, culture, localizer) => // Show event time range in 24-hour format
                          `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                      }}
                      components={{
                        week: {
                          header: DayHeader, // Custom header for the day name
                          toolbar: CustomToolbar, // Custom toolbar to remove navigation buttons
                        },
                        day: CustomEvent, // Use custom day component
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        {/* Modal to show selected time slot or event details */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-[420px] w-[90%] bg-[#F8FFFC] p-6 rounded-2xl">
            {formError && (
              <div className="mb-4 text-red-500 text-center">
                {formError}
              </div>
            )}

            {(selectedSlot || selectedEvent) && (
              <>
                <div className='flex gap-2 justify-between mb-6'>
                  <div>
                    <button type='button' onClick={handleCloseModal} className='underline text-[#828DEE]'>Annulla</button>
                  </div>
                  <div>
                    <button type='button' onClick={handleSave} className='underline text-[#828DEE]' disabled={loadingSave}>
                      {loadingSave ? 'Saving...' : 'Salva'}
                    </button>
                  </div>
                </div>

                <h2 className='text-center font-semibold text-2xl mb-4'>
                  {selectedEvent ? selectedEvent?.weekName : 'Create Event'}
                </h2>

                {/* Time Slot selection */}
                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>dalle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.start) || (selectedEvent?.slotStart) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, start: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotStart: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>alle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.end) || (selectedEvent?.slotEnd) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, end: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotEnd: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                {/* Visit Time Slot */}
                <div className='mb-3'>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="selectVisit-label">Slot visite da</InputLabel>
                    <Select
                      labelId="selectVisit-label"
                      value={selectedEvent?.timeSlot || selectedSlot?.timeSlot || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, timeSlot: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, timeSlot: e.target.value });
                        }
                      }}
                    >
                      <MenuItem value="min10">10 Min</MenuItem>
                      <MenuItem value="min15">15 Min</MenuItem>
                      <MenuItem value="min30">30 Min</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* SSN Toggle */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    SSN
                    <Switch
                      checked={selectedEvent?.isSSN || selectedSlot?.isSSN || false}
                      onChange={() => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isSSN: !selectedSlot.isSSN });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isSSN: !selectedEvent.isSSN });
                        }
                      }}
                    />
                  </label>
                  {(selectedEvent?.isSSN || selectedSlot?.isSSN) && (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.ssnClinic || selectedSlot?.ssnClinic || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, ssnClinic: !selectedSlot.ssnClinic });
                          } else {
                            setSelectedEvent({ ...selectedEvent, ssnClinic: !selectedEvent.ssnClinic });
                          }
                        }} />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.ssnRemote || selectedSlot?.ssnRemote || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, ssnRemote: !selectedSlot.ssnRemote });
                          } else {
                            setSelectedEvent({ ...selectedEvent, ssnRemote: !selectedEvent.ssnRemote });
                          }
                        }} />}
                        label="Da remoto"
                      />
                    </div>
                  )}
                </div>

                {/* Private Toggle */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    Privato
                    <Switch
                      checked={selectedEvent?.isPrivate || selectedSlot?.isPrivate || false}
                      onChange={() => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isPrivate: !selectedSlot.isPrivate });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isPrivate: !selectedEvent.isPrivate });
                        }
                      }}
                    />
                  </label>
                  {(selectedEvent?.isPrivate || selectedSlot?.isPrivate) && (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.privateClinic || selectedSlot?.privateClinic || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, privateClinic: !selectedSlot.privateClinic });
                          } else {
                            setSelectedEvent({ ...selectedEvent, privateClinic: !selectedEvent.privateClinic });
                          }
                        }} />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.privateRemote || selectedSlot?.privateRemote || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, privateRemote: !selectedSlot.privateRemote });
                          } else {
                            setSelectedEvent({ ...selectedEvent, privateRemote: !selectedEvent.privateRemote });
                          }
                        }} />}
                        label="Da remoto"
                      />
                    </div>
                  )}
                </div>

                {/* Studio Address selection */}
                {(selectedEvent?.ssnClinic || selectedEvent?.privateClinic || selectedSlot?.ssnClinic || selectedSlot?.privateClinic) && (
                  <FormControl fullWidth>
                    <InputLabel id="selectAddress-label">Studio di riferimento</InputLabel>
                    <Select
                      labelId="selectAddress-label"
                      value={selectedEvent?.selectedAddress || selectedSlot?.selectedAddress || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, selectedAddress: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, selectedAddress: e.target.value });
                        }
                      }}
                    >
                      {addresses.length > 0 ? (
                        addresses.map((studio) => (
                          <MenuItem key={studio.id} value={studio.id}>
                            {studio.studio} - {studio.street}, {studio.province}, {studio.zip}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No Studio Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              </>
            )}

          </Box>
        </Modal>
      </div>
    </>
  );
}
