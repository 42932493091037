import React from 'react'
import PediatricianSlider from './PediatricianSlider'

export default function Pediatrician() {
  return (
    <>
    <section className='md:py-28 py-16 bg-amber-50'>
        <div className='container mx-auto px-4 relative'>
            <img src='assets/images/bird.png' className='absolute right-16 top-[-35px] animationupdown md:block hidden'/>
            <h2 className="md:text-5xl sm:text-4xl text-3xl mb-6 font-semibold text-center">Meet Our Pediatrician</h2>
            <div className='w-14 h-1 bg-theme-primary mx-auto'></div>
            <div className='px-3 mt-16'>
                <PediatricianSlider/>
            </div>
            <div className='text-center mt-10'>
                <a href='#' className='font-bold bg-theme-primary p-4 inline-block px-10 rounded-full text-white hover:bg-green-600 transition '>View All Pediatrician</a>
            </div>
        </div>
    </section>
    </>
  )
}
