import { Checkbox, FormControl, Grid, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from '../Dashboard/DashboardNav'


export default function CertificateRequestList() {
    return (
        <>
            <div className='flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs'>
                <Link to="/"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo </Link>
                <span>/</span>
                <Link to="/">Gestione studio medico</Link>
                <span>/</span>
                <span>Certificate Request</span>
            </div>
            <div className='mt-10 text-center'>
                <div className=' mb-8 '>
                    <h2><span className='inline-block border-4 text-4xl w-14 h-14 py-1 border-black rounded-lg'><i class="fa-solid fa-solid fa-bars-sort"></i></span></h2>
                    <p className='mt-8 text-base'>Inserisci la tipologia di certificati.</p>
                </div>
                <div className='overflow-x-auto'>
                    <table className='w-full lg:text-base text-sm tablecustom'>
                        <thead className='bg-[#FFC885]'>
                            <tr>
                                <th className='text-left'>Patient Name</th>
                                <th>Patient ID</th>
                                <th>Parent Name</th>
                                <th>Certificate Type</th>
                                <th>Certificate Title</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-left'>Giovanni Bianchi</td>
                                <td>AH488518</td>
                                <td>Maria Bianchi</td>
                                <td><span className='inline-block bg-[#aab5ff] px-5 rounded-full font-semibold text-white text-sm py-1'>Paid</span></td>
                                <td>Riammissione a scuola</td>
                                <td>
                                    <div className='flex gap-1 justify-end'>
                                        <button className='text-[#86D8B0] border border-[#86D8B0] px-2 rounded-full font-semibold text-sm py-1'><i class="fa-regular fa-eye"></i> View</button>
                                        <button className='text-[#aab5ff] border border-[#aab5ff] px-2 rounded-full font-semibold text-sm py-1'><i class="fa-regular fa-gear"></i> Generate</button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
