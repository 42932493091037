import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { combineReducers } from 'redux';
import studioReducer from './studioSlice'; // Import your reducer
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// Redux Persist configuration
const persistConfig = {
  key: 'root', // Key in localStorage
  storage, // Use localStorage
  whitelist: ['studio'], // Only persist the studio reducer
};

// Combine your reducers (in case you have more than one reducer)
const rootReducer = combineReducers({
  studio: studioReducer, // Add other reducers here if needed
});

// Wrap your root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions to avoid the serializability check warning
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor to manage the store persistence
export const persistor = persistStore(store);

export default store;
