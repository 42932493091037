import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useUser } from '../../hooks/useUserContext';
import axios from 'axios';
import { Constants } from '../../endPoints';


export default function StampSignature() {
  const { user, updateUser } = useUser();


  // State to store files and previews
  const [files, setFiles] = useState({
    stamp: null,
    signature: null,
  });
  const [success, setSuccess] = useState('');

  const [previews, setPreviews] = useState({
    stamp: user.stamp || null,
    signature: user.signature || null,
  });

  // State for loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Handler to manage file changes and previews
  const handleFileChange = (event, name) => {
    const file = event.target.files[0];
    if (file) {
      // Update the files state
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: file,
      }));

      // Create a preview URL
      const previewUrl = URL.createObjectURL(file);
      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [name]: previewUrl,
      }));
    }
  };

// Handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  setError('');
  setSuccess(''); // Optional: For success messages

  const formData = new FormData();
  formData.append('idPediatrician', user.id); // Include user ID in the request body

  // Append files if they exist
  if (files.stamp) {
    formData.append('stamp', files.stamp);
  }
  if (files.signature) {
    formData.append('signature', files.signature);
  }

  // Append dateOfBirth from user context
  formData.append('dateOfBirth', user.dateOfBirth);

  try {
    const response = await axios.post(Constants.profilePediatrician, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Log the entire response for debugging
    console.log('API Response:', response);

    if (response.status === 200 || response.status === 201) {
      // Access the 'pediatrician' object from the response
      const updatedPediatrician = response.data.pediatrician;

      // Update user data with the correct fields, including dateOfBirth
      updateUser({
        ...user,
        stamp: updatedPediatrician.stamp, // Update stamp URL
        signature: updatedPediatrician.signature, // Update signature URL
        dateOfBirth: updatedPediatrician.dateOfBirth, // Update dateOfBirth
      });

      // Optional: Set a success message instead of using alert
      setSuccess('Files uploaded successfully!');
    } else {
      // Handle unexpected status codes
      const errorMessage =
        response.data.message || 'Failed to upload files. Please try again.';
      setError(errorMessage);
    }
  } catch (error) {
    console.error('Error uploading files:', error);

    // Extract a more detailed error message if available
    const errorMessage =
      error.response?.data?.message || 'Failed to upload files. Please try again.';
    setError(errorMessage);
  } finally {
    setLoading(false);
  }
};

  
  

  return (
    <>
      <div >
        <Grid container columns={12} spacing={2}>
          <Grid item md={9} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div >
              <div className='flex gap-3 mb-6 font-semibold'>
                <Link to="/profile">
                  <i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo
                </Link>
                <span>/</span>
                <Link to="/medical-practice-management">Gestione studio medico</Link>
                <span>/</span>
                <span>Timbro e Firma</span>
              </div>
              <Grid container spacing={3} className='justify-center'>
                <Grid item lg={9} md={12} xs={12}>
                  <div>
                    <h2 className='text-5xl font-bold mt-8 mb-10 text-center'>
                      <img src='assets/images/signature.png' className='h-[70px] mx-auto' alt='Signature' />
                    </h2>
                    <p className='text-center mb-16'>
                      Carica la foto del timbro e della firma.<br />
                      La foto deve essere chiara, leggibile e non sgranata per poter essere apposta sui documenti.
                    </p>
                    <Grid container spacing={3} className='justify-center'>
                      <Grid item lg={5} md={8} xs={12}>
                        <form onSubmit={handleSubmit}>
                          {/* Signature Field */}
                          <div className='mb-5'>
                            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
                              <div className='flex gap-3 items-center'>
                                <img src='assets/images/signature.png' className='h-[18px]' alt='Signature Icon' /> Firma
                              </div>
                              <input
                                type='file'
                                className='hidden'
                                onChange={(event) => handleFileChange(event, 'signature')}
                              />
                            </label>
                            {/* Image Preview for Signature */}
                            {previews.signature && (
                              <div className='mt-3'>
                                <img
                                  src={previews.signature}
                                  alt='Signature Preview'
                                  className='w-full rounded-lg'
                                />
                              </div>
                            )}
                          </div>

                          {/* Stamp Field */}
                          <div className='mb-5'>
                            <label className='flex items-center justify-between cursor-pointer py-7 px-6 rounded-lg border border-gray-400'>
                              <div>
                                <i className='fa-regular fa-stamp mr-1'></i> Timbro
                              </div>
                              <input
                                type='file'
                                className='hidden'
                                onChange={(event) => handleFileChange(event, 'stamp')}
                              />
                            </label>
                            {/* Image Preview for Stamp */}
                            {previews.stamp && (
                              <div className='mt-3'>
                                <img
                                  src={previews.stamp}
                                  alt='Stamp Preview'
                                  className='w-full rounded-lg'
                                />
                              </div>
                            )}
                          </div>

                          {success && <div className='text-green-500 mb-4'>{success}</div>}
                          {error && <div className='text-red-500 mb-4'>{error}</div>}
                          {/* Submit Button */}
                          <div className='mb-6 mt-10'>
                            <button
                              className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all disabled:opacity-50'
                              type='submit'
                              disabled={loading}
                            >
                              {loading ? 'Uploading...' : 'Salva'}
                            </button>
                          </div>
                        </form>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item md={3} sm={12} xs={12}>
            <DashboardNav />
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}
