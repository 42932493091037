import { Container } from '@mui/material'
import React from 'react'

export default function AudioCall() {
  return (
    <>
    <div className='w-full h-screen relative bg-[#828DEE]'>
        <div className='table h-full w-full'>
            <div className='align-middle table-cell pb-32'>
                <div className='md:flex gap-5 justify-center'>
                    <div className='mb-4'>
                        <div className='text-center xl:p-36 p-16 xl:pb-8 pb-5 bg-contain bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/callBg.png)` }}>
                            <img className='lg:w-40 lg:h-40 w-24 h-24 min-w-24 object-cover rounded-full border-4 border-[#828dee] mx-auto' src='assets/images/profile-dr2.jpg'/>
                        </div>
                        <h2 className='text-xl font-semibold text-center'>Dott. Giuseppe Morero</h2>
                    </div>
                    <div  className='mb-4'>
                        <div className='text-center xl:p-36 p-16 xl:pb-8 pb-5 bg-contain bg-no-repeat bg-center' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/callBg.png)` }}>
                            <img className='lg:w-40 lg:h-40 w-24 h-24 min-w-24 object-cover rounded-full border-4 border-[#5EAF88] mx-auto' src='assets/images/kids4.png'/>
                        </div>
                        <h2 className='text-xl font-semibold text-center'>Giuseppe Bianchi</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='absolute md:bottom-6 bottom-3 left-0 right-0 mx-auto md:w-[262px] w-[205px] md:p-3 p-2 bg-[#141419b5] rounded-lg flex gap-2 justify-center'>
            <div >
                <button className='lg:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-[#141419] text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-video"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-white text-black md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-volume-high"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] bg-[#141419] text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-microphone-slash"></i></button>
            </div>
            <div>
                <button className='lg:w-[50px] w-[40px] md:h-[50px] bg-red-500 text-white md:leading-[50px] leading-[40px] rounded-full'><i class="fa-regular fa-phone-hangup"></i></button>
            </div>
        </div>
    </div>
    </>
  )
}
