import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function DoctorProfile({ doctor }) {

    useEffect(() => {
        // Fetch doctor profile
    })
  return (
    <div className='flex gap-3 mb-8 px-6'>
      <div>
        <img
          src={doctor?.photo || 'assets/images/default-profile.png'} // Default if no profile picture
          alt={doctor?.firstName}
          className='w-[80px] h-[80px] mx-auto object-cover rounded-full'
        />
      </div>
      <div>
        <h2 className='mt-1 mb-1 text-white font-semibold text-xl mr-1'>
          Ciao, <br />
          {doctor?.firstName || 'Guest'}
        </h2>
        <Link to="/profile" className="text-white text-xs underline">
          Gestisci profilo
        </Link>
      </div>
    </div>
  );
}