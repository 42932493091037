import React from 'react'
import { Grid } from '@mui/material'

export default function AddChildren() {
  return (
    <>
    <h2 className='text-2xl font-semibold mb-10'>Chindren Profile</h2>
    <form>
        <Grid container columns={12} spacing={2}>
            <Grid item lg={6}>
                <label className='mb-2 block'>First Name</label>
                <input type='text' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='First Name'/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Last Name</label>
                <input type='text' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='Last Name'/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Fiscal Code</label>
                <input type='text' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='Fiscal Code'/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Upload Profile Picture</label>
                <input type='file' className='border w-full pt-2 h-12 border-gray-400 rounded-lg px-4 text-base' placeholder=''/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Date of Birth</label>
                <input type='date' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='Date of Birth'/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Place of Birth</label>
                <input type='text' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='Place of Birth'/>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'>Pediatrician</label>
                <input type='text' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base' placeholder='Pediatrician'/>
            </Grid>
            <Grid item lg={12}>
                <h2 className='text-xl font-semibold mb-4'>Upload the Identity Card</h2>
                <p className='text-gray-700'>Take a photo or upload a file containing a front and back photo of your Identity Card. The photo must be clear, legible, and not pixelated to ensure its verifiability.</p>
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'><i class="fa-regular fa-id-card"></i> Front</label>
                <input type='file' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base pt-2'/>   
            </Grid>
            <Grid item lg={6}>
                <label className='mb-2 block'><i class="fa-regular fa-credit-card-blank"></i> Back</label>
                <input type='file' className='border w-full h-12 border-gray-400 rounded-lg px-4 text-base pt-2'/>   
            </Grid>
            <Grid item lg={12} className='text-right'>
                <button className='border h-12 border-green-500 rounded-lg px-8 bg-theme-primary text-white text-base hover:bg-green-600'>Update Children Profile</button>
            </Grid>
        </Grid>
        
    </form>
    </>
  )
}
