import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios'
import { Constants } from '../endPoints';

// Create a context to store user data
const UserContext = createContext();

// Create a provider component to wrap your app and provide user data
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Try to fetch user from localStorage if it exists
    console.log("setting User in global context", localStorage.getItem('user'))
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  // Function to update user data after login and save it to localStorage
  const updateUser = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData)); // Persist user in localStorage
  };

  // Function to log out the user and remove their data from state and localStorage
  const deleteUser = () => {
    setUser(null); // Clear user from state
    localStorage.removeItem('user'); // Remove user from localStorage
    localStorage.removeItem('userId'); // Remove userId from localStorage if it's stored separately
  };

  const fetchUserData = async () => {
    const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
    if (userId && !user) {
      try {
        const response = await axios.get(`${Constants.getPediatricianByID}${userId}`);
        const fetchedUser = response.data.data;

        if (fetchedUser) {
          updateUser(fetchedUser);
          localStorage.setItem('user', JSON.stringify(fetchedUser)); // Save user data to localStorage
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const forceUserDataUpdate = async () => {
    const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
    if (userId) {
      try {
        const response = await axios.get(`${Constants.getPediatricianByID}${userId}`);
        const fetchedUser = response.data.data;

        if (fetchedUser) {
          updateUser(fetchedUser);
          localStorage.setItem('user', JSON.stringify(fetchedUser)); // Save user data to localStorage
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // Fetch user data when the provider mounts
  useEffect(() => {

    if (!user) { // Only fetch if user isn't already set in state
      fetchUserData();
    } else {
      setLoading(false); // If user already exists in state, no need to fetch
    }
  }, [user]);




  return (
    <UserContext.Provider value={{ user, updateUser, deleteUser, forceUserDataUpdate, loading }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);
