import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext
import { Constants } from '../../endPoints';
import { countries as countriesList } from 'countries-list';
import dayjs from 'dayjs'; // Import dayjs for date parsing
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

export default function BankingInfo() {
  const { user, updateUser } = useUser(); // Access the user info from the context
  const [stripSubmitLoading, setStripSubmitLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [formValues, setFormValues] = useState({
    email: '',
    first_name: '',
    last_name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    phone: '',
    dob_day: null,
    dob_month: null,
    dob_year: null,
    ssn_last_4: '',
    country: 'IT', // Initialize with 'IT' as default
  });
  const navigate = useNavigate();
  const [stripeStep, setStripeStep] = useState(1); // Stripe step state
  const [inputValue, setInputValue] = useState('');

  // New state variables for regions and provinces
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);

  // Convert countries list from countries-list to suitable format for select input
  const countries = React.useMemo(
    () =>
      Object.keys(countriesList).map((code) => ({
        code: code,
        name: countriesList[code].name,
      })),
    []
  );

  // Flag to ensure formValues are initialized only once
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const { isStripeAccCreated, isStripeAccVerified } = user || {
      isStripeAccCreated: false,
      isStripeAccVerified: false,
    };

    console.log('User:', user);

    // Set Stripe step based on user status
    if (!isStripeAccCreated) {
      setStripeStep(1);
    } else if (isStripeAccCreated && !isStripeAccVerified) {
      setStripeStep(2);
    } else {
      setStripeStep(3); // Assuming step 3 indicates completion or next action
    }

    // Initialize formValues from user object only once
    if (user && isInitialLoad) {
      // Parse dateOfBirth and map user fields to formValues
      const parsedDate = user.dateOfBirth ? dayjs(user.dateOfBirth) : null;

      const formValuesFromUser = {
        email: user.email || '',
        first_name: user.firstName || '', // Map firstName to first_name
        last_name: user.lastName || '', // Map lastName to last_name
        address_line1: user.address_line1 || '',
        address_line2: user.address_line2 || '',
        city: user.city || '',
        postal_code: user.postal_code || '',
        phone: user.phone || '',
        dob_day: parsedDate ? parsedDate.date() : '', // Extract day from dateOfBirth
        dob_month: parsedDate ? parsedDate.month() + 1 : '', // Extract month from dateOfBirth (zero-indexed)
        dob_year: parsedDate ? parsedDate.year().toString() : '', // Extract year from dateOfBirth
        ssn_last_4: user.ssn_last_4 || '',
        country: user.country || 'IT',
      };
      setFormValues((prevValues) => ({
        ...prevValues,
        ...formValuesFromUser,
      }));
      console.log('Set form values from user data:', formValuesFromUser);

      // Set the inputValue based on the initial country
      const initialCountry = countries.find(
        (c) => c.code === formValuesFromUser.country
      );
      if (initialCountry) {
        setInputValue(initialCountry.name);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, countries]);

  // Fetch regions on component mount
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(
          'https://api.kiddocare.eu/api/geolanguage/region-list?page=1&limit=20'
        );
        if (response.status === 200) {
          const regionsData = response.data.data;
          setRegions(regionsData);

          // Set selectedRegion from user data
          if (user && isInitialLoad) {
            const userRegion = regionsData.find(
              (region) =>
                region.code === user.regionCode ||
                region.id === user.regionId ||
                region.denomination === user.regionDenomination ||
                region.denomination === user.regionName
            );
            if (userRegion) {
              setSelectedRegion(userRegion);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };
    fetchRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch provinces when selectedRegion changes
  useEffect(() => {
    if (selectedRegion) {
      const fetchProvinces = async () => {
        try {
          const response = await axios.get(
            `https://api.kiddocare.eu/api/geolanguage/region-province-list/${selectedRegion.code}?page=&limit=20`
          );
          if (response.status === 200) {
            const provincesData = response.data.data;
            setProvinces(provincesData);

            // Set selectedProvince from user data
            if (user && isInitialLoad) {
              const userProvince = provincesData.find(
                (province) =>
                  province.denomination === user.state ||
                  province.acronym === user.state
              );
              if (userProvince) {
                setSelectedProvince(userProvince);
                // Update formValues.state
                const updatedFormValues = {
                  ...formValues,
                  state: userProvince.denomination,
                };
                setFormValues(updatedFormValues);
              }
              setIsInitialLoad(false);
            }
          }
        } catch (error) {
          console.error('Error fetching provinces:', error);
        }
      };
      fetchProvinces();
    } else {
      setProvinces([]);
      setSelectedProvince(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  console.log('Current Country Code:', formValues.country);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    const updatedFormValues = { ...formValues, [name]: value };

    // Set form state
    setFormValues(updatedFormValues);
  };

  // Handle country selection
  const handleCountryChange = (event, value) => {
    const selectedCountryCode = value ? value.code : 'IT'; // Default to 'IT' if no selection
    const updatedFormValues = { ...formValues, country: selectedCountryCode };
    setFormValues(updatedFormValues);

    // Update the inputValue to reflect the selected country's name
    setInputValue(value ? value.name : 'IT');
  };

  const handleDateChange = (date) => {
    if (date && dayjs(date).isValid()) {
      const parsedDate = dayjs(date);
      const day = parsedDate.date(); // Day as integer
      const month = parsedDate.month() + 1; // Month as integer (add 1 because months are zero-indexed)
      const year = parsedDate.year().toString(); // Year as string

      // Update form values based on the selected date
      const updatedFormValues = {
        ...formValues,
        dob_day: day,
        dob_month: month,
        dob_year: year,
      };

      // Set form state
      setFormValues(updatedFormValues);
    } else {
      // If date is invalid or not selected, reset or handle accordingly
      const updatedFormValues = {
        ...formValues,
        dob_day: '',
        dob_month: '',
        dob_year: '',
      };
      setFormValues(updatedFormValues);
    }
  };

  const handleRegionChange = (event, value) => {
    setSelectedRegion(value);
    setSelectedProvince(null);
    setProvinces([]);
    // Reset formValues.state
    const updatedFormValues = { ...formValues, state: '' };
    setFormValues(updatedFormValues);
  };

  const handleProvinceChange = (event, value) => {
    setSelectedProvince(value);
    const updatedFormValues = {
      ...formValues,
      state: value ? value.denomination : '',
    };
    setFormValues(updatedFormValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Values:', formValues);
    await handleCreateStripeAccount(formValues);
  };

  const handleCreateStripeAccount = async (values) => {
    setStripSubmitLoading(true);
    try {
      const response = await axios.post(
        `${Constants.stripeAccCreate}/${user.id}`,
        values
      );
      if (response.status === 200) {
        const { id } = response.data.data;
        updateUser({ ...user, isStripeAccCreated: true, stripeAccountId: id });
        navigate('/payments'); // Redirect to payments or desired page after submission
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occurred. Please try again later.';
      console.error('Error updating banking information:', errorMessage);
      setApiError(errorMessage);
    } finally {
      setStripSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="flex gap-3 mb-6 font-semibold">
        <Button
          onClick={() => {
            navigate('/payments');
          }}
        >
          <i className="fa-solid fa-angle-left mr-2"></i> Payments
        </Button>
        <span>/</span>
        <span>Update Banking Information</span>
      </div>
      <h2 className="text-2xl text-center font-semibold mb-8">
        Update Banking Information
      </h2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} className="justify-center">
          <Grid item lg={6} md={12} xs={12}>
            <Grid container spacing={2}>
              {/* Stripe Step Indicator (Optional) */}
              {/* You can add UI elements here to indicate the current stripeStep */}

              {/* Personal Information */}
              <Grid item lg={12}>
                <h3 className="text-lg font-semibold">Personal Information</h3>
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="First Name"
                  name="first_name"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.first_name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.last_name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Date of Birth"
                      className="w-full"
                      value={
                        formValues.dob_day &&
                        formValues.dob_month &&
                        formValues.dob_year
                          ? dayjs(
                              `${formValues.dob_year}-${formValues.dob_month}-${formValues.dob_day}`,
                              'YYYY-M-D'
                            )
                          : null
                      }
                      onChange={(date) => handleDateChange(date)}
                      disableFuture
                      openTo="year"
                      views={['year', 'month', 'day']}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item lg={6}>
                <TextField
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.email}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item lg={6}>
                <PhoneInput
                  country={'it'} // Default country using its country code
                  value={formValues.phone}
                  onChange={(value, country, e, formattedValue) => {
                    // When the phone number changes, update the form state
                    setFormValues((prev) => ({
                      ...prev,
                      phone: formattedValue, // Use formattedValue here
                      country: country.countryCode.toUpperCase(), // Updates the country in formValues when the phone input's country changes
                    }));
                    // Update the input value for the country autocomplete
                    setInputValue(
                      countries.find(
                        (c) => c.code === country.countryCode.toUpperCase()
                      )?.name || ''
                    );
                  }}
                  

                  inputStyle={{
                    width: '100%',
                    height: '56px', // Match your design specifications
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item lg={12}>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  value={
                    countries.find(
                      (country) => country.code === formValues.country
                    ) || null
                  }
                  onChange={handleCountryChange}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      required
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value.code
                  }
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="Address Line 1"
                  name="address_line1"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.address_line1}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="Address Line 2"
                  name="address_line2"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.address_line2}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  label="City"
                  name="city"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.city}
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              {/* Region Field */}
              <Grid item lg={6}>
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option.denomination}
                  value={selectedRegion || null}
                  onChange={handleRegionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Region"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>

              {/* State Field */}
              <Grid item lg={6}>
                <Autocomplete
                  options={provinces}
                  getOptionLabel={(option) => option.denomination}
                  value={selectedProvince || null}
                  onChange={handleProvinceChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Province"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  label="Postal Code"
                  name="postal_code"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.postal_code}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  label="SSN Last 4"
                  name="ssn_last_4"
                  variant="outlined"
                  className="w-full rounded-lg"
                  value={formValues.ssn_last_4}
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              {/* Submit Button */}
              <Grid item lg={12}>
                {stripSubmitLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    className="w-full h-[54px] bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                  >
                    Connect
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {apiError && (
          <p className="text-red-500 text-center mt-4">{apiError}</p>
        )}
      </form>
    </>
  );
}
