import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";

const VideoControlButtons = ({
  micOn,
  cameraOn,
  setMic,
  setCamera,
  setCalling,
}) => {
  return (
    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-6 z-[3]">
      {/* Microphone Button */}
      <button
        className={`w-12 h-12 flex items-center justify-center rounded-full ${micOn ? "bg-green-600 text-white" : "bg-red-600 text-white"}`}
        onClick={() => setMic(!micOn)}
      >
        <FontAwesomeIcon icon={micOn ? faMicrophone : faMicrophoneSlash} />
      </button>
      {/* Camera Button */}
      <button
        className={`w-12 h-12 flex items-center justify-center rounded-full ${cameraOn ? "bg-green-600 text-white" : "bg-red-600 text-white"}`}
        onClick={() => setCamera(!cameraOn)}
      >
        <FontAwesomeIcon icon={cameraOn ? faVideo : faVideoSlash} />
      </button>
      {/* End Call Button */}
      <button
        className="w-12 h-12 flex items-center justify-center bg-red-600 text-white rounded-full"
        onClick={() => setCalling(false)} // This ends the call
      >
        <FontAwesomeIcon icon={faPhoneSlash} />
      </button>
    </div>
  );
};

export default VideoControlButtons;