import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Home from './Component/Home/Home';
import Aboutus from './Component/About/Aboutus';
import Login from './Component/Login/Login';
import Register from './Component/Register/Register';
import PediatricianRegister from './Component/PediatricianRegister/PediatricianRegister';
import Dashboard from './Component/Dashboard/Dashboard';
import Payments from './Component/Payments/Payments';
import Bankinginfo from './Component/Payments/BankingInfo';
import AddChildren from './Component/Dashboard/AddChildren';
import DefaultLayout from './Layouts/DefaultLayout';
import MinimalLayout from './Layouts/MinimalLayout';
import Profile from './Component/Profile/Profile';
// import EditPersonalDetails from './Component/Profile/Editprofile';
import EditPersonalDetails from './Component/Profile/editprofile/editPersonaldata';
import EditProfessionalDetails from './Component/Profile/editprofile/editProfesstionaldata';
import EditIdentityDetails from './Component/Profile/editprofile/editDocuments';
import CertificateList from './Component/Certificate/certificates';
import CertificateRequestList from './Component/Certificate/CertificateRequests';
// import EditProfessionalInfo from './Component/Profile/ProfesstionalData';
import { UserProvider } from './hooks/useUserContext'; // Import your UserProvider
import useAuthStore from './hooks/useAuthStore'; // Import your UserProvider
import ResetPasswordTemplate from './Component/Register/ResetPassword';
import StripeOnboardSuccess from './Component/Payments/StripeSuccess';
import CalendarVisit from './Component/Calender/CalendarVisit';
import AddStudio from './Component/Calender/AddStudio';
import Studio from './Component/Calender/Studio';
import ChooseAvilability from './Component/Calender/ChooseAvilability';
import DateSpecificHours from './Component/Calender/DateSpecificHours';
import AddAvailability from './Component/Calender/AddAvailability';
import AddCertificate from './Component/Certificate/AddCertificate';
import CertificateDetail from './Component/Certificate/ViewCertificate';
import ServicesList from './Component/Calender/ServicesList';
import SettingView from './Component/Calender/SettingView';
import ViewCalander from './Component/Calender/ViewCalander';
import CalendarAgenda from './Component/Calender/CalendarAgenda';
import CalendarAvailability from './Component/Calender/CalendarAvailability';
import SettingVisit from './Component/Calender/SettingVisit';
import ServicesListmodify from './Component/Calender/ServicesListmodify';
import PracticeManagement from './Component/Profile/PracticeManagement';
import PatientList from './Component/Patients/PatientList';
import VideoCall from './Component/Call/VideoCall';
import AudioCall from './Component/Call/AudioCall';
import Notification from './Component/Notification/Notification';
import EmptyNotification from './Component/Notification/EmptyNotification';
import RichiesteSOS from './Component/Notification/RichiesteSOS';
import RichiesteConsulto from './Component/Notification/RichiesteConsulto';
import RichiestePrescrizioni from './Component/Notification/RichiestePrescrizioni';
import RichiesteCertificati from './Component/Notification/RichiesteCertificati';
import AdvanceSearch from './Component/PatientSearch/AdvanceSearch';
import StampSignature from './Component/Profile/StampSignature';
import VideoCallClient from './Component/Calender/VideoCallClient';
import Sts from './Component/Profile/Sts';
import Receipts from './Component/Profile/Receipts';
import Communicationcenter from './Component/Profile/Communicationcenter';

const ProtectedRoute = ({ element: Element }) => {
  const { token } = useAuthStore();
  return token ? <Element /> : <Navigate to="/login" />;
};


function App() {
  return (
    <UserProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/register" element={<Register />}></Route>
          <Route exact path="/aboutus" element={<Aboutus />}></Route>
          <Route exact path="/rspassword" element={<ResetPasswordTemplate />}></Route>
          <Route exact path="/pediatrician-register" element={<PediatricianRegister />}></Route>


          {/* Wrap protected routes inside the ProtectedRoute component */}
          <Route exact path="/" element={<DefaultLayout />}>
            <Route exact path="/payments/success" element={<ProtectedRoute element={StripeOnboardSuccess} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
            <Route path="/payments" element={<ProtectedRoute element={Payments} />} />
            <Route path="/banking-info" element={<ProtectedRoute element={Bankinginfo} />} />
            <Route exact path="/profile/child/add" element={<ProtectedRoute element={AddChildren} />} />
            <Route exact path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route exact path="/profile/personal/edit" element={<ProtectedRoute element={EditPersonalDetails} />} />
            <Route exact path="/profile/professional/edit" element={<ProtectedRoute element={EditProfessionalDetails} />} />
            <Route exact path="/certificates" element={<ProtectedRoute element={CertificateList} />} />
            <Route exact path="/certificate/requests" element={<ProtectedRoute element={CertificateRequestList} />} />
            <Route exact path="/add-certificate" element={<ProtectedRoute element={AddCertificate} />} />
            <Route exact path="/add-certificate/:certId" element={<ProtectedRoute element={AddCertificate} />} />
            <Route exact path="/view-certificate/:certId" element={<ProtectedRoute element={CertificateDetail} />} />
            <Route exact path="/profile/identity/edit" element={<ProtectedRoute element={EditIdentityDetails} />} />
            <Route exact path="/calendar-visit" element={<ProtectedRoute element={CalendarVisit} />} />
            <Route exact path="/studio" element={<ProtectedRoute element={Studio} />} />
            <Route exact path="/add-studio" element={<ProtectedRoute element={AddStudio} />} />
            <Route exact path="/choose-availability" element={<ProtectedRoute element={ChooseAvilability} />} />
            <Route exact path="/add-date-specific-hours" element={<ProtectedRoute element={DateSpecificHours} />} />
            <Route exact path="/add-availability" element={<ProtectedRoute element={AddAvailability} />} />
            <Route exact path="/services-list" element={<ProtectedRoute element={ServicesList} />} />
            <Route exact path="/settings" element={<ProtectedRoute element={SettingView} />} />
            <Route exact path="/view-calander" element={<ProtectedRoute element={ViewCalander} />} />
            <Route exact path="/availability-calander" element={<ProtectedRoute element={CalendarAvailability} />} />
            <Route exact path="/agenda-calander" element={<ProtectedRoute element={CalendarAgenda} />} />
            <Route exact path="/setting-visit" element={<ProtectedRoute element={SettingVisit} />} />
            <Route exact path="/services-list-modify" element={<ProtectedRoute element={ServicesListmodify} />} />
            <Route exact path="/medical-practice-management" element={<ProtectedRoute element={PracticeManagement} />} />
            <Route exact path="/patients" element={<ProtectedRoute element={PatientList} />} />
            <Route exact path="/notification" element={<ProtectedRoute element={Notification} />} />
            <Route exact path="/empty-notification" element={<ProtectedRoute element={EmptyNotification} />} />
            <Route exact path="/empty-notification" element={<ProtectedRoute element={EmptyNotification} />} />
            <Route exact path="/request-sos" element={<ProtectedRoute element={RichiesteSOS} />} />
            <Route exact path="/request-consultation" element={<ProtectedRoute element={RichiesteConsulto} />} />
            <Route exact path="/request-prescription" element={<ProtectedRoute element={RichiestePrescrizioni} />} />
            <Route exact path="/request-certificate" element={<ProtectedRoute element={RichiesteCertificati} />} />
            <Route exact path="/advance-search" element={<ProtectedRoute element={AdvanceSearch} />} />
            <Route exact path="/stamp-and-signature" element={<ProtectedRoute element={StampSignature} />} />
            <Route exact path="/sts" element={<ProtectedRoute element={Sts} />} />
            <Route exact path="/receipts" element={<ProtectedRoute element={Receipts} />} />
            <Route exact path="/communication-center" element={<ProtectedRoute element={Communicationcenter} />} />
          </Route>

          {/* Routes with MinimalLayout (No Header/Footer) */}
          <Route element={<MinimalLayout />}>
            <Route exact path="/video-call" element={<ProtectedRoute element={VideoCall} />} />
            <Route exact path="/audio-call" element={<ProtectedRoute element={AudioCall} />} />
            <Route exact path="/meet/:channelId" element={<ProtectedRoute element={VideoCallClient} />} />
          </Route>

        </Routes>
      </div>
    </UserProvider>

  );
}

export default App;