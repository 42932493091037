import { Grid } from '@mui/material';
import React from 'react'

export default function Storieslist() {
    const storiesAreas = [
        { title: 'La sua salute', content: 'Eventi, visite fatte e vaccinazioni, per non perderti mai niente', image: 'assets/images/stories1.png',},
        { title: 'I primi ricordi', content: 'Salva foto e video dei suoi primi passi, le prime parole e tutti i ricordi a te cari', image: 'assets/images/stories2.png'},
        { title: 'Bilancio salute', content: 'Tieni sotto controllo l’andamento della salute',  image: 'assets/images/stories1.png'},
        { title: 'I primi ricordi', content: 'Salva foto e video dei suoi primi passi, le prime parole e tutti i ricordi a te cari', image: 'assets/images/stories3.png' }
    ]
    const storiesAreasList = storiesAreas.map(storiesArea =>
        <Grid item lg={3} md={6} xs={6} className='homestories'>
            <div className="text-center rounded-xl shadow-xl h-full hover:shadow-2xl transition hover:-translate-y-2 ">
                <div className="bg-gray-300 rounded-xl md:p-6 p-3 storiesbox">
                    <a href='#'><img src={storiesArea.image} className='h-40 w-40 mx-auto object-contain'/></a>
                </div>
                <div className='md:p-8 p-3'>
                    <h4 className="card-title sm:text-2xl text-lg font-semibold mb-3"><a href='#'>{storiesArea.title}</a></h4>
                    <p className='text-gray-600'>{storiesArea.content}</p>
                </div>
            </div>
        </Grid>
    );
  return (
    <>
    <Grid container columns={12} spacing={3}>
        {storiesAreasList}
    </Grid>
        

    </>
  )
}
