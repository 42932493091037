import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Aboutus() {
  const products = [
    {title: 'T-shirt', subtitle:'Polo Tshirt', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
    {title: 'Jeans', subtitle:'ABC Jeans', proImg:'https://i.imgur.com/1bX5QH6.jpg' },
    {title: 'Shirt', subtitle:'Raymond', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
    {title: 'Sharee', subtitle:'MInu', proImg:'https://i.imgur.com/1bX5QH6.jpg'},
  ]
  const listItems = products.map(product =>
    <div className='col-lg-3' key={product.id}>
      <div className='card shadow-lg'>
        <div className='card-body'>
          <img src={product.proImg} className='w-100'/>
          <h2 className='h4 fw-bold'>{product.title}</h2>
          <span className='badge bg-secondary'>{product.subtitle}</span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Breadcumb pagetitle="About Us"/>
      
    </>
  )
}
