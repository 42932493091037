import React from 'react'
import NavigationBar from './Navigation'
import { Grid } from '@mui/material'
import { Outlet } from 'react-router-dom';

export default function DefaultLayout() {
  return (
    <>
      <section>
        <div className='2xl:container mx-auto'>
          <Grid container columns={12} spacing={2}>
            <Grid item md={9} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
              <div className='md:p-8 p-5 h-full'>
                {/* Dashboard content */}
                <Outlet />
              </div>
            </Grid>

            <Grid item md={3} sm={12} xs={12}>
              <NavigationBar />
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  )
}