import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useAuthStore = create(
  persist(
    (set,get,api) => ({
      token: null,
      id: null, // Add the 'id' property
      setToken: (data) =>
        set(() => ({
          token: data,
        })),
      setId: (data) =>
        set(() => ({
          id: data,
        })), // Action to set the user ID
      resetToken: () =>{
        set(() => ({
          token: null,
        }));
        api.persist.clearStorage();
      },
      resetId: () =>
        set(() => ({
          id: null,
        })), // Action to reset the user ID
    }),
    {
      name: 'AuthStorage',
      // keeping it locastorage for now so that session persists accross multiple windows 
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useAuthStore