import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Constants } from '../../endPoints';
import axios from 'axios';
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext

const StripeOnboardSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user, updateUser } = useUser(); // Access the user info from the context

  useEffect(() => {
    const acc = searchParams.get('acc');
    const pid = searchParams.get('pid');

    console.log('Stripe Account:', acc);
    console.log('User ID:', pid);

    verifyStripeAccount({
      acc: acc,
      pid: pid,
    })
    // Set a timer to navigate after 6 seconds
    const timer = setTimeout(() => {
      navigate('/payments'); // Replace with the route where you want to navigate
    }, 6000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);

  }, []);


  const verifyStripeAccount = async (values) => {
    try {
      const response = await axios.post(`${Constants.stripeAccVerify}/${values.pid}`, {
        accountId: values.acc,
      });
      if (response.status === 200) {
        console.log('Stripe account verified successfully');
        updateUser({
          ...user,
          isStripeAccVerified: true,
        })
      }

    } catch (error) {
      console.error('Error verifying Stripe account:', error)

    }

  }


  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
      <div className="bg-white rounded-lg p-8 shadow-lg text-center">
        {/* Success Icon */}
        <svg
          className="w-16 h-16 text-green-500 mx-auto mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2l4 -4"
          />
        </svg>

        {/* Success Message */}
        <h1 className="text-2xl font-bold text-gray-800 mb-2">Success!</h1>
        <p className="text-gray-600">
          Your Stripe account onboarding has been successfully completed.
        </p>

        {/* Optional Button to Continue */}
        <button
          className="mt-6 bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600 transition duration-300 ease-in-out"
          onClick={() => {
            navigate("/dashboard", { replace: true });
          }}
        >
          Continue to Dashboard
        </button>
      </div>
    </div>
  );
};

export default StripeOnboardSuccess;
