
import React, { useState, useEffect, useContext } from 'react'
import { Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress, MenuItem,Autocomplete } from '@mui/material'
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce'; // **[Change 2]**: Import debounce
import { Constants } from '../../endPoints';
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik, ErrorMessage } from 'formik';
import { LanguageContext } from '../../LanguageContext';
import IdentificationDocument from "../Profile/IdentificationDocument";


const PersonalDetails = ({formik}) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);

  // **[Change 5]**: Debounced function to fetch cities based on user input
  const fetchCitiesAutocomplete = debounce(async (input) => {
      if (input.length >= 1) { // Fetch only if input length is >= 1
          setCityLoading(true);
          try {
              const response = await axios.get(`${Constants.cityList}`, { // **[Change 6]**: API endpoint for fetching cities
                  params: {
                      page: 1,
                      limit: 5,
                      keyword: input,
                  },
              });
              if (response.data.status === 1 && response.data.data.length > 0) {
                  // Assuming 'denominations' is the city name field
                  setCityOptions(response.data.data.map(city => city.denominations));
              } else {
                  setCityOptions([]);
              }
          } catch (error) {
              console.error('Error fetching cities:', error);
              setCityOptions([]);
          } finally {
              setCityLoading(false);
          }
      } else {
          setCityOptions([]); // Clear options if input is empty
      }
  }, 300); // Debounce delay of 300ms

  // **[Change 7]**: Handler for input changes in Place of Birth Autocomplete
  const handlePlaceOfBirthInputChange = (event, value) => {
      fetchCitiesAutocomplete(value);
  };


    return (
        <>
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='firstName'
                  name='firstName'
                  label='Nome'
                  variant='outlined'
                  placeholder='Cognome'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='lastName'
                  name='lastName'
                  label='Cognome'
                  variant='outlined'
                  placeholder='Cognome'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>

              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='dateOfBirth'
                  name='dateOfBirth'
                  label='Data di nascita'
                  variant='outlined'
                  type='date'
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                  helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                  InputLabelProps={{
                    shrink: true, // Ensures the label doesn't overlap the input value
                  }}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>    
              <div className='mb-5'>
                <Autocomplete
                    freeSolo // Allows free text input
                    options={cityOptions} // **[Change 9]**: Set autocomplete options
                    loading={cityLoading} // **[Change 10]**: Show loading spinner when fetching data
                    value={formik.values.placeOfBirth} // **[Change 11]**: Bind value to Formik
                    onChange={(event, value) => formik.setFieldValue('placeOfBirth', value || '')} // **[Change 12]**: Update Formik state on selection
                    onInputChange={handlePlaceOfBirthInputChange} // **[Change 13]**: Handle input changes to fetch suggestions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Place of Birth (City)"
                            variant="outlined"
                            name="placeOfBirth"
                            onBlur={formik.handleBlur}
                            error={formik.touched.placeOfBirth && Boolean(formik.errors.placeOfBirth)}
                            helperText={formik.touched.placeOfBirth && formik.errors.placeOfBirth}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {cityLoading ? <CircularProgress color="inherit" size={20} /> : null} {/* **[Change 14]**: Add loading indicator */}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                                style: { height: '54px' },
                            }}
                        />
                    )}
                />
            </div>
             
              <div className='mb-5'>
                <TextField
                  fullWidth
                  id='taxCode'
                  name='taxCode'
                  label='Codice Fiscale'
                  variant='outlined'
                  placeholder='Codice Fiscale'
                  value={formik.values.taxCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.taxCode && Boolean(formik.errors.taxCode)}
                  helperText={formik.touched.taxCode && formik.errors.taxCode}
                  InputProps={{ style: { height: '54px' } }}
                />
              </div>
              
            </>
    )
}

export default PersonalDetails 