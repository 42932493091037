import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, FormControl, InputLabel, Select, Checkbox, MenuItem, Switch, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalanderDate from "react-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; // Constants for endpoints
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';
import './CalendarStyles.css';


const localizer = momentLocalizer(moment);

// Helper function to map a day name to the corresponding date in the current week
const getDateFromDayName = (dayName) => {
  const daysOfWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };

  const today = moment(); // Current date
  const dayIndex = daysOfWeek[dayName]; // Get index of the day from the name
  return today.startOf('week').add(dayIndex, 'days').toDate(); // Return the date corresponding to the day in the current week
};



export default function ViewCalendar() {
  const idPediatrician = localStorage.getItem('userId');
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // Store selected event
  const [selectedSlot, setSelectedSlot] = useState(null); // Store selected time slot (start and end time)
  const [availabilityList, setAvailabilityList] = useState([]); // Dynamic availability list
  const [events, setEvents] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [formError, setFormError] = useState(''); // State to store form validation errors
  const [value, setValue] = useState(new Date());
  const [isAddNewSlot, setIsAddNewSlot] = useState(false);  // New state to track if "Add New Slot" button is clicked

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]); // Initialize selectedDate
  const [loading, setLoading] = useState(false);
  const [pgloading, setPgLoading] = useState(false);
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false); // Loader state for the save button
  const [calanderDate, setCalanderDate] = useState(new Date());

  const [keyword, setKeyword] = useState('');
  const [availabilities, setAvailabilities] = useState('');
  const [activeFilter, setActiveFilter] = useState('');  // Track active filter

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setCalanderDate(date);
    console.log("original date:", date);
  };

  // Calculate the start and end of the week based on selectedDate
  const startOfWeek = moment(calanderDate).startOf('week').toDate();
  const endOfWeek = moment(calanderDate).endOf('week').toDate();

  console.log("Selected date:", calanderDate);
  console.log("startOfWeek:", startOfWeek);

  // Fetch availability data
  const fetchAvailability = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Constants.allAvailability}/${idPediatrician}?keyword=${keyword}&availabilities=${availabilities}`);
      const { status, data } = response.data; // Destructure the response correctly

      if (response.data.status === 1 && response.data.list.length > 0) {
        setAvailabilityList(response.data.list); // Set the availability list
        setEvents(mapAvailabilityToEvents(response.data.list)); // Set events based on the availability data
      } else {
        // Clear the list and events if no data is fetched
        setAvailabilityList([]); // Reset availability list
        setEvents([]); // Clear events
        setError('No availability details available.');
      }
    } catch (error) {
      setError('Error fetching availability details');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch studio addresses
  const getStudios = async () => {
    try {
      const response = await fetch(`${Constants.pediatricianAddressList}${idPediatrician}`);
      if (response.ok) {
        const data = await response.json(); // Directly parse JSON
        if (data.status === 1) {
          setAddresses(Array.isArray(data.data) ? data.data : []); // Set fetched addresses
        } else {
          console.error('Failed to fetch addresses:', data.message);
        }
      } else {
        console.error('Failed to fetch addresses');
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

   // Convert API data to calendar events
   const mapAvailabilityToEvents = () => {
    const mappedEvents = [];
    const startYear = 2024; // Fixed start year for all slots
    const endYear = startYear + 5; // Show slots for the next 3 years (2024 to 2027)

    availabilityList.forEach((slot) => {
      if (slot.availabilityType === 'Weekly' && slot.weekName) {
        // Start from the first week of the fixed year (2024)
        const currentDate = moment().year(startYear).startOf('year'); // Start from January 1st of the fixed year

        // Loop through all weeks up to the end year (2027)
        while (currentDate.year() <= endYear) {
          const dayDate = currentDate.clone().day(slot.weekName).toDate(); // Get the date for the specified day (e.g., "Monday")

          const startDate = new Date(dayDate);
          startDate.setHours(slot.slotStart.split(':')[0], slot.slotStart.split(':')[1]);

          const endDate = new Date(dayDate);
          endDate.setHours(slot.slotEnd.split(':')[0], slot.slotEnd.split(':')[1]);

          const studioName = slot.studioName ? `${slot.studioName}` : '';

          // Determine the color based on conditions
          let eventColor;
          if (slot.isSSN && slot.isPrivate && slot.ssnClinic && slot.ssnRemote && slot.privateClinic && slot.privateRemote) {
            eventColor = '#FFC885'; // Yellow
          } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && slot.isPrivate && slot.privateClinic && !slot.privateRemote) {
            eventColor = '#FFC885'; // Yellow
          } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote) {
            eventColor = '#FFC885'; // Yellow
          } else if (slot.isSSN && slot.ssnClinic && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnRemote) {
            eventColor = '#FFC885'; // Yellow for SSN in Clinic
          } else if (slot.isSSN && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnClinic) {
            eventColor = '#FFA232'; // Orange for SSN remotely
          } else if (slot.isPrivate && slot.privateClinic && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateRemote) {
            eventColor = '#828DEE'; // Violet for Private in Clinic
          } else if (slot.isPrivate && slot.privateClinic && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote) {
            eventColor = '#828DEE'; // Violet for Private in Clinic
          } else if (slot.isPrivate && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateClinic) {
            eventColor = '#5EAF88'; // Green for Private remotely
          } else {
            eventColor = '#FFC885'; // Default color if no other conditions are matched
          }

          let eventTitle = 'Visit '; // Start with "Visit" only once

          // Append SSN details if isSSN is true
          if (slot.isSSN) {
            eventTitle += 'SSN ';
            if (slot.ssnClinic && slot.ssnRemote) {
              eventTitle += 'in studio e da remoto ';
            } else if (slot.ssnClinic) {
              eventTitle += 'in studio ';
            } else if (slot.ssnRemote) {
              eventTitle += 'da remoto ';
            }
          }

          // Append Private details if isPrivate is true
          if (slot.isPrivate) {
            eventTitle += 'Privato ';
            if (slot.privateClinic && slot.privateRemote) {
              eventTitle += 'in studio e da remoto ';
            } else if (slot.privateClinic) {
              eventTitle += 'in studio ';
            } else if (slot.privateRemote) {
              eventTitle += 'da remoto ';
            }
          }

          eventTitle = eventTitle.trim(); // Remove any trailing spaces


              // Push the event for this week
              mappedEvents.push({
                id: slot.id,
                idPediatrician: slot.idPediatrician,
                idStudio: slot.idStudio,
                availabilityType: slot.availabilityType,
                weekName: slot.weekName,
                slotInterval: slot.slotInterval,
                slotStart: slot.slotStart,
                slotEnd: slot.slotEnd,
                languageId: slot.languageId,
                studioName: slot.studioName,
                title: eventTitle,
                start: startDate,
                end: endDate,
                color: eventColor,
                isSSN: slot.isSSN,
                ssnClinic: slot.ssnClinic,
                ssnRemote: slot.ssnRemote,
                isPrivate: slot.isPrivate,
                privateClinic: slot.privateClinic,
                privateRemote: slot.privateRemote,
                selectedAddress: slot.idStudio,
                timeSlot: slot.slotInterval,
              });

          currentDate.add(1, 'week'); // Move to the next week
        }
      } else if (slot.availabilityType === 'Specific Date' && slot.specificDate) {
        // Handle Specific Date availability type
        const specificDate = new Date(slot.specificDate);
        const startDate = new Date(specificDate);
        startDate.setHours(slot.slotStart.split(':')[0], slot.slotStart.split(':')[1]);

        const endDate = new Date(specificDate);
        endDate.setHours(slot.slotEnd.split(':')[0], slot.slotEnd.split(':')[1]);

        const studioName = slot.studioName ? `${slot.studioName}` : '';
        // Determine the color based on conditions
        let eventColor;
        if (slot.isSSN && slot.isPrivate && slot.ssnClinic && slot.ssnRemote && slot.privateClinic && slot.privateRemote) {
          eventColor = '#FFC885'; // Yellow
        } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && slot.isPrivate && slot.privateClinic && !slot.privateRemote) {
          eventColor = '#FFC885'; // Yellow
        } else if (slot.isSSN && slot.ssnClinic && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote) {
          eventColor = '#FFC885'; // Yellow
        } else if (slot.isSSN && slot.ssnClinic && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnRemote) {
          eventColor = '#FFC885'; // Yellow for SSN in Clinic
        } else if (slot.isSSN && slot.ssnRemote && !slot.isPrivate && !slot.privateClinic && !slot.privateRemote && !slot.ssnClinic) {
          eventColor = '#FFA232'; // Orange for SSN remotely
        } else if (slot.isPrivate && slot.privateClinic && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateRemote) {
          eventColor = '#828DEE'; // Violet for Private in Clinic
        } else if (slot.isPrivate && slot.privateClinic && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote) {
          eventColor = '#828DEE'; // Violet for Private in Clinic
        } else if (slot.isPrivate && slot.privateRemote && !slot.isSSN && !slot.ssnClinic && !slot.ssnRemote && !slot.privateClinic) {
          eventColor = '#5EAF88'; // Green for Private remotely
        } else {
          eventColor = '#FFC885'; // Default color if no other conditions are matched
        }

        // Build the event title without studio name
        let eventTitle = '';
        if (slot.isSSN) {
          eventTitle += 'Visit ';
          eventTitle += slot.ssnClinic ? 'SSN in studio ' : '';
          eventTitle += slot.ssnRemote ? 'da remoto ' : '';
        }
        if (slot.isPrivate) {
          eventTitle += 'Visit ';
          eventTitle += slot.privateClinic ? 'Privato in studio ' : '';
          eventTitle += slot.privateRemote ? 'da remoto ' : '';
        }
        eventTitle = eventTitle.trim(); // Remove any trailing spaces

        mappedEvents.push({
            id: slot.id,
            idPediatrician: slot.idPediatrician,
            idStudio: slot.idStudio,
            availabilityType: slot.availabilityType,
            weekName: slot.weekName,
            slotInterval: slot.slotInterval,
            slotStart: slot.slotStart,
            slotEnd: slot.slotEnd,
            languageId: slot.languageId,
            studioName: slot.studioName,
            title: eventTitle,
            start: startDate,
            end: endDate,
            color: eventColor,
            isSSN: slot.isSSN,
            ssnClinic: slot.ssnClinic,
            ssnRemote: slot.ssnRemote,
            isPrivate: slot.isPrivate,
            privateClinic: slot.privateClinic,
            privateRemote: slot.privateRemote,
            selectedAddress: slot.idStudio,
            timeSlot: slot.slotInterval,
          });
      }
    });

    return mappedEvents;
  };

  // Initialize local state from API on component mount
  useEffect(() => {
    const fetchData = async () => {
      setPgLoading(true); // Optional: Set loading state while fetching
  
      // Fetch availability, studios, and patients
      fetchAvailability();
      getStudios();
  
      try {
        const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
  
        // Check if `isAvailabilityAdded` is present in the response
        if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
          if (response.data.data.isAvailabilityAdded === false) {
            // If `isAvailabilityAdded` is true, redirect to the `calendar-visit` page
            navigate('/calendar-visit');
          } else {
            // If `isAvailabilityAdded` is false, stop loading and stay on the page
            setPgLoading(false);
          }
        } else {
          setPgLoading(false);
        }
      } catch (error) {
        console.error("Error fetching pediatrician data:", error);
        setPgLoading(false);
      }
    };
  
    // Invoke the fetchData function to initiate API calls
    fetchData();
  
  }, [idPediatrician, navigate, calanderDate, keyword, availabilities]);

  useEffect(() => {
    // Ensure the calendar updates based on the availability list
    if (availabilityList.length > 0) {
      setEvents(mapAvailabilityToEvents(availabilityList)); // Map and set events when availabilityList changes
    } else {
      setEvents([]); // Clear events if no data is available
    }
  }, [availabilityList]); // Only depend on availabilityList here to trigger the event setting

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setKeyword(e.target.value);
  };

  // Update the availability when a filter button is clicked
  const handleFilterClick = (filterType) => {
    if (filterType !== availabilities) { // Only update if filterType is different
      setActiveFilter(filterType); // Set the clicked filter as active
      setAvailabilities(filterType); // Update availabilities for the API call
    }
  };

  console.log("availabilityList ", availabilityList);
  console.log("events ", events);


  // Conditional class application
  const getLinkClass = (filterType) => {
    return activeFilter === filterType
      ? 'bg-[#828DEE] text-white py-[5px] px-4 inline-block border rounded-full text-sm border-[#72777A]'
      : 'text-[#72777A] py-[5px] px-4 inline-block border rounded-full text-sm border-[#72777A] hover:bg-[#828DEE] hover:text-white';
  };

  // Define eventPropGetter to customize event background color
  const eventPropGetter = (event) => {
    const backgroundColor = event.color; // Set color based on event data
    return { style: { backgroundColor } }; // Return the style object with the color
  };

  const handleEventClick = (event) => {
    setIsAddNewSlot(false);  // Not adding a new slot, just editing an existing event

    const day = event.weekName || moment(event.start).format('dddd');
    const selectedDate = moment(event.start).format('YYYY-MM-DD');

    setSelectedEvent({
      id: event.id,
      idPediatrician: event.idPediatrician,
      idStudio: event.idStudio,
      availabilityType: event.availabilityType,
      weekName: day,
      selectedDate,
      slotInterval: event.slotInterval,
      slotStart: moment(event.start).format('HH:mm'),
      slotEnd: moment(event.end).format('HH:mm'),
      languageId: event.languageId,
      studioName: event.studioName,
      timeSlot: event.timeSlot || 'min10',
      isSSN: event.isSSN || false,
      ssnClinic: event.ssnClinic || false,
      ssnRemote: event.ssnRemote || false,
      isPrivate: event.isPrivate || false,
      privateClinic: event.privateClinic || false,
      privateRemote: event.privateRemote || false,
      selectedAddress: event.selectedAddress || '',
    });

    setOpenModal(true);  // Open modal
  };

  const handleSlotSelect = (slotInfo) => {
    setIsAddNewSlot(false);  // Not adding a new slot, just editing an empty time slot

    const startMoment = moment(slotInfo.start);
    const endMoment = moment(slotInfo.end);
    const day = startMoment.format('dddd');
    const selectedDate = startMoment.format('YYYY-MM-DD');
    const minimumEndTime = startMoment.clone().add(1, 'hours');

    setSelectedSlot({
      day,
      selectedDate,
      start: startMoment.format('HH:mm'),
      end: endMoment.isAfter(minimumEndTime) ? endMoment.format('HH:mm') : minimumEndTime.format('HH:mm'),
      isSSN: false,
      ssnClinic: false,
      ssnRemote: false,
      isPrivate: false,
      privateClinic: false,
      privateRemote: false,
      selectedAddress: '',
    });

    setSelectedEvent(null);
    setOpenModal(true);  // Open the modal
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvent(null); // Clear the selected event
    setSelectedSlot(null); // Clear the selected slot
  };

  // Custom day header component to hide the date
  // const DayHeader = ({ label }) => <span>{label.split(' ')[0]}</span>;
  const DayHeader = ({ label }) => {
    const [day, date] = label.split(' '); // Split the label into day and date
    const singleCharDay = day.charAt(0); // Get the first character of the day

    return (
      <div style={{ textAlign: 'center' }}> {/* Center align the content */}
        <span>{singleCharDay}</span> {/* Display the first letter of the day */}
        <br />
        <span>{date}</span> {/* Display the date below */}
      </div>
    );
  };

  // Function to handle "Add New Slot" button click
  const handleAddNewSlotClick = () => {
    setIsAddNewSlot(true);  // Set flag to true for bypassing date validation

    console.log("isAddNewSlot set", isAddNewSlot);

    setSelectedSlot({
      day: '',  // Force the user to select a day when the button is clicked
      start: '',  // Empty start time
      end: '',  // Empty end time
      isSSN: false,
      ssnClinic: false,
      ssnRemote: false,
      isPrivate: false,
      privateClinic: false,
      privateRemote: false,
      selectedAddress: '',  // No address selected initially
    });
    setSelectedEvent(null);  // No pre-selected event
    setOpenModal(true);  // Just open the modal, no validation yet
  };

  // Validation function to avoid duplication
  const validateForm = (eventData) => {
    // Extract start, end times, timeSlot, day, and date from eventData
    const slotStart = eventData?.slotStart || eventData?.start || '';
    const slotEnd = eventData?.slotEnd || eventData?.end || '';
    const timeSlot = eventData?.timeSlot || '';
    const day = eventData?.day || eventData?.weekName || '';
    const selectedDate = eventData?.selectedDate || '';

    // Perform form validations
    if (!slotStart || !slotEnd) {
      return 'Please provide both start and end times.';
    }

    if (!timeSlot) {
      return 'Please select a valid visit time slot.';
    }

    if (!day) {
      return 'Please select a valid day.';
    }

    // Skip the date validation if it's the "Add New Slot" button case
    if (!isAddNewSlot && !selectedDate) {
      return 'Please select a valid date.';
    }

    const { privateClinic = false, ssnClinic = false, selectedAddress = null } = eventData;

    if ((privateClinic || ssnClinic) && !selectedAddress) {
      return 'Please select a studio address if "In the Clinic" is selected for SSN or Private.';
    }

    return ''; // No errors
  };

  const handleDeleteWithSwal = async () => {
    const eventData = selectedEvent || selectedSlot;

    // Show Swal alert for deletion options
    const { value: selectedOption } = await Swal.fire({
      title: 'Cancella orario',
      text: `Gli appuntamenti precedentemente prenotati non verranno cancellati`,
      input: 'radio',
      inputOptions: {
        updateSingleDay: 'Solo questa data',
        updateAllDays: 'The whole calendar',
      },
      inputValidator: (value) => {
        if (!value) {
          return "Seleziona un'opzione!";
        }
      },
      confirmButtonText: 'Confermare',
      showCancelButton: true,
      cancelButtonText: 'Cancellare',
    });

    if (selectedOption) {
      await handleDelete(selectedOption);  // Pass the delete option to handleDelete
    }
  };

  const handleDelete = async (deleteOption) => {
    const eventData = selectedEvent || selectedSlot;
    setLoadingSave(true);  // Show loading indicator

    try {
      let apiUrl = `${Constants.deleteAvailability}/${selectedEvent?.id}`;  // Set the API endpoint
      const requestPayload = {
        idPediatrician,
        day: eventData.day || eventData.weekName,  // Include the day in the payload
        languageId: 1,
        slotStart: eventData.slotStart || eventData.start,
        slotEnd: eventData.slotEnd || eventData.end,
        timeSlot: eventData.timeSlot,
        deleteOption,  // Include the selected delete option (single day or all days)
      };

      // Scenario 1: Deleting for a specific day
      if (deleteOption === 'updateSingleDay') {
        requestPayload.availabilityType = "Specific Date";  // Specify the type for single day deletion
        requestPayload.selectedDate = eventData.selectedDate;  // Include the date
      }

      // Scenario 2: Deleting for all days
      else if (deleteOption === 'updateAllDays') {
        requestPayload.availabilityType = "Weekly";  // Specify the type for weekly deletion
      }

      // Make the API request
      const response = await axios.post(apiUrl, requestPayload);

      if (response.data.status === 1) {
        // Handle successful deletion
        fetchAvailability();  // Refresh the availability after deletion
        setOpenModal(false);  // Close modal
      } else {
        setFormError('Failed to delete availability. Please try again.');
      }
    } catch (error) {
      setFormError('Error deleting availability. Please try again.');
      console.error('Error deleting availability:', error);
    } finally {
      setLoadingSave(false);  // Stop the loader
    }
  };


  const handleSaveWithSwal = async () => {
    const eventData = selectedEvent || selectedSlot;

    console.log("isAddNewSlot ", isAddNewSlot);

    // Perform form validation and bypass date validation for "Add New Slot"
    const validationError = validateForm(eventData);
    if (validationError) {
      setFormError(validationError);  // Show the validation error in the form
      return;
    }

    // Prepare radio options based on whether it's a new slot or existing one
    const inputOptions = isAddNewSlot
      ? { updateAllDays: 'Tutto il calendario' }  // Only show this option when adding a new slot
      : {
        updateSingleDay: 'Solo questa data',
        updateAllDays: 'Tutto il calendario',
      }; // Show both options when editing an existing slot

    // Show the Swal alert after validation passes
    const { value: selectedOption } = await Swal.fire({
      title: 'Modifica calendario',
      text: 'Do you want to edit just for this date or for the entire calendar?',
      input: 'radio',
      inputOptions: inputOptions,  // Use conditional inputOptions
      inputValidator: (value) => {
        if (!value) {
          return "Seleziona un'opzione!";
        }
      },
      confirmButtonText: 'Confermare',
      showCancelButton: true,
      cancelButtonText: 'Cancellare',
    });

    if (selectedOption) {
      handleSave(selectedOption);  // Pass the flag to handleSave as well
    }
  };


  const handleSave = async (updateOption) => {
    const eventData = selectedEvent || selectedSlot;

    // Perform form validation again to ensure it's still valid when saving
    const validationError = validateForm(eventData);
    if (validationError) {
      setFormError(validationError);
      return;
    }

    setLoadingSave(true); // Start loader
    setFormError(''); // Clear previous errors

    try {
      let apiUrl = `${Constants.updateAvailability}`; // Default URL for both update and create
      const requestPayload = {
        idPediatrician,
        day: eventData.day || eventData.weekName,  // Add the day to the request payload
        languageId: 1,
        slotStart: eventData.slotStart || eventData.start,
        slotEnd: eventData.slotEnd || eventData.end,
        timeSlot: eventData.timeSlot,
        isSSN: eventData.isSSN || false,
        ssnClinic: eventData.ssnClinic || false,
        ssnRemote: eventData.ssnRemote || false,
        isPrivate: eventData.isPrivate || false,
        privateClinic: eventData.privateClinic || false,
        privateRemote: eventData.privateRemote || false,
        selectedAddress: eventData.selectedAddress || null,
        updateOption, // Add the selected update option to the payload
      };

      // Only include selectedDate if updateOption is "updateSingleDay"
      if (updateOption === "updateSingleDay") {
        requestPayload.selectedDate = eventData.selectedDate; // Include the specific date for this day
      }

      // Scenario 1: Existing event (id is present) with "updateSingleDay" option
      if (selectedEvent?.id && updateOption === "updateSingleDay") {
        // requestPayload.id = selectedEvent.id; 
        requestPayload.availabilityType = "Specific Date"; // Update availability type to "Specific Date"
        requestPayload.selectedDate = eventData.selectedDate; // Include the specific date for this day
        apiUrl = `${Constants.updateAvailability}`;
      }

      // Scenario 2: Existing event with "updateAllDays" option
      else if (selectedEvent?.id && updateOption === "updateAllDays") {
        requestPayload.id = selectedEvent.id; // Include ID for the update
        requestPayload.availabilityType = "Weekly"; // Keep availability type as "Weekly"
        // requestPayload.selectedDate = eventData.selectedDate;
        apiUrl = `${Constants.updateAvailability}`;
      }

      // Scenario 3: New slot (id is empty) with "updateAllDays" option
      else if (!selectedEvent?.id && updateOption === "updateAllDays") {
        requestPayload.availabilityType = "Weekly"; // Set availability type as "Weekly" for new slot
        // requestPayload.selectedDate = eventData.selectedDate; // Include selected date
        apiUrl = `${Constants.updateAvailability}`; // Create API for a new slot
      }

      // Scenario 4: New slot (id is empty) with "updateSingleDay" option
      else if (!selectedEvent?.id && updateOption === "updateSingleDay") {
        requestPayload.availabilityType = "Specific Date"; // Set availability type as "Specific Date" for new slot
        requestPayload.selectedDate = eventData.selectedDate; // Include selected date
        apiUrl = `${Constants.updateAvailability}`; // Create API for a new slot
      }

      // Scenario 5: New slot via "Add New Slot" button, with "updateAllDays" option
      else if (!selectedEvent?.id && isAddNewSlot && updateOption === "updateAllDays") {
        requestPayload.availabilityType = "Weekly"; // Set availability type to "Weekly"
        apiUrl = `${Constants.updateAvailability}`; // Create API for a new slot
      }

      // Now send the request to the backend
      const response = await axios.post(apiUrl, requestPayload);

      if (response.data.status === 1) {
        // Handle success
        fetchAvailability(); // Refresh the availability list after update
        setOpenModal(false); // Close the modal
      } else {
        setFormError('Failed to save availability. Please try again.');
      }
    } catch (error) {
      setFormError('Error saving availability. Please try again.');
      console.error('Error saving availability:', error);
    } finally {
      setLoadingSave(false); // Stop loader
    }
  };

  const CustomToolbar = (props) => {
    const goToWeekView = () => {
      props.onView('week');
    };

    const goToDayView = () => {
      props.onView('day');
    };

    const goToAgendaView = () => {
      props.onView('agenda');
    };

    return (
      <div className="rbc-toolbar custom-toolbar"> {/* Added custom-toolbar class */}
        <span className="rbc-btn-group toolbar-icons">
          {/* Day View */}
          <button
            type="button"
            onClick={goToDayView}
            className={props.view === 'day' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          >
            <img src='assets/images/view_stream.png' />

          </button>
          {/* Agenda View */}
          <button
            type="button"
            onClick={goToAgendaView}
            className={props.view === 'agenda' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          >
            <img src='assets/images/calendar_view_day.png' />

          </button>

          {/* Week View */}
          <button
            type="button"
            onClick={goToWeekView}
            className={props.view === 'week' ? 'rbc-active' : ''}
            style={{ border: 'none', background: 'transparent' }}
          > <img src='assets/images/calendar_view_week.png' />
          </button>

        </span>
      </div>
    );
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (pgloading) {
  //     return <div>Loading...</div>; 
  // }



  return (
    <>

      <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
          <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
            <div>
              <Grid container spacing={3} className='items-center'>
                <Grid item lg={8} md={12} xs={12}>
                  <div className='flex gap-3 mb-6 font-semibold'>
                    <Link to="/agenda-calander"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                  </div>
                </Grid>
                <Grid item lg={4} md={12} xs={12}>
                  <form>
                    <div className='relative'>
                      <div>
                          <input
                            type="text"
                            className='pl-10 w-full py-2 border border-gray-300 rounded-lg'
                            placeholder='Cerca'
                            value={keyword}
                            onChange={handleSearchChange} // Handle search input
                          />
                          <button type="button" className='absolute top-2 left-3 text-gray-400'>
                            <i className="fa-regular fa-magnifying-glass"></i>
                          </button>
                        </div>
                    </div>
                  </form>
                </Grid>
              </Grid>
              <div className='pt-6 mb-6'>
                <div className='flex gap-4 justify-end'>
                    <Link
                      to="javascript:void(0);"
                      className={getLinkClass('privateRemote')} // Conditionally apply classes
                      onClick={() => handleFilterClick('privateRemote')} // Handle button click for filter
                    >
                      Privato da remoto
                    </Link>

                    <Link
                      to="javascript:void(0);"
                      className={getLinkClass('privateClinic')} // Conditionally apply classes
                      onClick={() => handleFilterClick('privateClinic')} // Handle button click for filter
                    >
                      Privato in studio
                    </Link>

                    <Link
                      to="javascript:void(0);"
                      className={getLinkClass('ssnClinic')} // Conditionally apply classes
                      onClick={() => handleFilterClick('ssnClinic')} // Handle button click for filter
                    >
                      SNN in studio
                    </Link>

                    <Link
                      to="javascript:void(0);"
                      className={getLinkClass('ssnRemote')} // Conditionally apply classes
                      onClick={() => handleFilterClick('ssnRemote')} // Handle button click for filter
                    >
                      SNN da remoto
                    </Link>
                  </div>
              </div>
              <Grid container spacing={3}>
                <Grid item lg={4} md={12} xs={12}>
                  <div className='calendarBox pb-7 border-b border-gray-300'>
                    <CalanderDate
                        onChange={handleDateChange}
                        value={calanderDate}
                        showNeighboringMonth={false}
                        tileClassName={({ date, view }) => {

                          if (date.getDate() === 5 || date.getDate() === 12) {
                            return 'orange';
                          }
                          if (date.getDate() === 23) {
                            return 'green';
                          }
                          if (date.getDate() === 15) {
                            return 'purple';
                          }
                          return '';
                        }}
                      />
                  </div>
                  <div className='pt-7'>
                    <h3 className='text-lg font-semibold mb-5'><i class="fa-regular fa-gear mr-2"></i> Impostazioni</h3>
                    
                    <Link to="/agenda-calander" className='block w-full items-center mb-2 relative py-2 px-4 bg-[#828DEE]'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-white'>
                          <i class="fa-light fa-calendar-days"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-white'>Gestisci calendario orari</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/setting-visit" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-sharp fa-regular fa-gear"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Impostazioni visita</h2>
                        </div>
                      </div>
                    </Link>
                    <Link to="/services-list-modify" className='block w-full items-center mb-2 relative py-2 px-4'>
                      <div className='flex gap-5 items-center'>
                        <div className='text-4xl text-[#828DEE]'>
                          <i class="fa-solid fa-list-ul"></i>
                        </div>
                        <div>
                          <h2 className=' text-lg font-semibold mb-1 text-[#828DEE]'>Lista prestazioni</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={8} md={12} xs={12} className='lg:text-right'>
                  <div className='mt-10'>
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div className='cal calendarVisit'>

                      <Calendar
                          localizer={localizer}
                          events={events} // Use dynamic events from API
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: 600 }}
                          defaultView="week" // Default view is week
                          views={['week', 'day', 'agenda']} // Include multiple views
                          selectable // Enable slot selection
                          eventPropGetter={eventPropGetter} // Custom event color logic
                          onSelectEvent={handleEventClick} // Event click handler
                          onSelectSlot={handleSlotSelect} // Slot select handler for empty time slots
                          formats={{
                            timeGutterFormat: 'HH:mm', // Show times in 24-hour format
                            dayFormat: (date, culture, localizer) =>
                              `${localizer.format(date, 'ddd', culture)} ${localizer.format(date, 'D', culture)}`, // Format for day names
                            eventTimeRangeFormat: ({ start, end }, culture, localizer) => // Show event time range in 24-hour format
                              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                          }}
                          components={{
                            toolbar: CustomToolbar,  // Use custom toolbar here
                          }}
                          length={7} // Show 7 days in the agenda view
                          agendaLength={7} // Limit agenda view to show 7 days
                          //defaultDate={startOfWeek} // Show the selected week 
                          defaultDate={calanderDate}
                          step={30} // Time slots of 30 minutes
                          timeslots={2} // 2 timeslots per hour (e.g., 30 minutes each)
                          firstDay={0} // Start week from Monday (0 = Sunday, 1 = Monday)
                          getNow={() => moment().toDate()} // Make sure the current date is used as the starting point
                        />

                        {/* <Calendar
                          localizer={localizer}
                          events={events} 
                          startAccessor="start"
                          endAccessor="end"
                          style={{ height: 600 }}
                          defaultView="week" 
                          views={['week', 'day', 'agenda']} 
                          selectable 
                          eventPropGetter={eventPropGetter} 
                          onSelectEvent={handleEventClick} 
                          onSelectSlot={handleSlotSelect} 
                          formats={{
                            timeGutterFormat: 'HH:mm',
                            dayFormat: (date, culture, localizer) =>
                              `${localizer.format(date, 'ddd', culture)} ${localizer.format(date, 'D', culture)}`, 
                            eventTimeRangeFormat: ({ start, end }, culture, localizer) => 
                              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
                          }}
                          components={{
                            toolbar: CustomToolbar,  
                          }}
                          length={7}
                          agendaLength={7} 
                          defaultDate={new Date()} 
                          step={30} 
                          timeslots={2} 
                          firstDay={0} 
                          getNow={() => moment().toDate()} 
                        /> */}

                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <button
          className='fixed right-3 bottom-3 w-12 h-12 bg-[#828DEE] rounded-full text-3xl text-white shadow-xl'
          onClick={handleAddNewSlotClick}  // Add this onClick
        >
          <i class="fa-solid fa-plus"></i>
        </button>



        {/* Modal to show selected time slot or event details */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal md:w-[420px] w-[90%] bg-[#F8FFFC] p-6 rounded-2xl">
            {formError && (
              <div className="mb-4 text-red-500 text-center">
                {formError}
              </div>
            )}

            {(selectedSlot || selectedEvent) && (
              <>
                <div className='flex gap-2 justify-between mb-6'>
                  <div>
                    <button type='button' onClick={handleCloseModal} className='underline text-[#828DEE]'>Annulla</button>
                  </div>
                  <div>
                    <button
                      type='button'
                      onClick={handleSaveWithSwal}  // Trigger Swal with options
                      className='underline text-[#828DEE]'
                      disabled={loadingSave}
                    >
                      {loadingSave ? 'Saving...' : 'Salva'}
                    </button>

                  </div>
                </div>

                <h2 className='text-center font-semibold text-2xl mb-4'>
                  {selectedEvent
                    ? `${selectedEvent.weekName} ${moment(selectedEvent.selectedDate).format('Do MMM YYYY')}`
                    : selectedSlot
                      ? `${selectedSlot.day} ${moment(selectedSlot.selectedDate).format('Do MMM YYYY')}`
                      : 'Create Event'}
                </h2>


                {/* Conditionally render the dropdown if day is not pre-selected */}
                {!selectedSlot?.day && !selectedEvent?.weekName && (
                  <div className='mb-3'>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="selectDay-label">Select Day</InputLabel>
                      <Select
                        labelId="selectDay-label"
                        value={selectedSlot?.day || ''}
                        onChange={(e) => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, day: e.target.value });
                          }
                        }}
                      >
                        <MenuItem value="Monday">Monday</MenuItem>
                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                        <MenuItem value="Thursday">Thursday</MenuItem>
                        <MenuItem value="Friday">Friday</MenuItem>
                        <MenuItem value="Saturday">Saturday</MenuItem>
                        <MenuItem value="Sunday">Sunday</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}

                {/* Time Slot selection */}
                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>dalle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.start) || (selectedEvent?.slotStart) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, start: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotStart: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='flex gap-4 items-center justify-center mb-3'>
                  <div className='text-right min-w-10'>alle</div>
                  <div className='w-20'>
                    <TextField
                      type='time'
                      variant="standard"
                      className='w-full ps-20'
                      value={(selectedSlot?.end) || (selectedEvent?.slotEnd) || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, end: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, slotEnd: e.target.value });
                        }
                      }}
                    />
                  </div>
                </div>

                {/* Visit Time Slot */}
                <div className='mb-3'>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="selectVisit-label">Slot visite da</InputLabel>
                    <Select
                      labelId="selectVisit-label"
                      value={selectedEvent?.timeSlot || selectedSlot?.timeSlot || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, timeSlot: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, timeSlot: e.target.value });
                        }
                      }}
                    >
                      <MenuItem value="min10">10 Min</MenuItem>
                      <MenuItem value="min15">15 Min</MenuItem>
                      <MenuItem value="min30">30 Min</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* SSN Toggle */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    SSN
                    <Switch
                      checked={selectedEvent?.isSSN || selectedSlot?.isSSN || false}
                      onChange={() => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isSSN: !selectedSlot.isSSN });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isSSN: !selectedEvent.isSSN });
                        }
                      }}
                    />
                  </label>
                  {(selectedEvent?.isSSN || selectedSlot?.isSSN) && (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.ssnClinic || selectedSlot?.ssnClinic || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, ssnClinic: !selectedSlot.ssnClinic });
                          } else {
                            setSelectedEvent({ ...selectedEvent, ssnClinic: !selectedEvent.ssnClinic });
                          }
                        }} />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.ssnRemote || selectedSlot?.ssnRemote || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, ssnRemote: !selectedSlot.ssnRemote });
                          } else {
                            setSelectedEvent({ ...selectedEvent, ssnRemote: !selectedEvent.ssnRemote });
                          }
                        }} />}
                        label="Da remoto"
                      />
                    </div>
                  )}
                </div>

                {/* Private Toggle */}
                <div className='mb-3 shadow-lg p-3 rounded'>
                  <label className="flex justify-between font-semibold items-center">
                    Privato
                    <Switch
                      checked={selectedEvent?.isPrivate || selectedSlot?.isPrivate || false}
                      onChange={() => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, isPrivate: !selectedSlot.isPrivate });
                        } else {
                          setSelectedEvent({ ...selectedEvent, isPrivate: !selectedEvent.isPrivate });
                        }
                      }}
                    />
                  </label>
                  {(selectedEvent?.isPrivate || selectedSlot?.isPrivate) && (
                    <div className="flex gap-3">
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.privateClinic || selectedSlot?.privateClinic || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, privateClinic: !selectedSlot.privateClinic });
                          } else {
                            setSelectedEvent({ ...selectedEvent, privateClinic: !selectedEvent.privateClinic });
                          }
                        }} />}
                        label="In studio"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={selectedEvent?.privateRemote || selectedSlot?.privateRemote || false} onChange={() => {
                          if (selectedSlot) {
                            setSelectedSlot({ ...selectedSlot, privateRemote: !selectedSlot.privateRemote });
                          } else {
                            setSelectedEvent({ ...selectedEvent, privateRemote: !selectedEvent.privateRemote });
                          }
                        }} />}
                        label="Da remoto"
                      />
                    </div>
                  )}
                </div>

                {/* Studio Address selection */}
                {(selectedEvent?.ssnClinic || selectedEvent?.privateClinic || selectedSlot?.ssnClinic || selectedSlot?.privateClinic) && (
                  <FormControl fullWidth>
                    <InputLabel id="selectAddress-label">Studio di riferimento</InputLabel>
                    <Select
                      labelId="selectAddress-label"
                      value={selectedEvent?.selectedAddress || selectedSlot?.selectedAddress || ''}
                      onChange={(e) => {
                        if (selectedSlot) {
                          setSelectedSlot({ ...selectedSlot, selectedAddress: e.target.value });
                        } else {
                          setSelectedEvent({ ...selectedEvent, selectedAddress: e.target.value });
                        }
                      }}
                    >
                      {addresses.length > 0 ? (
                        addresses.map((studio) => (
                          <MenuItem key={studio.id} value={studio.id}>
                            {studio.studio} - {studio.street}, {studio.province}, {studio.zip}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No Studio Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}

                {selectedEvent?.id && (
                  <div className='mt-8 text-center'>
                    <button onClick={handleDeleteWithSwal} className='text-red-500 underline font-semibold'>Elimina Orario</button>
                  </div>
                )}
              </>
            )}

          </Box>
        </Modal>
      </div>
    </>
  );
}
