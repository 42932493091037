import { Grid } from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'
import DashboardNav from '../Dashboard/DashboardNav'

export default function Communicationcenter() {
  return (
    <>
    <div >
        <Grid container columns={12} spacing={2}>
            <Grid item md={9} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <div >
                    <div className='flex gap-3 mb-6 font-semibold'>
                        <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link> 
                        <span>/</span>
                        <span>Centro comunicazioni</span>
                    </div>
                    <Grid container spacing={3} className='justify-center'>
                        <Grid item lg={9} md={12} xs={12}>
                            <p className='text-right mb-8'><Link to="#" className="underline text-[#aab5ff]">Segna tutte come lette</Link></p>
                            <div className='bg-white mb-4 pt-4 pb-2 px-6 text-lg transition-all hover:bg-[#F8FFFC] shadow-lg rounded-lg relative'>
                                <span class="w-4 h-4 bg-red-500 inline-block rounded-full absolute top-[-5px] right-[-5px]"></span>
                                <div class="flex gap-3 items-top justify-between ">
                                    <div className='flex gap-5 items-center'>
                                        <div>
                                            <img src='assets/images/sos-colored.png' className='aspect-square w-14'/>
                                        </div>
                                        <div>
                                            <h4 className='font-semibold mb-1 text-xl'>Attiva SOS</h4>
                                            <p className='text-base'>Attiva il sistema SOS</p>
                                        </div>
                                    </div>
                                    <div className='text-sm'>
                                        10 min fa
                                    </div>
                                </div>
                                <div className='text-right'>
                                    <Link to="#" class="text-sm underline italic">Vedi altro <i class="fa-solid fa-angle-right"></i></Link>
                                </div>
                            </div>
                            <div className='bg-white mb-4 pt-4 pb-2 px-6 text-lg transition-all hover:bg-[#F8FFFC] shadow-lg rounded-lg relative'>
                                <div class="flex gap-3 items-top justify-between ">
                                    <div className='flex gap-5 items-center'>
                                        <div>
                                            <img src='assets/images/campaign.png' className='aspect-square w-14'/>
                                        </div>
                                        <div>
                                            <h4 className='font-semibold mb-1 text-xl'>Comunicazione dal tuo pediatra</h4>
                                            <p className='text-base'>Scopri di cosa si tratta</p>
                                        </div>
                                    </div>
                                    <div className='text-sm'>
                                        10 min fa
                                    </div>
                                </div>
                                <div className='text-right'>
                                    <Link to="#" class="text-sm underline italic">Vedi altro <i class="fa-solid fa-angle-right"></i></Link>
                                </div>
                            </div>
                            <div className='bg-white mb-4 pt-4 pb-2 px-6 text-lg transition-all hover:bg-[#F8FFFC] shadow-lg rounded-lg relative'>
                                <div class="flex gap-3 items-top justify-between ">
                                    <div className='flex gap-5 items-center'>
                                        <div>
                                            <img src='assets/images/campaign.png' className='aspect-square w-14'/>
                                        </div>
                                        <div>
                                            <h4 className='font-semibold mb-1 text-xl'>Comunicazione dal tuo pediatra</h4>
                                            <p className='text-base'>Scopri di cosa si tratta</p>
                                        </div>
                                    </div>
                                    <div className='text-sm'>
                                        10 min fa
                                    </div>
                                </div>
                                <div className='text-right'>
                                    <Link to="#" class="text-sm underline italic">Vedi altro <i class="fa-solid fa-angle-right"></i></Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            
        </Grid>
    </div>
    </>
  )
}
