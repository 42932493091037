import { Grid } from '@mui/material';
import React from 'react'

export default function CommunityList() {
    const communityAreas = [
        { title: 'Come affrtonare il caldo', content: 'Bere, dormire, mangiare, correre giocare....', image: 'assets/images/aboutbg.jpg',},
        { title: 'I primi ricordi', content: 'Salva foto e video dei suoi primi passi, le prime parole e tutti i ricordi a te cari', image: 'assets/images/comm-01.png'},
        { title: 'Come affrtonare il caldo', content: 'Bere, dormire, mangiare, correre giocare....', image: 'assets/images/aboutbg.jpg',},
    ]
    const communityAreasList = communityAreas.map(communityArea =>
        <Grid item lg={4} md={4} xs={12}>
            <div className="rounded-xl shadow-xl h-full hover:shadow-2xl transition hover:-translate-y-2 bg-white overflow-hidden">
                <div className='sm:flex items-center'>
                    <div className="bg-gray-300 rounded-xl storiesbox sm:w-44 w-full min-w-44 h-full">
                        <a href='#' className='block h-full '><img src={communityArea.image} className='sm:w-44 w-full h-44 mx-auto object-cover'/></a>
                    </div>
                    <div className='md:p-4 p-3'>
                        <h4 className="card-title text-xl font-semibold mb-2"><a href='#'>{communityArea.title}</a></h4>
                        <p className='text-gray-600 text-[15px]'>{communityArea.content}</p>
                        <a href='#' className='text-sm mt-4 inline-block text-theme-primary'>Read More <i class="fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </Grid>
    );
  return (
    <>
    <Grid container columns={12} spacing={3} className='h-full'>
        {communityAreasList}
    </Grid>
        

    </>
  )
}
