import { Checkbox, FormControl, Grid, InputAdornment, OutlinedInput, TextField, CircularProgress } from '@mui/material'
import { Container, Button } from 'react-bootstrap'
import PaginationComponent from '../../utils/pagination'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardNav from '../Dashboard/DashboardNav'
import { FaTrash, } from 'react-icons/fa'
import axios from 'axios'
import { Constants } from '../../endPoints'
import { useUser } from '../../hooks/useUserContext'
import useAuthStore from '../../hooks/useAuthStore';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const MySwal = withReactContent(Swal)

export default function CertificateList() {
    const navigate = useNavigate()
    const { user } = useUser()
    const { setToken, token, setId } = useAuthStore();
    const [certificates, setCertificates] = useState([])
    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCertificates, setTotalCertificates] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [deleteTrigger, setDeleteTrigger] = useState(false)


    const fetchCertificateList = async (userId) => {
        setLoading(true)
        try {
            const response = await axios.get(`${Constants.UsercertificateList}?page=${currentPage}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Accessed-By': 'Generic'

                }
            });
            const { data } = response.data;
            console.log(data)
            setCertificates(data.items || []);
            setTotalPages(data.totalPages)
            setItemsPerPage(data && data.data.pageSize || 10)
            setCurrentPage(data && data.data.currentPage || 1)

        } catch (error) {
            console.error('Error fetching pediatrician details:', error);
            setApiError('Failed to retrieve pediatrician details.');
        } finally {
            setLoading(false)
        }
    }

    const handlePageChange = (e) => {
        const pageNumber = Number(e.target.innerText)
        if (!loading) {
            setCurrentPage(pageNumber);
        }
    };


    const handleDeleteCertificate = async (certId) => {
        try {
            const response = await fetch(`${Constants.deleteCertificate}/${certId}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `Bearer ${token}`,
                    'X-Accessed-By': 'Admin',
                }),
            })
            if (response.ok) {
                // Remove the deleted user from the local state
                setCertificates(certificates.filter((cert) => cert.id !== certId))
                setDeleteTrigger((prev) => !prev)
                MySwal.fire({
                    icon: 'success',
                    title: 'User Deleted!',
                    text: 'The user has been successfully deleted.',
                })
            } else {
                console.error('Failed to delete user')
            }
        } catch (error) {
            console.error('Error deleting user:', error)
        }
    }

    useEffect(() => {
        // console.log("user",user)
        fetchCertificateList(user.id)

    }, [deleteTrigger, currentPage])

    return (
        <>
            <div className='flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs'>
                <Link to="/"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo </Link>
                <span>/</span>
                <Link to="/">Gestione studio medico</Link>
                <span>/</span>
                <span>Lista certificati</span>
            </div>

            <div className='mt-10 text-center'>
                <div className=' mb-8 '>
                    <h2><span className='inline-block border-4 text-4xl w-14 h-14 py-1 border-black rounded-lg'><i class="fa-solid fa-solid fa-bars-sort"></i></span></h2>
                    <p className='mt-8 text-base'>Inserisci la tipologia di certificati.</p>
                    <button className='text-sm text-[#828DEE] underline' onClick={(e) => {
                        navigate('/certificate/requests')
                    }} >Modifica</button>
                </div>
                <div class="text-center mt-6 mb-2">
                    <button class="font-semibold text-gray-500 rounded-lg border border-gray-400 border-dashed w-full py-3" onClick={(e) => {

                        e.preventDefault()
                        navigate('/add-certificate')

                    }}>+ Aggiungi certificato</button>
                </div>
                <div className='overflow-x-auto'>
                    <table className='w-full lg:text-base text-sm tablecustom'>
                        <thead className='bg-[#FFC885]'>
                            <tr>
                                <th className='text-left'>Nome del certificato</th>
                                <th>Tipologia</th>
                                <th>Costo</th>
                                <th>Type</th>
                                <th>Abilitato</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <CircularProgress /> : <>
                                    {
                                        certificates.length === 0 && <tr><td colSpan="5" className='text-center'>No certificates found</td></tr>}
                                    {
                                        certificates.map((certificate, index) => (
                                            <tr>
                                                <td className='text-left font-semibold' onClick={(e) => {
                                                    navigate(`/view-certificate/${certificate.id}`)
                                                }} style={{ cursor: 'pointer' }}>{certificate.certificateName}</td>
                                                <td>{certificate.category}</td>
                                                <td>{certificate.cost ? certificate.cost : 0}</td>
                                                <td><Checkbox defaultChecked /></td>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        size="lg" // Change size to 'lg' to make it larger
                                                        style={{
                                                            backgroundColor: 'red',
                                                            borderColor: 'red',
                                                            padding: '8px 8px', // Add padding for larger button
                                                            fontSize: '16px', // Increase font size
                                                            width: '40px', // Adjust width for a larger button
                                                            height: '40px', // Adjust height for a larger button
                                                            margin: '5px'
                                                        }}
                                                        title="Delete Certificate"
                                                        onClick={() => {
                                                            MySwal.fire({
                                                                title: 'Are you sure?',
                                                                text: 'Once deleted, you will not be able to recover this user!',
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Yes, delete it!',
                                                                cancelButtonText: 'No, cancel!',
                                                                reverseButtons: true,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    handleDeleteCertificate(certificate.id)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <FaTrash style={{ fontSize: '18px' }} />
                                                    </Button>
                                                    <button className='text-sm text-[#828DEE] underline' onClick={(e) => {
                                                        navigate(`/add-certificate/${certificate.id}`)
                                                    }}>Modifica</button>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </>
                            }




                        </tbody>
                    </table>
                </div>
                <Container>
                    <div className="col-sm-12 col-md-5">
                        <div className="dataTables_length">
                            Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
                            {Math.min(currentPage * itemsPerPage, totalCertificates)} of {totalCertificates} entries
                        </div>
                    </div>
                    <div>


                    </div>

                    <div className="col-sm-12 col-md-7">
                        <Stack spacing={2}>
                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" color="primary" />
                        </Stack>

                    </div>
                </Container>


            </div>
        </>
    )
}