import React, { useState, useEffect, useContext } from 'react'
import { Grid, TextField, MenuItem, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { Constants } from '../../endPoints';



export default function ProfessionalDataForm({ formik, formData }) {

    const [regionalCodes, setRegionalCodes] = useState([])
    const [filteredAslOptions, setFilteredAslOptions] = useState([])
    const [selectedRegionalCode, setSelectedRegionalCode] = useState('')
    const [regionCurrentPage, setRegionCurrentPage] = useState(1);
    const [regionTotalPages, setRegionTotalPages] = useState(1);
    const [regionLoading, setRegionLoading] = useState(false);
    const [regionHasMore, setRegionHasMore] = useState(true);

    // For City dropdown
    const [cityOptions, setCityOptions] = useState([]);
    const [cityCurrentPage, setCityCurrentPage] = useState(1);
    const [cityTotalPages, setCityTotalPages] = useState(1);
    const [cityLoading, setCityLoading] = useState(false);
    const [cityHasMore, setCityHasMore] = useState(true);

    // For ASL dropdown
    const [aslOptions, setAslOptions] = useState([]);
    const [aslCurrentPage, setAslCurrentPage] = useState(1);
    const [aslTotalPages, setAslTotalPages] = useState(1);
    const [aslLoading, setAslLoading] = useState(false);
    const [aslHasMore, setAslHasMore] = useState(true);
    // fetch all remote fields for apis
    useEffect(() => {

        fetchRegionalCodes()
    }, [formData]); // Trigger every time formData changes


    const fetchRegionalCodes = async (page) => {
        if (regionLoading || page > regionTotalPages) return;
        setRegionLoading(true);
        try {
            const response = await fetch(`${Constants.regionList}?&page=1&limit=500`)
            if (response.ok) {
                const data = await response.json()
                setRegionalCodes((prevCodes) => [...prevCodes, ...data.data]);
                setRegionTotalPages(data.pagination.totalPages);
                setRegionCurrentPage(data.pagination.currentPage);
                setRegionHasMore(data.pagination.currentPage < data.pagination.totalPages);
            } else {
                console.error('Failed to Region ')
            }
        } catch (error) {
            console.error('Error in fetching Region:', error)
        }
        finally {
            setRegionLoading(false);
        }
    }

    // Handle region change
    const handleRegionChange = async (event, formik) => {
        const regionalCode = event.target.value;
        formik.setFieldValue('regionalCode', regionalCode);
        setAslOptions([]);  // Reset ASL options on region change
        setAslCurrentPage(1);  // Reset ASL pagination
        await fetchAslOptions(regionalCode, 1);  // Fetch ASL based on new region
    };

    const fetchCityOptions = async (regionalCode, page) => {
        if (cityLoading || page > cityTotalPages) return;
        setCityLoading(true);
        try {
            const response = await fetch(
                `${Constants.regionWiseCity}?idRegion=${regionalCode}&page=1&limit=50`,
            )
            if (response.ok) {
                const result = await response.json()
                setCityOptions((prevCities) => [...prevCities, ...result.cities]);
                // setCityTotalPages(result.pagination.totalPages);
                // setCityCurrentPage(result.pagination.currentPage);
                // setCityHasMore(result.pagination.currentPage < result.pagination.totalPages);
            } else {
                console.error('Failed to fetch City options')
                setCityOptions([])
            }
        } catch (error) {
            console.error('Error fetching City options:', error)
            setCityOptions([])
        }
        finally {
            setCityLoading(false);
        }
    }

    const handleScroll = (event, type) => {
        const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
        if (bottomReached) {
            if (type === 'region' && regionHasMore) {
                fetchRegionalCodes(regionCurrentPage + 1);
            } else if (type === 'city' && cityHasMore) {
                fetchCityOptions(formik.values.regionalCode, cityCurrentPage + 1);
            } else if (type === 'asl' && aslHasMore) {
                fetchAslOptions(formik.values.regionalCode, aslCurrentPage + 1);
            }
        }
    };

    const fetchAslOptions = async (regionalCode, page) => {
        if (aslLoading || page > aslTotalPages) return;
        setAslLoading(true);
        if (regionalCode) {
            try {
                const response = await fetch(
                    `${Constants.regionWiseASL}?idRegion=${regionalCode}&page=1&limit=50`,
                )
                if (response.ok) {
                    const result = await response.json()
                    setAslOptions((prevAsl) => [...prevAsl, ...result.aslList]);
                    setAslTotalPages(result.totalPages);
                    setAslCurrentPage(result.currentPage);
                    setAslHasMore(result.currentPage < result.totalPages);
                } else {
                    console.error('Failed to fetch ASL options')
                    setAslOptions([])
                }
            } catch (error) {
                console.error('Error fetching ASL options:', error)
                setAslOptions([])
            } finally {
                setAslLoading(false);
            }
        } else {
            setAslOptions([])
        }
    }

    return (
        <>
            {/* <div className='flex gap-3 mb-12 font-semibold'>
                                <Link to="/profile"><i class="fa-solid fa-angle-left mr-2"></i> Gestione profilo</Link>
                                <span>/</span>
                                <span>Dati Professionali</span>
                            </div> */}
                            <Grid container className='justify-center'>
                                <Grid item xs={12} sm={10} md={8}>
                                <div className='mb-5'>
                                        <TextField
                                        fullWidth
                                        id='registrationNumber'
                                        name='registrationNumber'
                                        label='N° inscrizione all’albo'
                                        variant='outlined'
                                        placeholder='N° inscrizione all’albo'
                                        value={formik.values.registrationNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
                                        helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
                                        InputProps={{ style: { height: '54px' } }}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <TextField
                                            select
                                            fullWidth
                                            id="regionalCode"
                                            name="regionalCode"
                                            label="Codice Regione di Convenzione (regionalCode)"
                                            variant="outlined"
                                            value={formik.values.regionalCode}
                                            onChange={(event) => handleRegionChange(event, formik)} // Fetch city and ASL on region change
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.regionalCode && Boolean(formik.errors.regionalCode)}
                                            InputProps={{ style: { height: '54px' } }}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                            overflowY: 'auto',
                                                        },
                                                        onScroll: (event) => handleScroll(event, 'region'),
                                                    },
                                                },
                                            }}
                                        >
                                            {regionalCodes.map((region, index) => (
                                                <MenuItem key={index} value={region.code}>
                                                    {region.denomination}
                                                </MenuItem>
                                            ))}
                                            {regionLoading && (
                                                <MenuItem disabled>
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </div>
                                    
                                    <div className='mb-5'>
                                        <TextField
                                            select
                                            label="Codice ASL di convenzione"
                                            name='aslAgreementCode'
                                            value={formik.values.aslAgreementCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                            className='w-full rounded-lg'
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                            overflowY: 'auto',
                                                        },
                                                        onScroll: (event) => handleScroll(event, 'asl'),
                                                    },
                                                },
                                            }}
                                        >
                                            {aslOptions.map((asl, index) => (
                                                <MenuItem key={index} value={asl.code}>
                                                    {asl.denomination}
                                                </MenuItem>
                                            ))}
                                            {aslLoading && (
                                                <MenuItem disabled>
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </div>
                                    <div className='mb-5'>
                                        <TextField label="P. IVA"
                                            variant="outlined"
                                            name='vatNumber'
                                            value={formik.values.vatNumber}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.vatNumber && Boolean(formik.errors.vatNumber)}
                                            helperText={formik.touched.vatNumber && formik.errors.vatNumber}
                                            InputProps={{ style: { height: '54px' } }}

                                            className='w-full rounded-lg' />
                                    </div>
                                    <div className='mb-5'>
                                        <TextField
                                            fullWidth
                                            id='sdi'
                                            name='sdi'
                                            label='SDI'
                                            variant='outlined'
                                            value={formik.values.sdi}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.sdi && Boolean(formik.errors.sdi)}
                                            helperText={formik.touched.sdi && formik.errors.sdi}
                                            InputProps={{ style: { height: '54px' } }}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <TextField
                                            fullWidth
                                            id='pec'
                                            name='pec'
                                            label='PEC'
                                            variant='outlined'
                                            value={formik.values.pec}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.pec && Boolean(formik.errors.pec)}
                                            helperText={formik.touched.pec && formik.errors.pec}
                                            InputProps={{ style: { height: '54px' } }}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <TextField
                                            fullWidth
                                            id='taxDomicile'
                                            name='taxDomicile'
                                            label='Domicilio Fiscale'
                                            variant='outlined'
                                            value={formik.values.taxDomicile}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.taxDomicile && Boolean(formik.errors.taxDomicile)}
                                            helperText={formik.touched.taxDomicile && formik.errors.taxDomicile}
                                            InputProps={{ style: { height: '54px' } }}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <TextField
                                            fullWidth
                                            id='iban'
                                            name='iban'
                                            label='IBAN'
                                            variant='outlined'
                                            value={formik.values.iban}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.iban && Boolean(formik.errors.iban)}
                                            helperText={formik.touched.iban && formik.errors.iban}
                                            InputProps={{ style: { height: '54px' } }}
                                        />
                                    </div>

                                </Grid>

                            </Grid>
        </>
    )
}