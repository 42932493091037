import React, { useState, useRef } from 'react'
import { Grid, IconButton,  CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Constants } from '../../endPoints';
import axios from 'axios'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useAuthStore from "../../hooks/useAuthStore";

export const TwoFactorAuth = ({ handleNext, meta, setMeta }) => {
    // Using Formik to handle the form state and validation
    const [apiError, setApiError] = useState(''); // State to hold API error

    const ValidateMobile = async (phoneNumber, id) => {
        try {
            const body = {
                phone: phoneNumber,
                userId: id
            }
            const response = await axios.post(Constants.phoneVerificationorPediatrician, body)
            if (response.status === 200) {
                return true; // Mobile number is valid
            } else {
                return false; // Mobile number is invalid
            }
        } catch (error) {
            console.log(error)
            return false; // Mobile number is invalid
        }
    }

    const formik = useFormik({
        initialValues: {
            phoneNumber: '',
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .required('Il numero di telefono è richiesto')
                .matches(/^\+?\d{1,4}?\s?\d{1,4}?\s?\d{1,9}$/, 'Numero di telefono non valido')
                .test('validate-mobile', 'Numero di telefono non valido', async function (value) {
                    // Call the API for mobile validation
                    if (value) {
                        const isValid = await ValidateMobile(value, meta.id);
                        return isValid; // true if valid, false if not
                    }
                    return false; // Return false if no value is provided
                }),
        }),
        onSubmit: async (values) => {
            // console.log('Mobile Number Submitted:', values);
            await sendMobileOtp(values);
            handleNext();
        },
    });

    const sendMobileOtp = async (values) => {
        try {

            const body = {
                phone: values.phoneNumber,
                userId: meta.id
            }
            setMeta({ ...meta, phone: body.phone })

            const response = await axios.post(Constants.resendConfirmationMobile, body)
            if (response.status === 200) {
                console.log('-- mobile Otp sent ---')
            } 
        } catch (error) {
            console.error('Error in email OTP sent:', error)
            if (error.response && error.response.data) {
                console.log("Email is already registered")
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error occurred. Please try again later.')
            }
        }

    }

    return (
        <Grid item xl={6} lg={7} md={8} xs={12}>
            <h2 className="mb-8 text-3xl font-semibold text-center">
                Abilita l'autenticazione a due fattori
            </h2>
            <p className="text-center mb-5">Inserisci il numero di telefono</p>

            <form onSubmit={formik.handleSubmit}>
                <div className="mb-5">
                    <PhoneInput
                        country={'it'}  // Default to Italy, you can change the default country
                        value={formik.values.phoneNumber}
                        // onChange={(phone) => formik.setFieldValue('phoneNumber', `+${phone}`)}
                        onChange={(value, data, event, formattedValue) => {
                            const countryCode = data.dialCode;
                            const restNumber = value.substring(countryCode.length);
                            const formattedNumber = `+${countryCode} ${restNumber}`;
                            formik.setFieldValue('phoneNumber', formattedNumber);
                          }}
                        onBlur={formik.handleBlur}
                        inputProps={{
                            name: 'phoneNumber',
                            required: true,
                            autoFocus: true,
                            style: { height: '54px', width: '100%' }, // Adjust style to match your design
                        }}
                        placeholder="333 33 33 333"  // Italian phone number placeholder
                        containerClass="w-full"
                        inputClass={`w-full ${formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) ? 'error-class' : ''}`}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <div className="text-red-500">{formik.errors.phoneNumber}</div>
                    )}
                    <IconButton
                        aria-label="clear phone number"
                        onClick={() => formik.setFieldValue('phoneNumber', '')}
                        style={{ marginLeft: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className="mb-5">
                    <button
                        type="submit"
                        className="w-full h-[54px] bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                    >
                        Verifica numero
                    </button>
                </div>

            </form>
        </Grid>
    );
};


export const MobileOTP = ({ handleNext, meta, setMeta }) => {
    const { setToken, token,setId } = useAuthStore();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputRefs = useRef([]);
    const [apiError, setApiError] = useState(''); // State to hold API error
    // Validation schema for OTP input
    const validationSchema = Yup.object({
        otp: Yup.string()
            .length(6, 'Il codice OTP deve essere lungo 6 cifre')
            .required('Il codice OTP è richiesto'),
    });
    const otpFormik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log('Mobile OTP Submitted:', values);

            await confirmMobilOTP(values)
           
        },
    });

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Automatically focus on the next input field
        if (element.value !== "" && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        // Set the final OTP in Formik
        otpFormik.setFieldValue('otp', newOtp.join(""));
    };

    const handleBackspace = (event, index) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };



    const confirmMobilOTP = async ({ otp }) => {
        try {
            const response = await axios.post(Constants.VerifyPhoneOtpV2, {
                userId: meta.id,
                otp: otp
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            )
            if (response.status === 200) {
                console.log('Confirm OTP verified')
                setMeta({ ...meta, isPhoneVerified: true });
                handleNext(); // Call the handleNext function when OTP is submitted successfully
            } else {
                console.error('Confirm OTP verification failed')
            }
        } catch (error) {
            console.error('Confirm OTP sent ERROR :', error)
            if (error.response && error.response.data) {
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error Otp sent. Please try again later.')
            }
        }
    }

    const handleResendOtp = async () => {
        try {

            const body = {
                phone: meta.phone,
                userId: meta.id
            }
            const response = await axios.post(Constants.resendConfirmationMobile, body)
            if (response.status === 200) {
                console.log('-- mobile Otp sent ---')
            }
        } catch (error) {
            console.error('Error in email OTP sent:', error)
            if (error.response && error.response.data) {
                console.log(error.response.data.message)
                setApiError(error.response.data.message)
            } else {
                setApiError('An error occurred. Please try again later.')
            }
        }
    }


    return (
        <Grid item xl={6} lg={7} md={8} xs={12}>
            <h2 className="mb-8 text-3xl font-semibold text-center">
                Indirizzo email confermato!
            </h2>
            <form onSubmit={otpFormik.handleSubmit}>
                <div className="mb-5">
                    <div className="otp-inputs" style={{ display: "flex", gap: "8px", justifyContent: 'center' }}>
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className={`otp-box ${otpFormik.errors.otp && otpFormik.touched.otp ? 'error' : ''}`}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    textAlign: "center",
                                    fontSize: "24px",
                                    borderRadius: "8px",
                                    border: otpFormik.errors.otp && otpFormik.touched.otp ? "1px solid red" : "1px solid #ccc",
                                }}
                            />
                        ))}
                    </div>

                    {otpFormik.touched.otp && otpFormik.errors.otp ? (
                        <div style={{ color: "red", marginTop: "8px" }}>{otpFormik.errors.otp}</div>
                    ) : null}

                </div>
                <div className="mb-5">

                    <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>
                        Conferma
                    </button>
                    <a
                        href="#"
                        className="text-[#828DEE] underline text-sm hover:text-black transition-all"
                        style={{ display: 'block', textAlign: 'center', marginTop: '10px', cursor: 'pointer' }}
                        onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor behavior
                            handleResendOtp(); // Call the resend OTP function
                        }}
                    >
                        Non hai ricevuto l'SMS?
                    </a>

                    {apiError && <div className="text-red-500 text-sm m-3">{apiError}</div>}
                </div>
                {/* <div className="mb-5">
                    <div className="flex justify-center items-center h-full">
                        <button
                            type="submit"
                            className="w-[90px] h-[35px] bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                            onClick={(e) => { handleNext(); }}
                        >
                            Skip
                        </button>
                    </div>
                </div> */}
            </form>
        </Grid>
    );
};

