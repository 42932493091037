import { Grid } from '@mui/material'
import React from 'react'

export default function Homebanner() {
  return (
    <>
    <section className='cover' style={{backgroundImage : 'url(assets/images/banner-bg.png)'} }>
        <div className='relative sm:pt-48 pt-32 sm:pb-40 pb-20 bg-no-repeat bg-right-bottom' style={{backgroundImage : 'url(assets/images/banner.png)', backgroundSize: '60%'} }>
            <div className='container mx-auto px-4 relative z-10'>
                <Grid container columns={12} spacing={4}>
                    <Grid item lg={5}>
                        <h1 className='md:text-6xl text-4xl mb-10 font-bold'>Building a Better
                        Future through</h1>
                        <p className='text-lg text-gray-600 mb-10'>At Kidocare, our mission is to ensure every child receives the best possible medical care, no matter where they are. We strive to empower parents with the tools and knowledge they need to make informed decisions about their child's health.</p>
                        <a href='#' className='font-bold bg-theme-primary p-4 inline-block px-10 rounded-full text-white hover:bg-green-600 transition '>Get Started</a>
                    </Grid>
                </Grid>
            </div>
            <img src='assets/images/kite.png' className='absolute left-32 bottom-2 animationkite hidden md:block'/>
            <img src='assets/images/banner-shadow.png' className='absolute bottom-0 w-full'/>
        </div>
    </section>
    </>
  )
}
