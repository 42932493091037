import React, { useEffect, useState } from 'react'
import DashboardNav from '../Dashboard/DashboardNav'
import { Box, Grid, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUserContext'; // Import your custom hook to access UserContext
import { Constants } from '../../endPoints';

export default function Payments() {
    const [stripeStep, setStripeStep] = useState(1);
    const { user } = useUser(); // Access the user info from the context
    const [stripeloading, setStripeloading] = useState(false);




    useEffect(() => {
        const { isStripeAccCreated, isStripeAccVerified } = user || { isStripeAccCreated: false, isStripeAccVerified: false };

        console.log('User:', user);
        console.log('Stripe Account:', isStripeAccCreated, isStripeAccVerified);

        if (!isStripeAccCreated) {
            // Stripe account is not created
            setStripeStep(1)
        }
        if (isStripeAccCreated && !isStripeAccVerified) {
            // Stripe account is created but not verified
            setStripeStep(2)
        }
        if (isStripeAccCreated && isStripeAccVerified) {
            // Stripe account is created and verified
            setStripeStep(3)
        }


    }, [user]);



    const handleOnboardClick = async () => {
        setStripeloading(true);
        try {
            const response = await axios.post(`${Constants.stripeAccOnboard}`, {
                accountId: user.stripeAccountId,
                refresh_url: "https://kiddocare.eu/payments",
                return_url: `https://kiddocare.eu/payments/success?success=true&pid=${user.id}&acc=${user.stripeAccountId}`,
            });
            if (response.status === 200) {
                console.log('Banking information updated successfully');
                window.open(response.data.data, '_self', 'noopener,noreferrer');
            }
        } catch (error) {
            setStripeloading(false);
            console.error('Error updating banking information:', error);
        }

    }

    return (
        <>
            <div className='flex gap-3 mb-6 font-semibold'>
                <Link to="/payments"><i class="fa-solid fa-angle-left mr-2"></i> Payments </Link>
            </div>
            <Grid container spacing={3} className='justify-center'>
                <Grid item lg={5} md={12} xs={12}>
                    <h2 className='text-2xl text-center font-semibold mb-8'>Payments</h2>
                    <div class="flex gap-4 justify-center mb-8">
                        {
                            stripeloading ? (<Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CircularProgress />
                            </Box>) : (
                                <>
                                    {
                                        stripeStep === 2 && (<><button
                                            onClick={handleOnboardClick}
                                            className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:from-indigo-500 hover:to-purple-600 hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-4 focus:ring-indigo-300"
                                        >
                                            Onboard Stripe
                                        </button></>)
                                    }

                                </>
                            )
                        }
                        {
                            stripeStep === 3 && (
                                <button
                                    className="bg-gradient-to-r from-green-500 to-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:from-green-600 hover:to-green-500 hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-4 focus:ring-green-300"
                                >
                                    Stripe Onboard Complete
                                </button>
                            )
                        }
                        {
                            stripeStep === 1 && (
                                <Link to="/banking-info" className="px-5 py-2 border border-gray-400 rounded-lg text-base md:min-w-40 text-center hover:bg-gray-600 hover:text-white transition-all">
                                        Connect to Stripe
                                    </Link>
                            )
                        }


                    </div>
                    <Box className="flex items-center py-5 border-b border-gray-300">
                        <div className='text-4xl min-w-8 mr-4 text-[#86D8B0]'>
                            <i class="fa-sharp fa-regular fa-circle-check"></i>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-between items-center'>
                                <p className='text-base uppercase text-sm'>Transaction ID:
                                    <span className='font-semibold'>PAY05JHU2024</span>
                                </p>
                                <span className='inline-block py-1 px-3 rounded-full bg-[#86D8B0] text-xs text-white'>Success</span>
                            </div>
                            <div className='flex justify-between items-center'>
                                <h3 className='text-xl font-semibold my-1'>$ 100 </h3>
                                <p className='text-sm mb-1'>19/07/2024</p>
                            </div>
                            <p className='text-base mb-1'>johndoe22@gmail.com</p>
                            <p className='text-base text-sm font-semibold'><span className='font-normal uppercase'><i class="fa-regular fa-credit-card"></i> Source:</span> **** **** 7896</p>
                        </div>
                    </Box>
                    <Box className="flex items-center py-5 border-b border-gray-300">
                        <div className='text-4xl min-w-8 mr-4 text-[#86D8B0]'>
                            <i class="fa-sharp fa-regular fa-circle-check"></i>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-between items-center'>
                                <p className='text-base uppercase text-sm'>Transaction ID:
                                    <span className='font-semibold'>PAY05JHU2024</span>
                                </p>
                                <span className='inline-block py-1 px-3 rounded-full bg-[#86D8B0] text-xs text-white'>Success</span>
                            </div>
                            <div className='flex justify-between items-center'>
                                <h3 className='text-xl font-semibold mb-1'>$ 100 </h3>
                                <p className='text-sm mb-1'>19/07/2024</p>
                            </div>
                            <p className='text-base mb-1'>johndoe22@gmail.com</p>
                            <p className='text-base text-sm font-semibold'><span className='font-normal uppercase'><i class="fa-regular fa-credit-card"></i> Source:</span> **** **** 7896</p>
                        </div>
                    </Box>
                    <Box className="flex items-center py-5 border-b border-gray-300">
                        <div className='text-4xl min-w-8 mr-4 text-red-400'>
                            <i class="fa-sharp fa-regular fa-circle-xmark"></i>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-between items-center'>
                                <p className='text-base uppercase text-sm'>Transaction ID:
                                    <span className='font-semibold'>PAY05JHU2024</span>
                                </p>
                                <span className='inline-block py-1 px-3 rounded-full bg-red-400 text-xs text-white'>Faild</span>
                            </div>
                            <div className='flex justify-between items-center'>
                                <h3 className='text-xl font-semibold mb-1'>$ 100 </h3>
                                <p className='text-sm mb-1'>19/07/2024</p>
                            </div>
                            <p className='text-base mb-1'>johndoe22@gmail.com</p>
                            <p className='text-base text-sm font-semibold'><span className='font-normal uppercase'><i class="fa-regular fa-credit-card"></i> Source:</span> **** **** 7896</p>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}