import { Box, Modal } from '@mui/material';
import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

export default function PatientCall() {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        setOpen(true);
      }, []);


  return ( 
    <>
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal md:w-[800px] w-[90%] bg-[#F8FFFC] md:py-8 md:px-16 p-6 rounded-2xl">
                <h2 className='md:text-2xl text-xl font-bold text-center mb-6'>🆘 RICHIESTA SOS 🚨</h2>
                <div className='text-center mb-6'>
                    <h3 className='text-2xl font-semibold mb-3'>Vomito e diarrea + disidratazione (non mangia, non beve)</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </p>
                </div>
                <div className='text-center mb-6'>
                    <p>Paziente</p>
                    <h3 className='text-2xl font-semibold'>Giovanni Bianchi</h3>
                    <p className='font-semibold'>età: 8 mesi</p>
                </div>
                <div className='text-center mb-6'>
                    <p>Richiesto da</p>
                    <p className='font-semibold'>Maria Bianchi</p>
                </div>
                <div className='text-center mb-6'>
                    <Link to="#" className="font-semibold text-red-500 underline"> Vedi cartella clinica</Link>
                </div>
                <div className='text-center mb-1'>
                    <Link to="#" className='inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-white text-base font-semibold bg-red-500 mb-3'>Vai alla chat</Link>
                </div>
                <div className='text-center mb-1'>
                    <Link to="/audio-call" className='inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-red-500 text-base font-semibold bg--red-500 mb-3'><i class="fa-regular fa-phone"></i> Chiama</Link>
                </div>
                <div className='text-center'>
                    <Link to="/video-call" className='inline-block min-w-52 py-2 px-4 border-2 border-red-500 rounded-full text-red-500 text-base font-semibold bg--red-500 mb-3'><i class="fa-regular fa-video"></i> Videochiamata</Link>
                </div>
            </Box>
        </Modal>
      </>
  )
}
