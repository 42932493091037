import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { Constants } from '../../endPoints'


import ReactQuill from 'react-quill';

const CertificateDetail = () => {
    const navigate = useNavigate();
    const [certificate, setCertificate] = useState({
        name: '',
        category: '',
        cost: '',
        categoryId: '',
        textToBody: '',
        enabledFlag: true,
    });
    const { certId } = useParams();
    const [loading, setLoading] = useState(true);

    const fetchCertificate = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${Constants.certificateList}/${certId}`);
            const certificate = response && response.data && response.data.data;
            setCertificate(certificate)
            console.log(response.data.data);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        fetchCertificate();
        console.log(certificate);
        setCertificate(certificate);

    }, []);

    return (
        <Grid container spacing={3} className='justify-center'>
            <Grid item lg={7} md={12} xs={12}>
                <h2 className='text-center mb-8 text-xl font-semibold'>Certificate Details</h2>

                <Grid container spacing={3} className='justify-center'>
                    <Grid item lg={9} md={12} sm={12} xs={12}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <div className='mb-5'>
                                    <Typography variant="h6">Category Type</Typography>
                                    <Typography>{certificate.category || "N/A"}</Typography>
                                </div>

                                <div className='mb-5'>
                                    <Typography variant="h6">Availability</Typography>
                                    <Typography>{(certificate.enabled) ? 'Yes' : 'No'}</Typography>
                                </div>

                                <div className='mb-5'>
                                    <Typography variant="h6">Certificate Name</Typography>
                                    <Typography>{certificate.certificateName || "N/A"}</Typography>
                                </div>

                                <div className='mb-5'>
                                    <Typography variant="h6">€ Cost</Typography>
                                    <Typography>{certificate.cost || "N/A"}</Typography>
                                </div>
                                <div className='mb-5'>
                                    <Typography variant="h6">Created on</Typography>
                                    <Typography>{certificate.issueDate || "N/A"}</Typography>
                                </div>
                                <div className='mb-5'>
                                    <Typography variant="h6">Issued by</Typography>
                                    <Typography>{certificate.creatorFirstName || "N/A"}</Typography>
                                </div>

                                <div className='mb-5'>
                                    <Typography variant="h6">Certificate Body</Typography>
                                    <div
                                        style={{
                                            border: '1px solid #ddd',
                                            padding: '15px',
                                            borderRadius: '8px',
                                            height: '200px',
                                            overflowY: 'auto'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: certificate.textToBody || "<p>No content available</p>" }}
                                    />
                                    <a href={`https://kiddo-care.s3.eu-central-1.amazonaws.com/${certificate.certificateContent}`} target="_blank" rel="noreferrer">View Certificate</a>

                                </div>

                                <div className='mb-5'>
                                    <button className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all' onClick={
                                        () => {
                                            navigate('/certificates')
                                        }
                                    }>
                                        Back to Certificates
                                    </button>
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CertificateDetail;
