import React, { useEffect, useState } from "react";
import { RemoteUser, LocalUser } from "agora-rtc-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";

const pediatricianDetails = JSON.parse(localStorage.getItem("user"));
const pediatricianName = pediatricianDetails?.firstName || "Pediatrician";

const VideoDisplay = ({
  user,
  isLocal,
  isFullscreen,
  videoTrack,
  micTrack,
  cameraOn,
  micOn,
  userName,
  time
}) => {
  const [timeLeft, setTimeLeft] = useState(time * 60); // 30 minutes in seconds

  // Countdown timer effect
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  // Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className={`relative ${isFullscreen ? "w-full h-full" : "w-36 h-24"} transition-all duration-500`}>
      <div className={`w-full h-full object-cover ${isLocal ? "rounded-md border-2 border-gray-300" : ""}`} >
        {isLocal ? (
          <LocalUser
            audioTrack={micTrack}
            videoTrack={videoTrack}
            cameraOn={cameraOn}
            micOn={micOn}
            className={`${isFullscreen ? "w-full h-full object-cover" : ""}`}
          >
            <div className="absolute bottom-0 right-2 px-2 py-1 text-sm font-semibold text-white bg-gray-800 bg-opacity-50 rounded">
              {pediatricianName}
              <FontAwesomeIcon icon={micOn ? faMicrophone : faMicrophoneSlash} className="ml-2" />
              <FontAwesomeIcon icon={cameraOn ? faVideo : faVideoSlash} className="ml-2" />
            </div>
          </LocalUser>
        ) : (
          <RemoteUser
            className={`${isFullscreen ? "w-full h-full object-cover" : ""}`}
            user={user} >

            <div className="absolute top-4 left-4 px-2 py-1 text-sm font-semibold text-white bg-gray-800 bg-opacity-50 rounded">
              {userName}
            </div>
            <div style={{ marginLeft: "48%" }} className={`absolute top-2 left-2 px-3 py-1 text-sm font-semibold rounded ${timeLeft <= 5 * 60 ? "text-red-500" : "text-white"} bg-gray-800 bg-opacity-50`}>
              {formatTime(timeLeft)}
            </div>
          </RemoteUser>
        )}
      </div>

      {!isLocal && (
        <div className="absolute top-4 left-4 px-2 py-1 text-sm font-semibold text-white bg-gray-800 bg-opacity-50 rounded">
          {userName}
        </div>
      )}

      {isLocal && (
        <div className="absolute bottom-0 right-2 px-2 py-1 text-sm font-semibold text-white bg-gray-800 bg-opacity-50 rounded">
          {pediatricianName}
          <FontAwesomeIcon icon={micOn ? faMicrophone : faMicrophoneSlash} className="ml-2" />
          <FontAwesomeIcon icon={cameraOn ? faVideo : faVideoSlash} className="ml-2" />
        </div>
      )}
    </div>
  );
};

export default VideoDisplay;
