import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'; // React Router imports
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; // Constants for endpoints


export default function SettingView() {
    const idPediatrician = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const [pgloading, setPgLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const [pediatricianDetail, setPediatricianDetail] = useState([]); // Dynamic availability list
    const [isSSNBookedAllowed, setIsSSNBookedAllowed] = useState(null);
    const [cancelBeforeHr, setCancelBeforeHr] = useState(null);


    useEffect(() => {
        const fetchPediatricianStatus = async () => {
            setPgLoading(true);

            try {
                const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
                const data = response.data.data;

                setPediatricianDetail(data);

                // Pre-select options based on the fetched data
                if (data.isSSNBookedAllowed) {
                    // Set isSSNBookedAllowed to "Yes" or "No" based on the value received
                    setIsSSNBookedAllowed(data.isSSNBookedAllowed === "Yes" ? "Yes" : "No");
                }
                if (data.cancelBeforeHr) {
                    setCancelBeforeHr(data.cancelBeforeHr);
                }

                // Redirect or set loading based on availability status
                if (data.isAvailabilityAdded !== undefined) {
                    data.isAvailabilityAdded ? navigate('/agenda-calander') : setPgLoading(false);
                } else {
                    setPgLoading(false);
                }
            } catch (error) {
                console.error("Error fetching pediatrician data:", error);
                setPgLoading(false);
            }
        };

        fetchPediatricianStatus();
    }, [idPediatrician, navigate]);

    console.log("pediatricianDetail ", pediatricianDetail);

    // Validation and Submission function
    const handleSubmit = async () => {
        if (isSSNBookedAllowed === null || cancelBeforeHr === null) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Per favore seleziona tutte le opzioni richieste.',
            });

            return;
        }

        const payload = {
            idPediatrician,
            isSSNBookedAllowed,
            cancelBeforeHr,
        };

        try {
            const response = await axios.put(
                `${Constants.pediatricianSetting}`,
                payload
            );
            Swal.fire({
                icon: 'success',
                title: 'Salvato!',
                text: 'Le impostazioni sono state aggiornate con successo.',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Si è verificato un errore durante il salvataggio. Per favore riprova.',
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching the data
    }

    if (pgloading) {
        return <div>Loading...</div>; // Show a pgloading message while fetching the data
    }



    return (
        <div className='2xl:container mx-auto'>
            <Grid container columns={12} spacing={2}>
                <Grid item md={12} sm={12} xs={12} order={{ md: 2 }} className='bg-[#FAFAFA]'>
                    <div>
                        <div className='flex gap-3 mb-6 font-semibold'>
                            <Link to="/calendar-visit"><i class="fa-solid fa-angle-left mr-2"></i> Calendario viste </Link>
                            <span>/</span>
                            <span>Impostazioni vista</span>
                        </div>
                        <div className='mt-10'>
                            <Grid container spacing={3} className='justify-center'>
                                <Grid item lg={5} md={12} xs={12}>
                                    <h2 className='text-center mb-8 text-2xl font-semibold'>Impostazioni vista</h2>
                                    <h3 className='text-center text-xl font-semibold mb-2'>Vuoi far prenotare ai tuoi pazienti anche le visite con il SSN?</h3>
                                    <p className='text-center text-sm mb-5'>Potrai modificare questa scelta anche successivamente</p>
                                    <div className='mb-14 flex gap-5 justify-center'>
                                        <div className='blockRadio'>
                                            <input
                                                type='radio'
                                                name="isSSNBookedAllowed"
                                                id='allowyes'
                                                checked={isSSNBookedAllowed === "Yes"}
                                                onChange={() => setIsSSNBookedAllowed("Yes")}
                                            />
                                            <label htmlFor='allowyes' className='border rounded-lg min-w-24 inline-block text-center py-3 px-4 border-gray-300 font-semibold'>SI</label>
                                        </div>
                                        <div className='blockRadio'>
                                            <input
                                                type='radio'
                                                name="isSSNBookedAllowed"
                                                id='allowno'
                                                checked={isSSNBookedAllowed === "No"}
                                                onChange={() => setIsSSNBookedAllowed("No")}
                                            />
                                            <label htmlFor='allowno' className='border rounded-lg min-w-24 inline-block text-center py-3 px-4 border-gray-300 font-semibold'>NO</label>
                                        </div>
                                    </div>

                                    <h3 className='text-center text-xl font-semibold mb-2'>Entro quanto i pazienti possono disdire la visita?</h3>
                                    <p className='text-center text-sm mb-5'>Potrai modificare questa scelta anche successivamente</p>
                                    <div className='mb-14 flex gap-2 justify-center'>
                                        {['0h', '12h', '24h', '48h'].map((hr) => (
                                            <div className='blockRadio' key={hr}>
                                                <input
                                                    type='radio'
                                                    name="cancelBeforeHr"
                                                    id={`hr${hr}`}
                                                    checked={cancelBeforeHr === hr}
                                                    onChange={() => setCancelBeforeHr(hr)}
                                                />
                                                <label htmlFor={`hr${hr}`} className='border rounded-lg inline-block text-center py-3 px-6 border-gray-300 font-semibold'>{hr}</label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='text-center'>
                                        <button
                                            onClick={handleSubmit}
                                            className="min-w-52 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                        >
                                            Salva
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
