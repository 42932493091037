import React from 'react'
import Slider from 'react-slick'


export default function PediatricianSlider() {
    const pediatricSlide = {
         dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 575,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
            }
        ]
    };
  return (
    <>
        <Slider {...pediatricSlide}>
            <div>
                <img src='assets/images/profile-dr1.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr2.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr3.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr4.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr2.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr2.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr3.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr4.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
            <div>
                <img src='assets/images/profile-dr2.jpg' className='mx-auto w-36 h-36 object-cover rounded-xl border shadow'/>
                <h2 className='text-center text-lg font-semibold mt-5'>Dr. Olivia Turner</h2>
                <h3 className='text-center text-sm text-gray-600 font-semibold mb-2'> MD, FAAP</h3>
            </div>
        </Slider>
    </>
  )
}
