import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { Constants } from '../../endPoints'
import ReactQuill from 'react-quill';
import useAuthStore from '../../hooks/useAuthStore';
import 'react-quill/dist/quill.snow.css';
import { Category } from '@mui/icons-material';

// Validation schema using Yup
const validationSchema = Yup.object({
    name: Yup.string().required('Certificate Name is required'),
    category: Yup.string().required('Category Type is required'),
    cost: Yup.number().required('Cost is required').min(0, 'Cost must be greater than or equal to 0'),
    certificate: Yup.string().required('Select Certificate is required'),
    subject: Yup.string().required('Subject is required'),
    certificateBody: Yup.string().required('Certificate Body is required'),
});


export default function AddCertificate() {
    const navigate = useNavigate()
    const { token } = useAuthStore();
    const [apiError, setApiError] = useState(null);
    const [masterCertificates, setMasterCertificates] = useState([]);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false); // Track if we are updating a certificate
    const [initialValues, setInitialValues] = useState(
        {
            name: '',
            category: '',
            cost: '',
            categoryId: '',
            textToBody: '',
            enabledFlag: true,
        }
    )
    // TAG Filtering and Suggestions Methods --------------------------------------------------
    // ---------------------------------------------------------------------------------------
    const [editorHtml, setEditorHtml] = useState('');
    const { certId } = useParams(); // Get the certificateId from the URL
    const [suggestions, setSuggestions] = useState([]);
    const [cursorPos, setCursorPos] = useState({ top: 0, left: 0 });
    const [Tags, setTags] = useState([]);
    const editorRef = useRef(null);

    const handleCertificateChange = (e, setFieldValue) => {
        const selectedId = e.target.value;
        const certificate = masterCertificates.find((cert) => cert.id === selectedId);

        setSelectedCertificate(certificate);

        if (certificate) {
            // Update the form fields based on the selected certificate
            setFieldValue('name', certificate.name);
            setFieldValue('category', certificate.type === 'Free' ? 'Free' : 'Paid');
            setFieldValue('cost', certificate.cost ? certificate.cost : '0');
            setFieldValue('textToBody', certificate.textToBody);
            setEditorHtml(certificate.textToBody);
        }
    };

    const fetchMasterCertificates = async (type = '') => {
        setCategoryLoading(true)
        try {
            const response = await axios.get(`${Constants.certificateCategories}?q=${type}`);
            const { data } = response;
            setMasterCertificates(data && data.data.categories || []);
        } catch (error) {
            console.error('Error fetching regions:', error);
            // Fallback to a default list of regions
            setMasterCertificates([]);
        } finally {
            setCategoryLoading(false)
        }

    }
    // Define the toolbar options
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };
    const queryTags = async (prefix) => {
        // API call for list of tags
        try {
            const response = await axios.get(`${Constants.certificateTags}`);
            const { data } = response.data;
            const tags = data && data.tags.map(tag => tag.name) || [];
            console.log("GET TAGS ", tags)
            setTags(tags);
        } catch (error) {
            console.error('Error fetching regions:', error);
            // Fallback to a default list of regions
            setTags([]);
        }
    };

    useEffect(() => {

        fetchMasterCertificates()
        queryTags()
    }, [])


    // for update Mode
    useEffect(() => {
        if (certId) {
            // If certId is present, we're in update mode
            setIsUpdate(true);
            const fetchCertificateDetails = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(`${Constants.certificateList}/${certId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'X-Accessed-By': 'Generic',
                        }
                    });
                    const certificateData = response && response.data && response.data.data;
                    console.log("Updating certificate", certificateData)

                    setInitialValues({
                        name: certificateData.certificateName,
                        category: certificateData.category,
                        cost: certificateData.cost || 0,
                        categoryId: certificateData.CategoryId,
                        textToBody: certificateData.textToBody,
                        enabledFlag: certificateData.enabled || true,
                    })
                    setEditorHtml(certificateData.textToBody)

                    console.log(initialValues)

                } catch (error) {
                    console.error('Error fetching certificate details:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchCertificateDetails();
        }
    }, [certId, token]);


    const handleTextChange = async (content) => {
        setEditorHtml(content);
        // Get the Quill editor instance
        const quill = editorRef.current.getEditor();
        const range = quill.getSelection(true); // Get current cursor position

        if (range) {
            // Get the plain text up to the cursor position
            const textBeforeCursor = quill.getText(0, range.index); // Get the raw text before the cursor position

            // Find all occurrences of @ followed by a word, including line breaks and spaces
            const allMatches = [...textBeforeCursor.matchAll(/@(\w*)/g)];

            if (allMatches.length > 0) {
                // Get the last match (the @tag closest to the cursor)
                const lastMatch = allMatches[allMatches.length - 1];

                // Get the prefix for the tag (excluding the @)
                const prefix = lastMatch[1];

                // Filter suggestions based on the prefix
                const foundTags = Tags.filter(tag => tag.startsWith(prefix));
                console.log(foundTags);

                // Show suggestions if we have any
                setSuggestions(foundTags.length > 0 ? foundTags : []);
            } else {
                // If no @tags are found, clear suggestions
                setSuggestions([]);
            }
        }
    };

    const handleQuillChange = async (content, delta, source, editor, setFieldValue) => {
        await handleTextChange(content);
        setFieldValue('textToBody', content)
        if (editor) {
            const range = editor.getSelection();
            if (range) {
                const bounds = editor.getBounds(range.index);
                setCursorPos({
                    top: bounds.bottom,
                    left: bounds.left
                });
            }
        }
    };
    // Handle the click on a suggestion
    const handleTagClick = (tag) => {
        const quill = editorRef.current.getEditor(); // Get the Quill editor instance

        // Get the current cursor position
        const range = quill.getSelection(true);
        if (range) {
            const textBeforeCursor = quill.getText(0, range.index); // Get text before the cursor

            // Find the index of the last '@' symbol
            const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');

            if (lastAtSymbolIndex !== -1) {
                // Delete the text from '@' to the current cursor position
                quill.deleteText(lastAtSymbolIndex, range.index - lastAtSymbolIndex);

                // Insert the selected tag at the position of '@'
                quill.insertText(lastAtSymbolIndex, `{${tag}}`);

                // Move the cursor to the right of the inserted tag
                quill.setSelection(lastAtSymbolIndex + tag.length + 2); // +2 accounts for the `{}` braces

                // Clear suggestions after selecting a tag
                setSuggestions([]);
            }
        }
    };

    const createUserCertificate = async (values) => {
        setLoading(true)
        try {
            if (isUpdate) {
                const response = await axios.put(`${Constants.certificateList}/${certId}`, values, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-accessed-by': 'Generic',
                    },
                });
                if (response.status === 201 || response.status === 200) {
                    navigate('/certificates')

                } else {
                    setApiError(response.data.message);
                }
            }
            else {
                const response = await axios.post(`${Constants.certificateList}`, values, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-accessed-by': 'Generic',
                    },
                });

                if (response.status === 201 || response.status === 200) {
                    navigate('/certificates')
                } else {
                    setApiError(response.data.message);
                }
                console.log("POST Certificate ", response.data)
            }


            fetchMasterCertificates()
        } catch (error) {
            console.error('Error fetching regions:', error);
            // Fallback to a default list of regions
            setMasterCertificates([]);
        } finally {
            setLoading(false)
        }
    }


    return (
        <>
            <div className='flex lg:gap-3 gap-1 mb-6 font-semibold lg:text-base text-xs'>
                <Link to="/"><i className="fa-solid fa-angle-left mr-2"></i> Gestione profilo </Link>
                <span>/</span>
                <Link to="/">Gestione studio medico</Link>
                <span>/</span>
                <span>Lista certificati</span>
            </div>
            <div className='mt-10'>
                <Grid container spacing={3} className='justify-center'>
                    <Grid item lg={7} md={12} xs={12}>
                        <h2 className='text-center mb-8 text-xl font-semibold'>Add Certificate</h2>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                        >
                            {({ handleChange, handleBlur, values, setFieldValue }) => (
                                <Form onSubmit={(e) => {
                                    e.preventDefault()
                                    setFieldValue('textToBody', editorHtml)
                                    createUserCertificate(values)


                                }}>
                                    <Grid container spacing={3} className='justify-center'>
                                        <Grid item lg={9} md={12} sm={12} xs={12}>
                                            <div className='mb-5'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="category-label">Category Type</InputLabel>
                                                    <Select
                                                        labelId="category-label"
                                                        id="category"
                                                        name="category"
                                                        value={values.category}
                                                        onChange={(e) => {
                                                            fetchMasterCertificates(e.target.value)
                                                            handleChange(e)
                                                            setFieldValue("name", "")
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value="Paid">Paid</MenuItem>
                                                        <MenuItem value="Free">Free</MenuItem>
                                                    </Select>
                                                    <ErrorMessage name="category" component="div" className="error-message" />
                                                </FormControl>
                                            </div>
                                            <div className='mb-5'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="certificate-label">Select Certificate</InputLabel>
                                                    <Select
                                                        labelId="certificate-label"
                                                        id="categoryId"
                                                        name="categoryId"
                                                        value={values.categoryId}
                                                        onChange={(e) => {
                                                            handleCertificateChange(e, setFieldValue)
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value="Select">Select</MenuItem>
                                                        {
                                                            categoryLoading || loading ? <CircularProgress /> :
                                                                masterCertificates.map((cert) => (
                                                                    <MenuItem key={cert.id} value={cert.id}>
                                                                        {cert.name}
                                                                    </MenuItem>
                                                                ))
                                                        }
                                                        {/* {masterCertificates.map((cert) => (
                                                                <MenuItem key={cert.id} value={cert.id}>
                                                                    {cert.name}
                                                                </MenuItem>
                                                            ))} */}
                                                    </Select>
                                                    <ErrorMessage name="certificate" component="div" className="error-message" />
                                                </FormControl>
                                            </div>
                                            <div className='mb-5'>
                                                <TextField
                                                    label="Certificate Name"
                                                    variant="outlined"
                                                    name="name"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                    helperText={<ErrorMessage name="name" />}
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                <TextField
                                                    label="€ Cost"
                                                    variant="outlined"
                                                    name="cost"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cost}
                                                    helperText={<ErrorMessage name="cost" />}
                                                />
                                            </div>

                                            <div className='mb-5'>
                                                {/* <TextField
                                                    label="Subject"
                                                    variant="outlined"
                                                    name="subject"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.subject}
                                                    helperText={<ErrorMessage name="subject" />}
                                                /> */}
                                            </div>

                                            <div className='mb-5' style={{ height: '30vh', position: 'relative' }}>

                                                <ReactQuill
                                                    id="textToBody"
                                                    name="textToBody"
                                                    value={editorHtml}
                                                    onChange={(content, delta, source, editor) => handleQuillChange(content, delta, source, editor, setFieldValue)}
                                                    placeholder='Write your certificate body here...'
                                                    modules={modules}
                                                    style={{ height: '70%', width: '100%' }} // Ensure Quill editor takes full height of container
                                                    ref={editorRef}
                                                />

                                            </div>
                                            <div>
                                                {suggestions.length > 0 && (
                                                    <ul style={{
                                                        listStyleType: 'none',
                                                        padding: 0,
                                                        position: 'absolute',
                                                        top: cursorPos.top,
                                                        left: cursorPos.left,
                                                        border: '1px solid #ddd',
                                                        backgroundColor: 'white',
                                                        zIndex: 1000
                                                    }}>
                                                        {suggestions.map(tag => (
                                                            <li
                                                                key={tag}
                                                                onClick={(e) => handleTagClick(tag)} // Handle click on each tag
                                                                style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #ddd' }}>{tag}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>


                                            <div className='mb-5'>
                                                {
                                                    loading ? <CircularProgress /> : <button type='submit' className='w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>
                                                        {isUpdate ? 'Update Certificate' : 'Add Certificate'}
                                                    </button>
                                                }
                                            </div>
                                            {apiError && <div className='text-red-500'>{apiError}</div>}
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}





