import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'
import { Grid } from '@mui/material'

export default function PediatricianRegister() {
  return (
    <>
    <Breadcumb pagetitle="Register as a Pediatrician "/>
    <section className='py-16'>
        <div className='container mx-auto px-4'>
            <Grid container columns={12} spacing={4} className='justify-center'>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                    <Grid container columns={12} spacing={0} className='shadow-2xl rounded-2xl overflow-hidden h-full'>
                      <Grid item lg={6} className='h-full bg-cover bg-center' style={{backgroundImage: 'url(../assets/images/log-dr-bg.jpg)'}}>
                      </Grid>
                      <Grid item lg={6} sm={12} xs={12}>
                        <div className='md:p-10 py-8 px-6'>
                          <div className='mb-8'><img src='assets/images/logo.png' className='h-10 mx-auto'/></div>
                          <h2 className='text-center mb-5 text-2xl font-semibold'>Register as a Pediatrician</h2>
                          <form>
                            <div className='mb-4'>
                              <label className='mb-1 block text-gray-700'>E-mail</label>
                              <input type='email' placeholder='E-mail' className='w-full border-2 border-gray-600 rounded-lg h-14 px-4'/>
                            </div>
                            <div className='mb-4'>
                              <label className='mb-1 block text-gray-700'>Password</label>
                              <input type='password' placeholder='Password' className='w-full border-2 border-gray-600  rounded-lg h-14 px-4'/>
                            </div>
                            <div className='mb-4'>
                              <label className='mb-1 block text-gray-700'>Confirm Password</label>
                              <input type='password' placeholder='Confirm Password' className='w-full border-2 border-gray-600  rounded-lg h-14 px-4'/>
                            </div>
                            <div className='mb-4'>
                                <label><input type='checkbox'/> I agree to <a href='#' className='text-theme-primary'> terms & conditions</a></label>
                            </div>
                            <div className='mb-4'>
                              <button className='w-full h-14 bg-theme-primary rounded-lg text-white hover:bg-green-600 transition-all'>Create Account</button>
                            </div>
                            
                            <div className='mb-4 text-center'>
                              Already have account? <a href='/login' className='text-theme-primary'>Login</a>
                            </div>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </section>  
    </>
  )
}
