import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Constants } from '../../endPoints'; 

export default function AdvanceSearch() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
    <div className='2xl:container mx-auto'>
        <Grid container columns={12} spacing={2}>
            <Grid item md={12} sm={12} xs={12} order={{md:2}} className='bg-[#FAFAFA]'>
                <div>
                    <Grid container spacing={3} className='justify-center'>
                        <Grid item lg={6} md={6} xs={6}>
                            <div className='flex gap-3 mb-6 font-semibold'>
                                <Link to="/patients"><i class="fa-solid fa-angle-left mr-2"></i> Ricerca pazienti </Link> 
                                <span>/</span>
                                <span>Ricerca avanzata</span>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} className='text-right'>
                            <button type='button'  onClick={handleOpen} className=' py-2 px-4 shadow-0 border-2 border-[#828DEE] rounded-full text-[#828DEE] text-base font-semibold hover:bg-[#828DEE] hover:text-white transition-all'>+ Aggiungi paziente</button>
                        </Grid>
                    </Grid>
                    <div className='text-center mt-6 mb-10'>
                        <h2 className='font-semibold text-2xl mt-6 mb-3'>Ricerca Avanzata</h2>
                        <p>Puoi compilare tutti i campi o solo parte di essi per trovare un paziente specifico o un gruppo di pazienti</p>
                    </div>
                    <Grid container spacing={5} className='justify-center'>
                        <Grid item lg={8} md={12}>
                            <form>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={12} xs={12}>
                                        <TextField label="Nome e/o Cognome" variant="outlined" className='w-full' />
                                    </Grid>
                                    <Grid item lg={6} md={12}  xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Age"
                                                >
                                                <MenuItem value="Year of birth">Anno di nascita</MenuItem>
                                                <MenuItem value="Age">Età</MenuItem>
                                                <MenuItem value="Age range">Range età</MenuItem>
                                                <MenuItem value="Range dates">Date dell'intervallo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={12}  xs={12}>
                                        <TextField label="Genitore" variant="outlined" className='w-full' />
                                    </Grid>
                                    <Grid item lg={6} md={12}  xs={12}>
                                        <TextField label="Numero Tessera Sanitaria" variant="outlined" className='w-full' />
                                    </Grid>
                                    <Grid item lg={6} md={12}  xs={12}>
                                        <TextField label="Cerca parola chiave" variant="outlined" className='w-full' />
                                    </Grid>
                                    <Grid item lg={6} md={12}  xs={12} className='flex gap-5 font-semibold'>
                                        <label><Checkbox /> SSN</label>
                                        <label><Checkbox /> Privato</label>
                                    </Grid>
                                </Grid>
                                <div className="text-center mt-10 mb-4">
                                    <label><Checkbox /> Salva ricerca</label>
                                </div>
                                <div className='flex justify-center'>
                                    <button class="block text-center leading-[54px] px-5 min-w-72 h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all">Aggiungi</button>
                                </div>
                                
                            </form>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </div>

    <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal md:w-[500px] w-[90%] bg-[#F8FFFC] md:p-14 p-6 rounded-2xl">
            <button type='button' className='absolute top-4 right-4 text-2xl' onClick={handleClose}><i class="fa-solid fa-circle-xmark"></i></button>
          <h2 className='md:text-2xl text-xl font-bold text-center mb-10'>Inserisci paziente</h2>
            <form>
                <div className='mb-5'>
                    <TextField label="Codice Fiscale" variant="outlined" className='w-full rounded-lg' />
                </div>
                <div className='text-center'>
                    <label><Checkbox /> Paziente SSN</label>
                </div>
                <div className='text-center mt-10'>
                    <button className='block text-center leading-[54px] w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all'>Aggiungi</button>
                </div>
            </form>
          
        </Box>
      </Modal> 
    </>
  )
}
