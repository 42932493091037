import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetSelectedStudios, resetSelectedDays, removeDay } from '../../redux/studioSlice';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Checkbox, TextField, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Constants } from '../../endPoints'; // Constants for endpoints
import Swal from 'sweetalert2'; // SweetAlert for alerts

export default function AddAvailability() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const idPediatrician = localStorage.getItem('userId');
    const [formInstances, setFormInstances] = useState([{
        timeSlots: [{ from: '', to: '' }],
        selectedAddress: '',
        visitTimeSlot: '', // New state for visit time slot
        ssnChecked: false,
        ssnClinic: false,
        ssnRemote: false,
        privateChecked: false,
        privateClinic: false,
        privateRemote: false,
    }]);

    const selectedStudios = useSelector((state) => state.studio.selectedStudios);
    const selectedDays = useSelector((state) => state.studio.selectedDays);
    const [studiosData, setStudiosData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Loader state
    const [pgloading, setPgLoading] = useState(false);

    // Fetch studio addresses
    const getStudios = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${Constants.pediatricianAddressList}${idPediatrician}`);
            if (response.ok) {
                const data = await response.json(); // Directly parse JSON
                if (data.status === 1) {
                    setStudiosData(Array.isArray(data.data) ? data.data : []); // Set fetched addresses
                } else {
                    console.error('Failed to fetch addresses:', data.message);
                }

                setLoading(false);
            } else {
                console.error('Failed to fetch addresses');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching addresses:', error);
            setLoading(false);
        }
    };


    useEffect(() => {
        getStudios();
        
        const fetchPediatricianStatus = async () => {
            setPgLoading(true); 
            try {
              const response = await axios.get(`${Constants.getPediatricianByID}/${idPediatrician}`);
        
              // Access the correct path to `isAvailabilityAdded`
              if (response.data && response.data.data.isAvailabilityAdded !== undefined) {
                if (response.data.data.isAvailabilityAdded==true) {
                  // If `isAvailabilityAdded` is true, redirect to the `agenda-calander` page
                  navigate('/agenda-calander');
                } else {
                  // If `isAvailabilityAdded` is false, stop loading and stay on the page
                  setPgLoading(false);
                }
              } else {
                setPgLoading(false);
              }
            } catch (error) {
              console.error("Error fetching pediatrician data:", error);
              setPgLoading(false);
            }
          };
        
          fetchPediatricianStatus();

    }, [idPediatrician, navigate]);


    const handleAddFormInstance = () => {
        setFormInstances([...formInstances, {
            timeSlots: [{ from: '', to: '' }],
            selectedAddress: null,
            visitTimeSlot: '',
            ssnChecked: false,
            ssnClinic: false,
            ssnRemote: false,
            privateChecked: false,
            privateClinic: false,
            privateRemote: false,
        }]);
    };

    const handleRemoveFormInstance = (index) => {
        const updatedForms = formInstances.filter((_, i) => i !== index);
        setFormInstances(updatedForms);
    };

    const handleAddTimeSlot = (formIndex) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, timeSlots: [...form.timeSlots, { from: '', to: '' }] } : form
        );
        setFormInstances(updatedForms);
    };

    const handleRemoveTimeSlot = (formIndex, timeSlotIndex) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, timeSlots: form.timeSlots.filter((_, tIndex) => tIndex !== timeSlotIndex) } : form
        );
        setFormInstances(updatedForms);
    };

    const handleTimeChange = (formIndex, timeSlotIndex, field, value) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex
                ? {
                    ...form,
                    timeSlots: form.timeSlots.map((slot, tIndex) =>
                        tIndex === timeSlotIndex ? { ...slot, [field]: value } : slot
                    ),
                }
                : form
        );
        setFormInstances(updatedForms);
    };

    // Handle SSN toggle for each form instance
    const handleSSNSwitchChange = (formIndex) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, ssnChecked: !form.ssnChecked } : form
        );
        setFormInstances(updatedForms);
    };

    // Handle SSN Clinic and Remote checkboxes
    const handleSSNCheckboxChange = (formIndex, field) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, [field]: !form[field] } : form
        );
        setFormInstances(updatedForms);
    };

    // Handle Private toggle
    const handlePrivateSwitchChange = (formIndex) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, privateChecked: !form.privateChecked } : form
        );
        setFormInstances(updatedForms);
    };

    // Handle Private Clinic and Remote checkboxes
    const handlePrivateCheckboxChange = (formIndex, field) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, [field]: !form[field] } : form
        );
        setFormInstances(updatedForms);
    };

    const handleAddressChange = (formIndex, value) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, selectedAddress: value } : form
        );
        setFormInstances(updatedForms);
    };

    const handleVisitTimeSlotChange = (formIndex, value) => {
        const updatedForms = formInstances.map((form, i) =>
            i === formIndex ? { ...form, visitTimeSlot: value } : form
        );
        setFormInstances(updatedForms);
    };

    const validateFormOLd = () => {
        for (let form of formInstances) {
            console.log("Form Data:", form);
    
            // Validate visit time slot
            if (!form.visitTimeSlot) {
                setError('Please select a visit time slot.');
                return false; // Stop validation and show error if any visit time slot is missing
            }
    
            // Validate time slots
            for (let slot of form.timeSlots) {
                if (!slot.from || !slot.to) {
                    setError('Please fill in both "From" and "To" times for all time slots.');
                    return false; // Stop validation and show error if any time slot is incomplete
                }
            }
    
            // Destructure necessary fields from form
            const { privateClinic, ssnClinic, privateRemote, ssnRemote, selectedAddress } = form;
    
            // If either "In the Clinic" is selected for SSN or Private, studio address is required
            if ((privateClinic || ssnClinic) && !selectedAddress) {
                setError('Please select a studio address if "In the Clinic" is selected for SSN or Private.');
                return false; // Stop validation and show error if studio is missing but required
            }
    
            // If neither "In the Clinic" nor "Remotely" options are selected, studio address is required
            if (!privateClinic && !ssnClinic && !privateRemote && !ssnRemote && !selectedAddress) {
                setError('Please select a studio address if neither "In the Clinic" nor "Remotely" is selected.');
                return false; // Stop validation and show error if studio is required
            }
        }
    
        // Clear the error if validation passes for all forms
        setError('');
        return true;
    };

    const validateForm = () => {
        for (let formIndex = 0; formIndex < formInstances.length; formIndex++) {
            const form = formInstances[formIndex];
            console.log("Form Data:", form);
    
            // Validate visit time slot
            if (!form.visitTimeSlot) {
                setError(`Form ${formIndex + 1}: Please select a visit time slot.`);
                return false; // Stop validation and show error if any visit time slot is missing
            }
    
            // Validate time slots
            for (let slot of form.timeSlots) {
                if (!slot.from || !slot.to) {
                    setError(`Form ${formIndex + 1}: Please fill in both "From" and "To" times for all time slots.`);
                    return false; // Stop validation and show error if any time slot is incomplete
                }
            }
    
            // Destructure necessary fields from form
            const { privateClinic, ssnClinic, privateRemote, ssnRemote, selectedAddress } = form;
    
            // If either "In the Clinic" is selected for SSN or Private, studio address is required
            if ((privateClinic || ssnClinic) && !selectedAddress) {
                setError(`Form ${formIndex + 1}: Please select a studio address if "In the Clinic" is selected for SSN or Private.`);
                return false; // Stop validation and show error if studio is missing but required
            }
    
            // If neither "In the Clinic" nor "Remotely" options are selected, studio address is required
            if (!privateClinic && !ssnClinic && !privateRemote && !ssnRemote && !selectedAddress) {
                setError(`Form ${formIndex + 1}: Please select a studio address if neither "In the Clinic" nor "Remotely" is selected.`);
                return false; // Stop validation and show error if studio is required
            }
        }
    
        // Clear the error if validation passes for all forms
        setError('');
        return true;
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Validate all the form instances
        const isValid = validateForm();
        
        if (!isValid) {
            return; // Stop submission if validation fails
        }
    
        setLoading(true); // Start loader
    
        const payload = {
            idPediatrician, // Replace with the actual idPediatrician from your app logic
            selectedDays, // Add selectedDays to the payload
            availabilityType: 'Weekly', // Specific Date
            languageId: 1, 
            availabilityData: formInstances.map((form) => ({
                slotInterval: form.visitTimeSlot,
                timeSlots: form.timeSlots,
                selectedAddress: form.selectedAddress, // Studio address or ID
                ssnChecked: form.ssnChecked,
                ssnClinic: form.ssnClinic,
                ssnRemote: form.ssnRemote,
                privateChecked: form.privateChecked,
                privateClinic: form.privateClinic,
                privateRemote: form.privateRemote,
            })),
        };
    
        try {
            // Submit the data to the API
            const response = await axios.post(`${Constants.addStudioAvailability}`, payload);
    
            if (response.data.status === 1) {
                Swal.fire({
                    icon: 'success',
                    title: 'Availability Saved!',
                    text: 'Your availability has been successfully saved.',
                });
                dispatch(resetSelectedStudios()); // Reset studios
                dispatch(resetSelectedDays()); // Reset days
                navigate('/view-calander');
            } else {
                setError(response.data.message || 'An error occurred while saving the availability.');
            }
        } catch (error) {
            setError('Error submitting form:', error.message);
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false); // Stop loader
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching the data
    }

    if (pgloading) {
        return <div>Loading...</div>; // Show a pgloading message while fetching the data
    }
    
    

    return (
        <div className="2xl:container mx-auto">
            <Grid container columns={12} spacing={2}>
                <Grid item md={12} sm={12} xs={12} className="bg-[#FAFAFA]">
                    <div>
                        <div className="flex gap-3 mb-6 font-semibold">
                            <Link to="/calendar-visit">
                                <i className="fa-solid fa-angle-left mr-2"></i> Calendario viste
                            </Link>
                            <span>/</span>
                            <Link to="/choose-availability">Set Weekly Availability</Link>
                            <span>/</span>
                            Set Availability
                        </div>

                        <div className="mt-10">
                            <Grid container spacing={3} className="justify-center">
                                <Grid item lg={6} md={12} xs={12}>
                                    <h2 className="text-center mb-8 text-xl font-semibold"> Add Availability</h2>

                                    <div className="mb-3">
                                        {selectedDays.map((day, index) => (
                                            <span
                                                key={index}
                                                className="inline-block py-1 px-3 border border-[#828DEE] text-base rounded mb-1 mr-1"
                                            >
                                                {day}
                                                <button className="ml-1 text-red-500" onClick={() => dispatch(removeDay(day))}>
                                                    <i className="fa-solid fa-circle-xmark"></i>
                                                </button>
                                            </span>
                                        ))}
                                    </div>

                                    {formInstances.map((form, formIndex) => (
                                        <div key={formIndex} className="mb-14 shadow-lg p-5 rounded">
                                            {/* Select Visit Time Slot */}
                                            <div className="mb-3">
                                                <FormControl fullWidth>
                                                    <InputLabel id={`selectVisit-label-${formIndex}`}>Select Visit Time Slot</InputLabel>
                                                    <Select
                                                        label="Select Visit Time Slot"
                                                        labelId={`selectVisit-label-${formIndex}`}
                                                        value={form.visitTimeSlot}
                                                        onChange={(e) => handleVisitTimeSlotChange(formIndex, e.target.value)}
                                                    >
                                                        <MenuItem value="min10">10 Min</MenuItem>
                                                        <MenuItem value="min15">15 Min</MenuItem>
                                                        <MenuItem value="min30">30 Min</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            {/* SSN toggle section */}
                                            <div className="mb-3 shadow-lg p-3 rounded">
                                                <label className="flex justify-between font-semibold items-center">
                                                    SSN
                                                    <Switch checked={form.ssnChecked} onChange={() => handleSSNSwitchChange(formIndex)} />
                                                </label>
                                                {form.ssnChecked && (
                                                    <div className="flex gap-3">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.ssnClinic} onChange={() => handleSSNCheckboxChange(formIndex, 'ssnClinic')} />}
                                                            label="In the Clinic"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.ssnRemote} onChange={() => handleSSNCheckboxChange(formIndex, 'ssnRemote')} />}
                                                            label="Remotely"
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            {/* Private toggle section */}
                                            <div className="mb-3 shadow-lg p-3 rounded">
                                                <label className="flex justify-between font-semibold items-center">Private
                                                    <Switch checked={form.privateChecked} onChange={() => handlePrivateSwitchChange(formIndex)} />
                                                </label>
                                                {form.privateChecked && (
                                                    <div className="flex gap-3">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.privateClinic} onChange={() => handlePrivateCheckboxChange(formIndex, 'privateClinic')} />}
                                                            label="In the Clinic"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={form.privateRemote} onChange={() => handlePrivateCheckboxChange(formIndex, 'privateRemote')} />}
                                                            label="Remotely"
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            {/* Time Slot selection */}
                                            {form.timeSlots.map((slot, timeSlotIndex) => (
                                                <div key={timeSlotIndex} className="flex gap-3 justify-center items-center mb-5">
                                                    <TextField
                                                        label="From"
                                                        type="time"
                                                        variant="outlined"
                                                        className="w-full"
                                                        value={slot.from}
                                                        onChange={(e) => handleTimeChange(formIndex, timeSlotIndex, 'from', e.target.value)}
                                                    />
                                                    <TextField
                                                        label="To"
                                                        type="time"
                                                        variant="outlined"
                                                        className="w-full"
                                                        value={slot.to}
                                                        onChange={(e) => handleTimeChange(formIndex, timeSlotIndex, 'to', e.target.value)}
                                                    />
                                                    <div>
                                                        {timeSlotIndex === 0 ? (
                                                            <button onClick={() => handleAddTimeSlot(formIndex)}>
                                                                <i className="fa-sharp fa-solid fa-circle-plus text-green-500"></i>
                                                            </button>
                                                        ) : (
                                                            <button onClick={() => handleRemoveTimeSlot(formIndex, timeSlotIndex)}>
                                                                <i className="fa-solid fa-circle-xmark text-red-500"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}

                                            {/* Studio Address selection - hidden if only "Remotely" is selected */}
                                            {!(form.ssnRemote || form.privateRemote) || (form.ssnClinic || form.privateClinic) ? (
                                                <FormControl fullWidth>
                                                    <InputLabel id={`selectAddress-label-${formIndex}`}>Studio Address</InputLabel>
                                                    <Select
                                                        label="Select Address"
                                                        labelId={`selectAddress-label-${formIndex}`}
                                                        value={form.selectedAddress}
                                                        onChange={(e) => handleAddressChange(formIndex, e.target.value)}
                                                    >
                                                        {studiosData.length > 0 ? (
                                                            studiosData.map((studio) => (
                                                                <MenuItem key={studio.id} value={studio.id}>
                                                                    {studio.studio} - {studio.street}, {studio.province}, {studio.zip}
                                                                </MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem disabled>No Studio Available</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            ) : null}

                                            {formIndex > 0 && (
                                                <div className="text-center mt-3">
                                                    <button onClick={() => handleRemoveFormInstance(formIndex)} className="text-red-500">
                                                        <i className="fa-solid fa-circle-xmark"></i> Remove Availability
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    <div className="text-center mt-6">
                                        <button className="font-semibold text-gray-500 rounded-lg border border-gray-400 border-dashed w-full py-3" onClick={handleAddFormInstance}>
                                            + Add More
                                        </button>
                                    </div>

                                    <div className="mt-10">
                                    {error && <p style={{ color: '#e91010', marginBottom: '10px', fontSize: '14px' }}>{error}</p>}
                                        <button
                                            className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                            onClick={handleSubmit}
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
