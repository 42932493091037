
import { Grid, CircularProgress } from '@mui/material';
import React, { useState,useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios'
import CryptoJS from 'crypto-js'; // Import the crypto-js library
import { Constants } from '../../endPoints';


export default function ResetPasswordTemplate() {
    const navigate = useNavigate() // Initialize navigate from useNavigate
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptedToken = queryParams.get('token'); // Get encrypted token from URL
    const [token, setToken] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        // Decrypt the token when the component mounts
        if (encryptedToken) {
          try {
            // const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET; // Use the same secret key as backend
            // TODO: Replace the secret key with the actual secret key
            const secretKey = "12344A8mgCrq/d77wuO1cU8i1+SnFJx9N5nZDn+uNftQt"; // Use the same secret key as backend
            const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            setToken(decryptedToken); // Set the decrypted token
          } catch (err) {
            setError('Invalid or corrupted token');
          }
        }

        console.log('Encrypted token:', encryptedToken);
      }, []);



    // Formik initialization for Password Reset
    const passwordResetFormik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('New Password is required'),
            confirmPassword: Yup.string()
                .required('Confirm password is required'),
        }),
        onSubmit: async(values) => {
            console.log('Password Reset Form Submitted', values);
            // Handle password reset logic here
            await handleSubmit(values);
        },
    });

    // Handle form submission
    const handleSubmit = async (values) => {

        setLoading(true);
        setError('');
        setSuccessMessage('');
        try {
            const response = await axios.put(Constants.resetPassword, {
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Send token in the Authorization header
                },
            });

            if (response.status === 200) {
                setSuccessMessage('Email sent! Please check your inbox.');
                setEmail(''); // Clear the email input field after success
                navigate('/login')
            } else {
                setError('Something went wrong. Please try again.');
            }
        } catch (err) {
            setError(`Failed to send reset password email. Please try again. ${err}`);
        } finally {
            setLoading(false); // Stop loading
        }
    };


    return (
        <section className="relative min-h-screen bg-gray-50">
            {/* Top section with design */}
            <div className="bg-[#AAB5FF] pt-10 pb-20 relative">
                <div className="text-center">
                    <img src="assets/images/logo-white.png" className="mx-auto h-16" alt="Kiddocare Logo" />
                    <p className="mt-4 text-white font-semibold">La linea diretta con i tuoi pazienti</p>
                </div>
                {/* Decorative Elements */}

            </div>


            {/* Form Section */}
            <div className="container mx-auto py-10">
                <Grid container spacing={0} className="justify-center" maxWidth={'80%'} style={{
                    margin: '0 auto',
                }}>
                    <Grid item lg={6} md={8} sm={10} xs={12}>
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            {/* Title */}
                            <div className="text-center mb-6">
                                <h2 className="text-2xl font-bold">Crea nuova Password</h2>
                            </div>

                            {/* Loading Indicator */}
                            {loading ? (
                                <div className="flex justify-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <form onSubmit={passwordResetFormik.handleSubmit}>
                                    {/* New Password Field */}
                                    <div className="mb-5">
                                        <FormControl variant="outlined" className="w-full">
                                            <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-new-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="newPassword"
                                                value={passwordResetFormik.values.newPassword}
                                                onChange={passwordResetFormik.handleChange}
                                                onBlur={passwordResetFormik.handleBlur}
                                                error={passwordResetFormik.touched.newPassword && Boolean(passwordResetFormik.errors.newPassword)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="New Password"
                                            />
                                        </FormControl>
                                        {passwordResetFormik.touched.newPassword && passwordResetFormik.errors.newPassword ? (
                                            <div className="text-red-500">{passwordResetFormik.errors.newPassword}</div>
                                        ) : null}
                                    </div>

                                    {/* Confirm Password Field */}
                                    <div className="mb-5">
                                        <FormControl variant="outlined" className="w-full">
                                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-confirm-password"
                                                type={showPassword ? 'text' : 'password'}
                                                name="confirmPassword"
                                                value={passwordResetFormik.values.confirmPassword}
                                                onChange={passwordResetFormik.handleChange}
                                                onBlur={passwordResetFormik.handleBlur}
                                                error={passwordResetFormik.touched.confirmPassword && Boolean(passwordResetFormik.errors.confirmPassword)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Confirm Password"
                                            />
                                        </FormControl>
                                        {passwordResetFormik.touched.confirmPassword && passwordResetFormik.errors.confirmPassword ? (
                                            <div className="text-red-500">{passwordResetFormik.errors.confirmPassword}</div>
                                        ) : null}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="mb-5">
                                        <button
                                            type="submit"
                                            className="w-full h-[54px] shadow-0 bg-[#828DEE] rounded-full text-white font-semibold hover:bg-black transition-all"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                        {
                            error && <div className="text-red-500 text-sm m-3">{error}</div>
                        }
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}
